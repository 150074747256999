import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Typography, Link, Grid } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

const styles = theme => ({
    tableHeaderCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 40,
    },
    tableCellRow: {
        height: 40,
    },
    tableCell: {
        width: 50,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
})

const EventTRAINTable = React.memo((props)=>{
    const { classes, eventId, TRAINTypes, TRAINCategories, TRAINData, aggregates, filterValue } = props
    const eventUrl = eventId?'/event/'+eventId:'/event';
    const colors = ["rgba(58, 199, 255, 0.7)","rgba(0, 157, 0, 0.7)","rgba(253, 216, 53, 0.7)","rgba(245, 124, 0, 0.7)","rgba(224, 14, 60, 0.7)"]
    return (
        <div>
            <Typography style={{fontSize:20,fontWeight:"bold"}}>Triage Resource Allocation for Inpatients</Typography>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        <TableCell className={classes.tableHeaderCell} align="center"/>
                        {TRAINCategories.map((category,i)=>
                            <TableCell key={category.id} className={classes.tableHeaderCell} 
                                align="center"
                                // style={{backgroundColor: colors[i],
                                //      borderBottomColor: colors[i]
                                //     }}
                            >
                                {category.name}
                            </TableCell>
                        )}
                        <TableCell className={classes.tableHeaderCell} 
                            align="center"
                        >
                            Total
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {TRAINTypes.map(type=>
                        <TableRow key={type.id} className={classes.tableCellRow}>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography style={{fontWeight:"bold"}}>
                                    <Link component={RouterLink} 
                                        to={filterValue?
                                            eventUrl+`/TRAIN/${type.id}/aggregate/${aggregates}/filter/${filterValue}`
                                            :eventUrl+`/TRAIN/${type.id}/aggregate/${aggregates}`}
                                    >
                                        {type.name}
                                    </Link>
                                </Typography>
                            </TableCell>
                            {TRAINCategories.map((category,i)=>
                                <TableCell key={category.id} className={classes.tableCell} align="center"
                                    style={{backgroundColor: colors[i],
                                        //  borderBottomColor: colors[i]
                                        }}
                                >
                                    <Typography>
                                        <Link component={RouterLink} 
                                            to={filterValue?
                                                eventUrl+`/TRAIN/${type.id}/aggregate/${aggregates}/filter/${filterValue}`
                                                :eventUrl+`/TRAIN/${type.id}/aggregate/${aggregates}`}
                                        >
                                            {TRAINData[type.id][category.id]}
                                        </Link>
                                    </Typography>
                                </TableCell>
                            )}
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>
                                    {TRAINCategories.reduce((sum,category)=>{
                                        sum += TRAINData[type.id][category.id];
                                        return sum;
                                    },0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow className={classes.tableCellRow}>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography style={{fontWeight:"bold"}}>
                                Total
                            </Typography>
                        </TableCell>
                        {TRAINCategories.map((category,i)=>
                            <TableCell key={category.id} className={classes.tableCell} align="center"
                                style={{backgroundColor: colors[i],
                                    //  borderBottomColor: colors[i]
                                    }}
                            >
                                <Typography>
                                    {TRAINTypes.reduce((sum,type)=>{
                                        sum += TRAINData[type.id][category.id];
                                        return sum;
                                    },0)}
                                </Typography>
                            </TableCell>
                        )}
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>
                                {TRAINTypes.reduce((sum,type)=>{
                                    sum += TRAINCategories.reduce((sum2,category)=>{
                                        sum2 += TRAINData[type.id][category.id];
                                        return sum2;
                                    },0)
                                    return sum
                                },0)}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    )
}) 

export default withStyles(styles)(EventTRAINTable);