import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogActions, DialogContent, Checkbox, Grid, FormControl, FormControlLabel, Button } from '@material-ui/core';
import 'chartjs-plugin-datalabels';

const styles = theme => ({
});

const BarChart = React.memo((props)=>{
    const { classes, open, setOpen, setSelectedEvents, events, selectedEvents } = props
    const [tempSelectedEvents, setTempSelectedEvents] = useState({});
    function onEnter(){
        setTempSelectedEvents(selectedEvents);
    }
    function handleChange(event,id){
        setTempSelectedEvents({...tempSelectedEvents,[id]: event.target.checked})
    }
    return(
        <Dialog 
            style={{minWidth: 300}}
            onEnter={onEnter}
            open={open}
            onClose={()=>setOpen(false)}
        >
            <DialogTitle>Select Events</DialogTitle>
            <DialogContent>
                <Grid container direction="column">
                    {events.every(n=>tempSelectedEvents[n.id])?
                        <Button onClick={()=>setTempSelectedEvents({})}>Deselect All</Button>:
                        <Button onClick={()=>setTempSelectedEvents(events.reduce((obj,n)=>{obj[n.id]=true;return obj;},{}))}>Select All</Button>}
                    <FormControl>
                        {events.slice().reverse().map(n=>
                            <FormControlLabel
                                key={n.id}
                                control={<Checkbox checked={Boolean(tempSelectedEvents[n.id])} onChange={(event)=>handleChange(event,n.id)}/>}
                                label={n.name}
                            />
                        )}
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button className={classes.button} onClick={()=>{setSelectedEvents(tempSelectedEvents);setOpen(false);}} variant="contained" color="secondary">
                    Confirm
                </Button>
                <Button className={classes.button} onClick={()=>setOpen(false)} variant="contained" color="default">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )

})

export default withStyles(styles)(BarChart);