import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Typography, FormControl, InputLabel, Select, Grid, Table, TableBody, TableRow, TableCell, Tooltip } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CustomizedTextField from '../CustomizedTextField'
import { useCheckbox, useFormInput } from '../CustomHooks';
import InfoIcon from '@material-ui/icons/Info'

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 150,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        padding: 10,
        // '&:last-child': {
        //   paddingRight: 0,
        // },
    },
    tableHeaderRow: {
        height: 35,
    },
    tableCellRow: {
        height: 40,
    },
    tableCell: {
        // width: 50,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 10,
        // '&:last-child': {
        //   paddingRight: 0,
        // },
    },
    tableCellLeft: {
        // width: 50,
        fontSize: 14,
        fontWeight: "bold",
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 10,
    },
    input: {
        // width: 50,
        textAlign: "center",
        paddingTop: 2,
        paddingBottom: 2,
        color: "#555555",
        fontSize: 14,
    },
    formControl: {
        minWidth: 130,
    },
    spacing: {
        height: 25,
    },
    select: {
        color: "#555555",
        fontSize: 14,
    },
    heavyFont: {
        fontSize: 14,
        fontWeight: "bold",
    }
})

const StrikeInput = React.memo((props)=>{
    const { classes, data, fields, event, selectedEvent, events, statuses, handleStatusChange, handleDescriptionChange } = props
    if(!data){
        return null
    }
    const statusColors = statuses.reduce((obj,n)=>{
        obj[n.id] = n.color;
        return obj;
    },{})
    return (
        <Grid container>
            <Table>
                <colgroup>
                    <col width="50%"/>
                    <col width="20%"/>
                    <col width="30%"/>
                </colgroup>
                <TableBody>
                    <TableRow className={classes.tableHeaderRow}>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            {event?
                                <Typography className={classes.heavyFont}>{event.name}</Typography>:
                                <Grid container direction="row">
                                    <Typography className={classes.heavyFont} style={{marginRight: 10}}>Event:</Typography>
                                    <FormControl className={classes.formControl}>
                                        <select
                                            className={classes.select}
                                            {...selectedEvent}
                                        >
                                            <option value="" />
                                            {events.map(n=>
                                                <option key={n.id} value={n.id}>{n.name}</option>
                                            )}
                                        </select>
                                    </FormControl>
                                </Grid>
                            }
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            {"STATUS\n(Red/Green)"}
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            If Red - provide reason/need for escalation
                        </TableCell>
                    </TableRow>
                    {fields.map(n=>
                        <TableRow key={n.id} className={classes.tableCellRow}>
                            <TableCell className={classes.tableCellLeft}>
                                {n.name}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <FormControl className={classes.formControl}>
                                    <select
                                        className={classes.select}
                                        onChange = {(e)=>{handleStatusChange(n.id,e.target.value)}}
                                        value={data[n.id].status}
                                        style={{backgroundColor: statusColors[data[n.id].status], fontWeight: 'bold', color: 'white'}}
                                    >
                                        {statuses.map(status=>
                                            <option style={{backgroundColor:status.color, color:'white'}} key={status.id} value={status.id}>{status.name}</option>
                                        )}
                                    </select>
                                </FormControl>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <CustomizedTextField
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    rows={1}
                                    rowsMax={4}
                                    value={data[n.id].description}
                                    onChange = {(e)=>handleDescriptionChange(n.id,e.target.value)}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Grid>
    )
}) 

export default withStyles(styles)(StrikeInput);