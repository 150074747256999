import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Link, Typography, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import axios from 'axios';
import { stableSort, getSorting, SortableLabel } from '../TableUtils'
import { handleAxiosError, getAPIUrl, getSocketUrl } from '../AppUtils';
import io from 'socket.io-client';

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 120,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
        // position: "sticky",
        // top: 0
    },
    tableHeaderRow: {
        height: 30,
    },
    tableCellRow: {
        height: 40,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    greenCell: {
        backgroundColor: '#38a438',
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    redCell: {
        backgroundColor: 'red',
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    searchGrid: {
        marginBottom: 20,
    },
    totalText: {
        marginLeft: 15,
    },
    pagination:{
        marginTop: 30,
    },
})

function EventTechnologyStatusAggregatedTable(props) {
    const { classes, eventId, technologyId, aggregates, filters, colLabel } = props;
    const source = axios.CancelToken.source();

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState(1);
    const [technologyInfo, setTechnologyInfo] = useState({aggregates:[],technologyStatuses:[]});
    const [itemName, setItemName] = useState("");
    const [extraColumns, setExtraColumns] = useState([]);

    useEffect(()=>{
        getTechnologyItem();
        getTechnologyStatus();
        const socket = io(getSocketUrl());
        socket.on('socket_msg', getTechnologyStatus)
        return () => {
            socket.close();
            source.cancel("unmount");
        }
    }, []);

    useEffect(()=>{
        getTechnologyStatus();
    }, [aggregates,filters]);

    function getTechnologyItem() {
        axios.get(getAPIUrl()+`/technologyItem/${technologyId}`,{cancelToken: source.token})
        .then(response => {
            setItemName(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getTechnologyStatus() {
        var url =  eventId?getAPIUrl()+`/event/${eventId}/technologyStatus/${technologyId}/aggregate/${aggregates}`:
            getAPIUrl()+`/event/technologyStatus/${technologyId}/aggregate/${aggregates}`
        if(filters){
            url += `/filter/${filters}`
        }
        axios.get(url,{cancelToken: source.token})
        .then(response => {
            setExtraColumns(response.data.extraColumns)
            setTechnologyInfo(response.data.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getLinkURL(newFilter){
        var filtersString = filters?`${filters}&${newFilter}`:newFilter;
        var url = '/event';
        if(eventId){
            url += `/${eventId}`
        }
        url += `/technology/${technologyId}/aggregate/${aggregates}/filter/${filtersString}`
        return url;
    }
    function getLinkOrText(link,label){
        if(filters&&filters.split('&').length===aggregates.split('&').length){
            return label;
        }
        else{
            return (
                <Link component={RouterLink} 
                    to={getLinkURL(link)}
                >
                    {label}
                </Link>
            )
        }
    }
    const handleRequestSort = property => event => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    // technologyInfo.aggregates.forEach(aggregate=>{
    //     const itemStatus = technologyInfo.status.filter(n=>n.id===aggregate.id);
    //     technologyInfo.technologyStatuses.forEach(status=>{
    //         aggregate[status.id] = 0;
    //     });
    //     itemStatus.forEach(entry=>{
    //         aggregate[entry.status] += 1;
    //     })
    // })
    function lowestDisaggregation(){
        return filters&&filters.split('&').length===aggregates.split('&').length;
    }
    return (
        <div>
            <Typography style={{fontSize:20,fontWeight:"bold"}} display="inline">DETAILS: </Typography>
            <Typography style={{fontSize:20}} display="inline"> TECHNOLOGY ({itemName.toUpperCase()}) </Typography>
            <div style={{height:20}}/>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        {extraColumns.map(n=>
                            <TableCell key={n.id} className={classes.tableHeaderCell} align="center">
                                <SortableLabel
                                    order={order}
                                    active={orderBy===n.id}
                                    handleSort={handleRequestSort(n.id)}
                                    label={n.name}
                                />
                            </TableCell>
                        )}
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="name"}
                                handleSort={handleRequestSort("name")}
                                label={colLabel}
                            />
                        </TableCell>
                        {lowestDisaggregation()?
                            <TableCell className={classes.tableHeaderCell} align="center">
                                Status
                            </TableCell>:
                            technologyInfo.technologyStatuses.filter(n=>n.name!="N/A").map(status=>
                            <TableCell key={status.id} className={classes.tableHeaderCell} align="center">
                                <SortableLabel
                                    order={order}
                                    active={orderBy===status.id}
                                    handleSort={handleRequestSort(status.id)}
                                    label={status.name}
                                />
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stableSort(technologyInfo.aggregates, getSorting(order, orderBy))
                    .map((n,index)=>
                        <TableRow className={classes.tableCellRow} key={n.id}>
                            {extraColumns.map(col=>
                                <TableCell key={col.id} className={classes.tableCell} align="center">
                                    <Typography>{n[col.id]}</Typography>
                                </TableCell>
                            )}
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>
                                    {getLinkOrText(n.id,n.name)}
                                </Typography>
                            </TableCell>
                            {lowestDisaggregation()?
                                <TableCell className={classes.tableCell} align="center">
                                    <Typography>
                                        {technologyInfo.technologyStatuses.find(status=>n.status[status.id]>0).name}
                                    </Typography>
                                </TableCell>:
                                technologyInfo.technologyStatuses.filter(n=>n.name!="N/A").map(status=>
                                    <TableCell key={status.id} className={classes.tableCell} align="center">
                                        <Typography>
                                            {getLinkOrText(n[status.id],n.status[status.id])}
                                        </Typography>
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
} 

export default withStyles(styles)(EventTechnologyStatusAggregatedTable);