import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Typography } from '@material-ui/core'

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 150,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 35,
    },
    tableCellRow: {
        height: 40,
    },
    tableCell: {
        // width: 50,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
})

function ResourceStatusTable(props) {
    const { classes, resourceItems, resourceItemStatus } = props
    if(!resourceItemStatus){
        return null;
    }
    return (
        <Table>
            <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                    <TableCell className={classes.tableHeaderCell} align="center">Resource</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">On Hand</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">Available for Deployment</TableCell>
                    {resourceItems.length>1?
                        <React.Fragment>
                            <TableCell className={classes.tableHeaderCell} align="center">Resource</TableCell>
                            <TableCell className={classes.tableHeaderCell} align="center">On Hand</TableCell>
                            <TableCell className={classes.tableHeaderCell} align="center">Available for Deployment</TableCell>
                        </React.Fragment>
                        :undefined
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {resourceItems.map((item,index)=>
                    index%2===0?
                    <TableRow className={classes.tableCellRow} key={item.id}>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{item.name}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{resourceItemStatus[item.id].onHand}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{resourceItemStatus[item.id].availableForDeployment}</Typography>
                        </TableCell>
                        {resourceItems[index+1]?
                            <React.Fragment>
                                <TableCell className={classes.tableCell} align="center">
                                    <Typography>{resourceItems[index+1].name}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell} align="center">
                                    <Typography>{resourceItemStatus[resourceItems[index+1].id].onHand}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell} align="center">
                                    <Typography>{resourceItemStatus[resourceItems[index+1].id].availableForDeployment}</Typography>
                                </TableCell>
                            </React.Fragment>
                            :undefined
                        }
                    </TableRow>
                    :undefined
                )}
            </TableBody>
        </Table>
    )
} 

export default withStyles(styles)(ResourceStatusTable);