import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Grid, Typography, CircularProgress, Tooltip } from '@material-ui/core'
import { useCheckbox, useFormInput } from '../CustomHooks';
import axios from 'axios';
import StrikeInput from './StrikeInput';
import { handleAxiosError, getAPIUrl } from '../AppUtils';
import { connect } from 'react-redux';

const styles = theme => ({
    page: {
        width: "100%",
    },
    gridContainer: {
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        width: "100%"
    },
    companyLogo: {
        backgroundColor: theme.palette.background.default,
        maxWidth: "100%",
    },
    spacing: {
        height: 25,
    },
    title: {
        fontFamily: "'Lato', sans-serif",
        fontSize: 25,
        fontWeight: "bold",
    },
    submitErrorMessage: {
        color: 'red',
        marginTop: 10,
        marginLeft: 10,
    },
});

function CreateStrikeReportPage(props) {
    const { classes, departmentInfo, logo, initialDetails, orgSettings, unions, shifts } = props;
    const source = axios.CancelToken.source();
    
    const [invalidReport, setInvalidReport] = useState(false);
    const [submittingReport, setSubmittingReport] = useState(false);
    // const strikeDate = useFormInput(new Date());
    const selectedUnion = useFormInput("");
    const selectedShift = useFormInput("");
    const selectedStrikeEvent = useFormInput("");
    const normalStaffing = useFormInput(0);
    const reducedStaffing = useFormInput(0);
    const safeStaffing = useFormInput(0);
    const actualStaffing = useFormInput(0);
    const usedReplacementStaffing = useFormInput(0);
    const requestReplacementFailed = useCheckbox(false);
    const strikePlan = useFormInput("");
    const [strikeEvents, setStrikeEvents] = useState([]);
    //mount, unmount
    useEffect(() => {
        getStrikeEvents();
        if(initialDetails){
            selectedStrikeEvent.onChange({target:{value:initialDetails.strike_event_id}});
            selectedUnion.onChange({target:{value:initialDetails.union_id}});
            selectedShift.onChange({target:{value:initialDetails.shift_id}});
            normalStaffing.onChange({target:{value:initialDetails.normal_staffing}});
            reducedStaffing.onChange({target:{value:initialDetails.reduced_staffing}});
            safeStaffing.onChange({target:{value:initialDetails.safe_staffing}});
            actualStaffing.onChange({target:{value:initialDetails.actual_staffing}});
            usedReplacementStaffing.onChange({target:{value:initialDetails.used_replacement_staffing}});
            requestReplacementFailed.onChange({target:{checked:initialDetails.request_replacement_failed}});
            strikePlan.onChange({target:{value:initialDetails.strike_plan}});
        }
        return () => {source.cancel("unmount");}
    }, []);

    function getStrikeEvents(){
        axios.get(getAPIUrl()+'/openStrikeEvents',{cancelToken: source.token})
        .then(response => {
            setStrikeEvents(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function submitReport(){
        setInvalidReport(false);
        if(!allFieldsValid()){
            setInvalidReport(true);
        }
        else{
            let reportData = {
                departmentInfo,
                eventId:selectedStrikeEvent.value,
                union:selectedUnion.value,
                shift:selectedShift.value,
                normalStaffing:normalStaffing.value,
                reducedStaffing:reducedStaffing.value,
                safeStaffing:safeStaffing.value,
                actualStaffing:actualStaffing.value,
                usedReplacementStaffing:usedReplacementStaffing.value,
                requestReplacementFailed:requestReplacementFailed.checked,
                strikePlan:strikePlan.value
            }
            setSubmittingReport(true);
            axios.post(getAPIUrl()+'/submitStrikeReport',{...reportData},{cancelToken: source.token})
            .then(response => {
                setSubmittingReport(false);
                props.setDepartmentInfo(undefined);
                props.history.push("/strikeSubmitted");
            })
            .catch(error => {
                if(handleAxiosError(error)!=='cancel'){
                    setSubmittingReport(false);
                }
            });
        }
    }
    function allFieldsValid(){
        let valid = true;
        if(selectedStrikeEvent.value===""){
            valid = false;
        }
        if(selectedUnion.value===""){
            valid = false;
        }
        if(selectedShift.value===""){
            valid = false;
        }
        if(normalStaffing.value===""){
            valid = false;
        }
        if(reducedStaffing.value===""){
            valid = false;
        }
        if(safeStaffing.value===""){
            valid = false;
        }
        if(actualStaffing.value===""){
            valid = false;
        }
        if(usedReplacementStaffing.value===""){
            valid = false;
        }
        return valid;
    }
    return (
        <div className={classes.page}>
            <Grid container justify='center' className={classes.gridContainer}>
                <Grid item md={4}>
                    <img className={classes.companyLogo}
                        src={logo}
                        alt=''
                    />
                </Grid>
                <Grid container item md={4} direction="column" justify='center' alignItems='center'>
                    <Typography className={classes.title} variant="h3">Strike Report</Typography>
                </Grid>
                <Grid item md={4}/>
                <Grid item className={classes.spacing} xs={12}></Grid>
                <Grid item sm={12}>
                    <div className={classes.departmentInfoBox}>
                        {departmentInfo.labels.map((n,i)=>
                            <Typography key={i} style={{fontWeight:"bold"}}>{n}</Typography>
                        )}
                    </div>
                </Grid>
                <Grid item className={classes.spacing} xs={12}></Grid>
                <Grid item xs={12}>
                    {/* <Typography variant="h5">Strike Table</Typography> */}
                    <StrikeInput
                        selectedUnion={selectedUnion}
                        selectedShift={selectedShift}
                        normalStaffing={normalStaffing}
                        reducedStaffing={reducedStaffing}
                        safeStaffing={safeStaffing}
                        actualStaffing={actualStaffing}
                        usedReplacementStaffing={usedReplacementStaffing}
                        requestReplacementFailed={requestReplacementFailed}
                        strikePlan={strikePlan}
                        unions={unions}
                        shifts={shifts}
                        strikeEvents={strikeEvents}
                        selectedStrikeEvent={selectedStrikeEvent}
                        strikeEvent={initialDetails?{id:initialDetails.strike_event_id,name:initialDetails.strike_name}:undefined}
                    />
                </Grid>
                <Grid item className={classes.spacing} xs={12}></Grid>
                <Grid item container alignItems='center'>
                    {submittingReport?
                        <CircularProgress color="secondary"/>:
                        <Button className={classes.button} color="secondary" variant="contained" size="small"
                            onClick={()=>submitReport()}
                        >
                            Submit Report
                        </Button>
                    }
                    {invalidReport?<Typography className={classes.submitErrorMessage}>Please make sure all fields are filled.</Typography>:undefined}
                </Grid>
                <Grid item className={classes.spacing} xs={12}></Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { unions, shifts } = state
    return { unions, shifts }
};

export default withStyles(styles)(connect(mapStateToProps)(CreateStrikeReportPage));