import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, FormControl, Tooltip, Grid } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const styles = theme => ({
    tableHeaderCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
    },
    tableHeaderRow: {
        height: 30,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    input: {
        width: 50,
        textAlign: "center",
        color: "#555555",
    },
    textInput: {
        width: 150,
        textAlign: "left",
        paddingTop: 2,
        paddingBottom: 2,
        color: "#555555",
        fontSize: 18,
    },
    select: {
        color: "#555555",
        fontSize: 18,
    },
    otherTitleInput: {
        textAlign: "center",
        paddingTop: 2,
        paddingBottom: 2,
        color: "#555555",
        fontSize: 14,
        marginTop: 10,
    },
    otherTitleCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
        height: 70,
    },
    formControl: {
        minHeight: 20,
    },
    centerRaido: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
})
const CreateStaffingTable = React.memo((props)=>{
    const {classes, staffingDemandEntries,staffTitles,setStaffingDemandEntries} = props
    function handleDataChange(index,field){
        if (field === "title" || field === "positions") {
            return function(event){
                let newArr = staffingDemandEntries.slice(0);
                var newVal = parseInt(event.target.value);
                newArr[index][field] = isNaN(newVal)?"":newVal;
                setStaffingDemandEntries(newArr);
            };
        }
        return function(event){
            let newArr = staffingDemandEntries.slice(0);
            
            var newVal = event.target.value;
            newArr[index][field] = newVal;
            setStaffingDemandEntries(newArr);
        };
    }
    function handleDateChange(index,field, date){
        let newArr = staffingDemandEntries.slice(0);
        
        newArr[index][field] = date;
        setStaffingDemandEntries(newArr);
    }
    function handleOtherTitleChange(index){
        return function(event){
            let newArr = staffingDemandEntries.slice(0);
            newArr[index]["otherTitle"] = event.target.value;
            setStaffingDemandEntries(newArr);
        };
    }
    function selectOnFocus(event) {
        event.target.select();
    }

    
    return (
        <Table>
            <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                    <TableCell className={classes.tableHeaderCell} align="center">Sr.Director/CAO Name</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">Position Title</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">Number of Positions</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">Status</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">Start Date</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">End Date</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {staffingDemandEntries.map((entry, i) =>
                    <TableRow key={i}>
                        <TableCell className={classes.tableCell} align="center">
                            <input className={classes.textInput}
                                type="text"
                                value={entry.name}
                                onChange={handleDataChange(i,"name")}
                                onFocus={selectOnFocus}
                            />
                        </TableCell>
                        {/* Hard Coded title.id 14 = Other */}
                        <TableCell className={entry.title===14?classes.otherTitleCell:classes.tableCell} align="center">
                            <Grid container direction="column" justify="center">
                                <FormControl className={classes.formControl}>
                                    <select
                                        className={classes.select}
                                        name="input"
                                        value={entry.title}
                                        onChange={handleDataChange(i,"title")}
                                    >
                                        <option className={classes.option} value={-1}>Select Title</option>
                                        {staffTitles.map(title =>
                                            <option key={title.id} className={classes.option} value={title.id}>
                                                {title.name}
                                            </option>)
                                        }
                                    </select>
                                </FormControl>
                                {//Hard Coded title.id 14 = Other
                                    entry.title===14?
                                    <input className={classes.otherTitleInput} type="text" 
                                        value={entry.otherTitle}
                                        onChange={handleOtherTitleChange(i)}
                                    />:undefined
                                }
                            </Grid>
                        </TableCell>
                        
                        <TableCell className={classes.tableCell} align="center">
                            <input className={classes.input}
                                type="number"
                                min={0}
                                value={entry.positions}
                                onChange={handleDataChange(i,"positions")}
                                onFocus={selectOnFocus}
                            />
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <div className={classes.centerRaido}>
                            <input type="radio" size="25" value="perm" checked={entry.status === "perm"} onChange={handleDataChange(i,"status")}/> 
                            <label for="perm" size="25">&nbsp;Permanent</label>
                            </div>
                            <br></br>
                            <div className={classes.centerRaido}>
                            <input type="radio" value="temp" checked={entry.status === "temp"} onChange={handleDataChange(i,"status")}/> 
                            <label for="temp">&nbsp;Temporary</label>
                            </div>
                            
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <DatePicker style={{fontSize: "18"}} selected={entry.start_date} onChange={(date) => handleDateChange(i, "start_date", date)} />
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <DatePicker style={{fontSize: "18"}} selected={entry.end_date} onChange={(date) => handleDateChange(i, "end_date", date)} />
                        </TableCell>
                    </TableRow>
                    
                )}
            </TableBody>
        </Table>
    )
})

export default withStyles(styles)(CreateStaffingTable);