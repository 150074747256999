import React from 'react';
import { Route, Redirect } from "react-router-dom";

function AdminRoute(props){
    const { component: Component, componentProps, user, ...rest } = props
    return(
    <Route 
        {...rest} 
        render={(props) => (
            user.type>0?
            <Component {...props} {...componentProps} /> :
            <Redirect to='/start'/>
        )} 
    />
    )
};
export default AdminRoute;