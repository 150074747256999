import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Typography, Grid } from '@material-ui/core'

const styles = theme => ({
    tableHeaderCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 40,
    },
    tableCellRow: {
        height: 40,
    },
    tableCell: {
        width: 50,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    notApplicableCell: {
        width: 130,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        padding: 0,
    },
})

function AvailableBedsTable(props) {
    const { classes, bedsNotApplicable, bedTypes, bedStatuses, bedAvailability } = props
    if(!bedAvailability){
        return null;
    }
    return (
        <Table>
            <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                    <TableCell className={classes.notApplicableCell} align="center">
                        {/* <Grid container justify='center' alignItems='center'>
                            <input type="checkbox" checked={bedsNotApplicable} readOnly/>
                            &nbsp;Not Applicable
                        </Grid> */}
                    </TableCell>
                    {bedStatuses.map(status=>
                        <TableCell key={status.id} className={classes.tableHeaderCell} 
                            colSpan={status.categories.length} 
                            align="center"
                        >
                            {status.name}
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow padding="none" className={classes.tableCellRow}>
                    <TableCell className={classes.tableCell} align="center"></TableCell>
                    {bedStatuses.map(status=>status.categories.map(category=>
                        <TableCell key={category.id} className={classes.tableCell} align="center">
                            <Typography>{category.name}</Typography>
                        </TableCell>
                    ))}
                </TableRow>
                {bedTypes.map(type=>
                    <TableRow key={type.id} className={classes.tableCellRow}>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{type.name}</Typography>
                        </TableCell>
                        {bedStatuses.map(status=>status.categories.map(category=>
                            <TableCell key={category.id}className={classes.tableCell} align="center">
                                <Typography>
                                    {bedAvailability[type.id][status.id][category.id]}
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                )}
            </TableBody>
        </Table>
    )
} 

export default withStyles(styles)(AvailableBedsTable);