import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, CircularProgress } from '@material-ui/core'

const styles = theme => ({
    authenticatingGrid: {
        height:'100vh'
    }
});

function AuthenticatingPage(props) {
    const { classes } = props;
    return (
        <Grid className={classes.authenticatingGrid} container direction="column" justify='center' alignItems='center'>
            {/* <Typography variant="h6">Loading...</Typography> */}
            <div style={{height:20}}/>
            <CircularProgress/>
        </Grid>
    );
}

export default withStyles(styles)(AuthenticatingPage);