import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import StrikeReportsTable from './StrikeReportsTable';
import { handleAxiosError, getAPIUrl } from '../AppUtils';

const styles = theme => ({
    page: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 300,
    }
});

function StrikeReportsPage(props) {
    const { classes, adminView } = props;
    const source = axios.CancelToken.source();
    const [reports, setReports] = useState(undefined);

    //mount, unmount
    useEffect(() => {
        getReports();
        return () => {source.cancel("unmount");}
    }, []);
    function getReports(){
        if(adminView){
            axios.get(getAPIUrl()+'/strikeReports',{cancelToken: source.token})
            .then(response => {
                setReports(response.data);
            })
            .catch(error=>handleAxiosError(error));
        }
        else{
            axios.get(getAPIUrl()+'/userStrikeReports',{cancelToken: source.token})
            .then(response => {
                setReports(response.data);
            })
            .catch(error=>handleAxiosError(error));
        }
    }
    return (
        <div className={classes.page}>
            <StrikeReportsTable
                reports={reports}
                adminView={adminView}
            />
        </div>
    );
}

export default withStyles(styles)(StrikeReportsPage);