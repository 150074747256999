import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Link, Typography } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import EventStaffingAggregatedTable from './EventStaffingAggregatedTable';
import EventStaffingDemandAggregatedTable from './EventStaffingDemandAggregatedTable'
import EventAvailableBedsAggregatedTable from './EventAvailableBedsAggregatedTable';
import EventTechnologyStatusAggregatedTable from './EventTechnologyStatusAggregatedTable';
import EventResourceStatusAggregatedTable from './EventResourceStatusAggregatedTable';
import EventTRAINAggregatedTable from './EventTRAINAggregatedTable';
import axios from 'axios';
import { getAPIUrl, handleAxiosError } from '../AppUtils'

const styles = theme => ({
    page: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 300,
    },
    title: {
        fontFamily: 'Lato, sans-serif',
        fontSize: 24,
        fontWeight: 500,
    }
});

function EventTableAggregatedPage(props) {
    const { classes, match, history } = props;
    const [aggregateTitles, setAggregateTitles] = useState([]);
    useEffect(()=>{
        getAggregateTitles()
    },[match.params])
    function getAggregateTitles(){
        var url = getAPIUrl()+`/aggregateTitles/${match.params.aggregates}`
        if(match.params.filters){
            url += `/${match.params.filters}`
        }
        axios.get(url)
        .then(response=>{
            setAggregateTitles(response.data)
        })
        .catch(error=>handleAxiosError(error))
    }
    function getTitleLink(i){
        var url = '/event'
        if(match.params.eventId){
            url += `/${match.params.eventId}`
        }
        url += `/${match.params.table}/${match.params.tableId}/aggregate/${match.params.aggregates}`;
        let filters = match.params.filters;
        if(filters && filters.split('&').length > 1 && i > 0){
            url += `/filter/${filters.split('&').slice(0,i).join('&')}`;
        }
        return url;
    }
    return (
        <div className={classes.page}>
            <Grid container alignItems='center'>
                <Typography className={classes.title}>
                    <Link component={RouterLink} 
                        to={match.params.eventId?`/event/${match.params.eventId}`:'/event'}
                    >
                        Dashboard
                    </Link>
                </Typography>
                {aggregateTitles.map((n,i)=>
                <React.Fragment key={i}>
                    <ChevronRightIcon color="secondary" fontSize="large"/>
                    <Typography className={classes.title}>
                        {i<aggregateTitles.length-1?
                            <Link component={RouterLink} 
                                to={getTitleLink(i)}
                            >
                                {n}
                            </Link>:n
                        }
                    </Typography>
                </React.Fragment>
                )}
            </Grid>
            <div style={{height:30}}/>
            <TableSwitch
                match={match}
                history={history}
                colLabel={aggregateTitles[aggregateTitles.length-1]}
            />
        </div>
    );
}

function TableSwitch(props) {
    const { match, history, colLabel } = props;
    switch(match.params.table){
        case 'staffing':
            return (
                <EventStaffingAggregatedTable
                    eventId={match.params.eventId}
                    staffId={match.params.tableId}
                    aggregates={match.params.aggregates}
                    filters={match.params.filters}
                    colLabel={colLabel}
                />
            );
        case 'staffingDemand':
            return (
                <EventStaffingDemandAggregatedTable
                    eventId={match.params.eventId}
                    staffId={match.params.tableId}
                    aggregates={match.params.aggregates}
                    filters={match.params.filters}
                    colLabel={colLabel}
                />
            );
        case 'available-beds':
            return (
                <EventAvailableBedsAggregatedTable
                    eventId={match.params.eventId}
                    typeId={match.params.tableId}
                    aggregates={match.params.aggregates}
                    filters={match.params.filters}
                    colLabel={colLabel}
                />
            );
        case 'technology':
            return (
                <EventTechnologyStatusAggregatedTable
                    eventId={match.params.eventId}
                    technologyId={match.params.tableId}
                    aggregates={match.params.aggregates}
                    filters={match.params.filters}
                    colLabel={colLabel}
                />
            );
        case 'resource':
            return (
                <EventResourceStatusAggregatedTable
                    eventId={match.params.eventId}
                    resourceId={match.params.tableId}
                    aggregates={match.params.aggregates}
                    filters={match.params.filters}
                    colLabel={colLabel}
                />
            );
        case 'TRAIN':
            return (
                <EventTRAINAggregatedTable
                    eventId={match.params.eventId}
                    typeId={match.params.tableId}
                    history={history}
                    aggregates={match.params.aggregates}
                    filters={match.params.filters}
                    colLabel={colLabel}
                />
            );
        default:
            return (
                <Grid container direction='column' justify='center' alignItems='center'>
                    <Typography variant="h4">
                        Table Not Found
                    </Typography>
                </Grid>
            );
        }
}

export default withStyles(styles)(EventTableAggregatedPage);