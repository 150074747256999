import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Link, Typography, IconButton,
        Tooltip, TableSortLabel, Grid, CircularProgress } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from 'react-bootstrap/Pagination'
import './custom.scss';
import { format, parseISO } from 'date-fns'
import { stableSort, getSorting, SortableLabel } from './TableUtils'
import EditIcon from '@material-ui/icons/Edit';
import EditEventDialog from './EditEventDialog';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { getAPIUrl, handleAxiosError } from './AppUtils';

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 120,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 30,
    },
    tableRow: {
        height: 40,
        // '&:hover': {
        //     cursor: "pointer",
        // },
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    searchGrid: {
        marginBottom: 20,
    },
    totalText: {
        marginLeft: 15,
    },
    pagination:{
        marginTop: 30,
    }
})

function EventsTable(props) {
    const { classes, events, refreshEvents } = props
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [editEvent, setEditEvent] = useState(undefined);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [downloading, setDownloading] = useState({});

    const rowsPerPage = 10;
    const handleRequestSort = property => event => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    function handlePageChange(pageNum){
        setPage(pageNum);
    }
    const filteredEvents = events?events:[]
    const numPages = Math.ceil(filteredEvents.length/rowsPerPage);
    function handlePageIncrease(){
        if(page<numPages-1){
            setPage(page+1);
        }
    }
    function handlePageDecrease(){
        if(page>0){
            setPage(page-1);
        }
    }
    function handleEventClick(id){
        props.history.push(`/event/${id}`)
    }
    function handleEditDialogClose(){
        setEditDialogOpen(false);
        setEditEvent(undefined);
    }
    function downloadEventPDF(eventId){
        setDownloading({...downloading, [eventId]: true});
        axios.post(getAPIUrl()+`/eventPDF/${eventId}`,{userCurrentTime: format(new Date(),'MMM dd, yyyy hh:mm a')})
        .then(response=>{
            setDownloading({...downloading, [eventId]: false});
            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'unknown.pdf';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename=(.+)/);
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }
            var blob = new Blob([response.data]);
            if (navigator.appVersion.toString().indexOf('.NET') > 0)
                window.navigator.msSaveBlob(blob, fileName);
            else
            {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            }
        })
        .catch(error=>{
            setDownloading({...downloading, [eventId]: false});
            handleAxiosError(error)
        })
    }
    return (
        <div>
            <EditEventDialog
                open={editDialogOpen}
                event={editEvent}
                handleClose={handleEditDialogClose}
                refreshEvents={refreshEvents}
            />
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="id"}
                                handleSort={handleRequestSort("id")}
                                label="Event ID"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="name"}
                                handleSort={handleRequestSort("name")}
                                label="Name"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="status"}
                                handleSort={handleRequestSort("status")}
                                label="Status"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="create_time"}
                                handleSort={handleRequestSort("create_time")}
                                label="Create Time"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="close_time"}
                                handleSort={handleRequestSort("close_time")}
                                label="Close Time"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            Edit Event
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            Download
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stableSort(filteredEvents, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(event=>
                    <TableRow className={classes.tableRow} key={event.id}>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>
                                <Link component={RouterLink} 
                                    to={`/event/${event.id}`}
                                >
                                    {event.id}
                                </Link>
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>
                                <Link component={RouterLink} 
                                    to={`/event/${event.id}`}
                                >
                                    {event.name}
                                </Link>
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>
                                <Link component={RouterLink} 
                                    to={`/event/${event.id}`}
                                >
                                    {event.status?'Open':'Closed'}
                                </Link>
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>
                                <Link component={RouterLink} 
                                    to={`/event/${event.id}`}
                                >
                                    {format(parseISO(event.create_time),'MMM dd, yyyy hh:mm a')}
                                </Link>
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>
                                <Link component={RouterLink} 
                                    to={`/event/${event.id}`}
                                >
                                    {event.close_time?format(parseISO(event.close_time),'MMM dd, yyyy hh:mm a'):''}
                                </Link>
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <IconButton
                                fontSize="15"
                                onClick={()=>{
                                    setEditEvent(event);
                                    setEditDialogOpen(true);
                                }}
                            >
                                <EditIcon/>
                            </IconButton>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            {downloading[event.id]?<CircularProgress size={30} color="secondary"/>:
                                <IconButton
                                    fontSize="15"
                                    onClick={()=>{
                                        downloadEventPDF(event.id)
                                    }}
                                >
                                    <FontAwesomeIcon size="xs" icon={["fa","file-download"]}/>
                                </IconButton>
                            }
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
            <div>
            <Pagination className={classes.pagination}>
                <Pagination.First onClick={()=>handlePageChange(0)}/>
                <Pagination.Prev onClick={handlePageDecrease} disabled={page===0}/>
                {page-10>0?<Pagination.Ellipsis/>:undefined}
                {Array(numPages).fill().map((_, i) => {
                    if(i>page-10 && i<page+10){
                        return(
                            <Pagination.Item key={i} 
                                active={page===i} 
                                onClick={()=>handlePageChange(i)}
                            >
                                {i+1}
                            </Pagination.Item>
                        );
                    }
                })}
                {page+10<numPages-1?<Pagination.Ellipsis/>:undefined}
                <Pagination.Next onClick={handlePageIncrease} disabled={page>=numPages-1}/>
                <Pagination.Last onClick={()=>handlePageChange(numPages-1)}/>
            </Pagination>
            </div>
        </div>
    )
} 

export default withStyles(styles)(EventsTable);