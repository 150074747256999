import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Typography, Link, Grid } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

const styles = theme => ({
    tableHeaderCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 40,
    },
    tableCellRow: {
        height: 40,
    },
    tableCell: {
        width: 50,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
})

const EventAvailableBedsTable = React.memo((props)=>{
    const { classes, eventId, bedTypes, bedStatuses, bedAvailability, totalPatientCount, aggregates, filterValue } = props
    const eventUrl = eventId?'/event/'+eventId:'/event';
    return (
        <div>
            <Typography style={{fontSize:20,fontWeight:"bold"}} display="inline">Total Patient Count : </Typography>
            <Typography style={{fontSize:20}} display="inline">{totalPatientCount}</Typography>
            <div style={{height:20}}/>
            <Typography style={{fontSize:20,fontWeight:"bold"}}>Available Beds</Typography>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        <TableCell className={classes.tableHeaderCell} align="center"/>
                        {bedStatuses.map(status=>
                            <TableCell key={status.id} className={classes.tableHeaderCell} 
                                colSpan={status.categories.length} 
                                align="center"
                            >
                                {status.name}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow padding="none" className={classes.tableCellRow}>
                        <TableCell className={classes.tableCell} align="center"></TableCell>
                        {bedStatuses.map(status=>status.categories.map(category=>
                            <TableCell key={category.id} className={classes.tableCell} align="center">
                                <Typography>{category.name}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                    {bedTypes.map(type=>
                        <TableRow key={type.id} className={classes.tableCellRow}>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography style={{fontWeight:"bold"}}>
                                    <Link component={RouterLink} 
                                        to={filterValue?
                                            eventUrl+`/available-beds/${type.id}/aggregate/${aggregates}/filter/${filterValue}`
                                            :eventUrl+`/available-beds/${type.id}/aggregate/${aggregates}`}
                                    >
                                        {type.name}
                                    </Link>
                                </Typography>
                            </TableCell>
                            {bedStatuses.map(status=>status.categories.map(category=>
                                <TableCell key={category.id}className={classes.tableCell} align="center">
                                    <Typography>
                                        <Link component={RouterLink} 
                                            to={filterValue?
                                                eventUrl+`/available-beds/${type.id}/aggregate/${aggregates}/filter/${filterValue}`
                                                :eventUrl+`/available-beds/${type.id}/aggregate/${aggregates}`}
                                        >
                                            {bedAvailability[type.id][status.id][category.id]}
                                        </Link>
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
}) 

export default withStyles(styles)(EventAvailableBedsTable);