import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, FormControl, Typography } from '@material-ui/core'

const styles = theme => ({
    tableHeaderCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 35,
    },
    tableCellRow: {
        height: 40,
    },
    tableCell: {
        width: 50,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
})

function TechnologyStatusTable(props) {
    const { classes, technologyItems, technologyItemStatus } = props
    if(!technologyItemStatus){
        return null;
    }
    return (
        <Table>
            <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                    <TableCell className={classes.tableHeaderCell} align="center">Technology Item</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">Status</TableCell>
                    {technologyItems.length>1?
                        <React.Fragment>
                            <TableCell className={classes.tableHeaderCell} align="center">Technology Item</TableCell>
                            <TableCell className={classes.tableHeaderCell} align="center">Status</TableCell>
                        </React.Fragment>
                        :undefined
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {technologyItems.map((item,index)=>
                    index%2===0?
                    <TableRow className={classes.tableCellRow} key={item.id}>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{item.name}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{technologyItemStatus[item.id]}</Typography>
                        </TableCell>
                        {technologyItems[index+1]?
                            <React.Fragment>
                                <TableCell className={classes.tableCell} align="center">
                                    <Typography>{technologyItems[index+1].name}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell} align="center">
                                    <Typography>{technologyItemStatus[technologyItems[index+1].id]}</Typography>
                                </TableCell>
                            </React.Fragment>
                            :undefined
                        }
                    </TableRow>
                    :undefined
                )}
            </TableBody>
        </Table>
    )
} 

export default withStyles(styles)(TechnologyStatusTable);