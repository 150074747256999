import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, FormControl, Typography } from '@material-ui/core'

const styles = theme => ({
    tableHeaderCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 35,
    },
    tableCellRow: {
        height: 40,
    },
    tableCell: {
        width: 50,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    select: {
        color: "#555555",
        fontSize: 14,
    },
    formControl: {
        minWidth: 130,
    }
})

const CreateTechnologyStatusTable = React.memo((props)=>{
    const { classes, technologyItems, technologyStatuses, technologyItemStatus, setTechnologyItemStatus } = props
    function handleDataChange(id){
        return function(event){
            setTechnologyItemStatus({
                ...technologyItemStatus, 
                [id]:  parseInt(event.target.value),
            })
        };
    }
    if(!technologyItemStatus){
        return null;
    }
    return (
        <Table>
            <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                    <TableCell className={classes.tableHeaderCell} align="center">Technology Item</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">Status</TableCell>
                    {technologyItems.length>1?
                        <React.Fragment>
                            <TableCell className={classes.tableHeaderCell} align="center">Technology Item</TableCell>
                            <TableCell className={classes.tableHeaderCell} align="center">Status</TableCell>
                        </React.Fragment>
                        :undefined
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {technologyItems.map((item,index)=>
                    index%2===0?
                    <TableRow className={classes.tableCellRow} key={item.id}>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{item.name}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <FormControl className={classes.formControl}>
                                <select
                                    className={classes.select}
                                    name="input"
                                    value={technologyItemStatus[item.id]}
                                    onChange={handleDataChange(item.id)}
                                >
                                    {technologyStatuses.map(status =>
                                        <option key={status.id} className={classes.option} value={status.id}>
                                            &nbsp;{status.name}
                                        </option>)
                                    }
                                </select>
                            </FormControl>
                        </TableCell>
                        {technologyItems[index+1]?
                            <React.Fragment>
                                <TableCell className={classes.tableCell} align="center">
                                    <Typography>{technologyItems[index+1].name}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell} align="center">
                                    <FormControl className={classes.formControl}>
                                        <select
                                            className={classes.select}
                                            name="input"
                                            value={technologyItemStatus[technologyItems[index+1].id]}
                                            onChange={handleDataChange(technologyItems[index+1].id)}
                                        >
                                            {technologyStatuses.map(status =>
                                                <option key={status.id} className={classes.option} value={status.id}>
                                                    &nbsp;{status.name}
                                                </option>)
                                            }
                                        </select>
                                    </FormControl>
                                </TableCell>
                            </React.Fragment>
                            :undefined
                        }
                    </TableRow>
                    :undefined
                )}
            </TableBody>
        </Table>
    )
})

export default withStyles(styles)(CreateTechnologyStatusTable);