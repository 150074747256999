import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Typography, Link, Grid } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

const styles = theme => ({
    tableHeaderCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 40,
    },
    tableCellRow: {
        height: 40,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
})

const EventTechnologyStatusTable = React.memo((props)=>{
    const { classes, eventId, technologyItems, technologyStatuses, aggregates, filterValue } = props
    const eventUrl = eventId?'/event/'+eventId:'/event';
    return (
        <div>
            <Typography style={{fontSize:20,fontWeight:"bold"}} display="inline">Technology Status</Typography>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        <TableCell className={classes.tableHeaderCell} align="center">Technology Item</TableCell>
                        {technologyStatuses.map(status=>
                            <TableCell key={status.id} className={classes.tableHeaderCell} align="center">
                                {status.name}
                            </TableCell>
                        )}
                        {technologyItems.length>1?
                            <React.Fragment>
                                <TableCell className={classes.tableHeaderCell} align="center">Technology Item</TableCell>
                                {technologyStatuses.map(status=>
                                    <TableCell key={status.id} className={classes.tableHeaderCell} align="center">
                                        {status.name}
                                    </TableCell>
                                )}
                            </React.Fragment>
                            :undefined
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {technologyItems.map((item,index)=>
                        index%2===0?
                        <TableRow className={classes.tableCellRow} key={item.id}>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>
                                    <Link component={RouterLink} 
                                        to={filterValue?
                                            eventUrl+`/technology/${item.id}/aggregate/${aggregates}/filter/${filterValue}`
                                            :eventUrl+`/technology/${item.id}/aggregate/${aggregates}`}
                                    >
                                        {item.name}
                                    </Link>
                                </Typography>
                            </TableCell>
                            {technologyStatuses.map(status=>
                                <TableCell key={status.id} className={classes.tableCell} align="center">
                                    <Typography>
                                        <Link component={RouterLink} 
                                            to={filterValue?
                                                eventUrl+`/technology/${item.id}/aggregate/${aggregates}/filter/${filterValue}`
                                                :eventUrl+`/technology/${item.id}/aggregate/${aggregates}`}
                                        >
                                            {item[status.id]}
                                        </Link>
                                    </Typography>
                                </TableCell>
                            )}
                            {technologyItems[index+1]?
                                <React.Fragment>
                                    <TableCell className={classes.tableCell} align="center">
                                        <Typography>
                                            <Link component={RouterLink} 
                                                to={filterValue?
                                                    eventUrl+`/technology/${technologyItems[index+1].id}/aggregate/${aggregates}/filter/${filterValue}`
                                                    :eventUrl+`/technology/${technologyItems[index+1].id}/aggregate/${aggregates}`}
                                            >
                                                {technologyItems[index+1].name}
                                            </Link>
                                        </Typography>
                                    </TableCell>
                                    {technologyStatuses.map(status=>
                                        <TableCell key={status.id} className={classes.tableCell} align="center">
                                            <Typography>
                                                <Link component={RouterLink} 
                                                    to={filterValue?
                                                        eventUrl+`/technology/${technologyItems[index+1].id}/aggregate/${aggregates}/filter/${filterValue}`
                                                        :eventUrl+`/technology/${technologyItems[index+1].id}/aggregate/${aggregates}`}
                                                >
                                                    {technologyItems[index+1][status.id]}
                                                </Link>
                                            </Typography>
                                        </TableCell>
                                    )}
                                </React.Fragment>
                                :undefined
                            }
                        </TableRow>
                        :undefined
                    )}
                </TableBody>
            </Table>
        </div>
    )
}) 

export default withStyles(styles)(EventTechnologyStatusTable);