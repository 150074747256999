import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Grid, CircularProgress } from '@material-ui/core'
import axios from 'axios';
import ReportDetails from './ReportDetails';
import { handleAxiosError, getAPIUrl } from '../AppUtils';

const styles = theme => ({
});

function ReportDetailsPage(props) {
    const { classes, match, logo, orgSettings } = props;
    const source = axios.CancelToken.source();

    const [reportDetails, setReportDetails] = useState(undefined);
    const [loadingReport, setLoadingReport] = useState(true);
    //mount, unmount
    useEffect(() => {
        getReportDetails();
        return () => {source.cancel("unmount");}
    }, []);

    function getReportDetails() {
        axios.get(getAPIUrl()+`/reportDetails/${match.params.id}`,{cancelToken: source.token})
        .then(response => {
            setReportDetails(response.data);
            setLoadingReport(false);
        })
        .catch(error => {
            if(handleAxiosError(error)!=='cancel'){
                setLoadingReport(false);
            }
        });
    }
    return (
        loadingReport?
            <Grid container justify='center' alignItems='center'>
                <CircularProgress/>
            </Grid>
            :reportDetails?
            <ReportDetails
                reportDetails={reportDetails}
                orgSettings={orgSettings}
                logo={logo}
                history={props.history}
            />
            :<Grid container justify='center' alignItems='center'>
                <Typography className={classes.text} variant="h4">
                    Report Not Found
                </Typography>
            </Grid>
    );
}

export default withStyles(styles)(ReportDetailsPage);