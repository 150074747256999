import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogContent, DialogActions, Typography, DialogTitle, RadioGroup, Radio,
    FormControl, Tooltip, FormControlLabel, Checkbox, Grid, CircularProgress, FormGroup, Select, MenuItem } from '@material-ui/core'
import axios from 'axios';
import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import InfoIcon from '@material-ui/icons/Info';
import EditScheduledEventDialog from '../EditScheduledEventDialog';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: 200,
    },
})

function CreateEventButton(props){
    const { classes, refreshEvents } = props;
    // const source = axios.CancelToken.source();
    const [open, setOpen] = useState(false);
    // const eventName = useFormInput("");
    // const recurringEvent = useCheckbox(false);
    // const frequency = useFormInput("daily");
    // const daysOfWeek = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"];
    // const [selectedDays, setSelectedDays] = useState({});
    // const openTime = useFormInput("07:30");
    // const closeAfterTime = useFormInput(24);
    // const closeAfterType = useFormInput('nextevent');
    // const closeAfterTimeInterval = useFormInput('hours');
    // const [endDate, setEndDate] = useState(null);
    // const [startDate, setStartDate] = useState(new Date());
    // const [errorMessage, setErrorMessage] = useState("");

    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div>
            <Button variant="contained" color="secondary" onClick={()=>setOpen(true)}>Create Quick StatRep Event</Button>
            <EditScheduledEventDialog
                open={open}
                handleClose={()=>setOpen(false)}
                refreshEvents={refreshEvents}
                title={'Create Quick StatRep Event'}
                editTitle="Edit Quick StatRep Scheduled Event"
                createEventRoute='/createQuickStatRepEvent'
                editScheduledEventRoute='/editQuickStatRepScheduledEvent'
                createScheduledEventRoute='/createQuickStatRepScheduledEvent'
            />
        </div>
        </MuiPickersUtilsProvider>
    )
}

export default withStyles(styles)(CreateEventButton);