import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const styles = theme => ({
});

const BarChart = React.memo((props)=>{
    const { classes, labels, datasets, title, height } = props
    const chartData = {
      labels: labels,
      datasets: datasets,
    }
    const options = {
        // responsive: false,
        // layout: {
        //     padding: {
        //         top: 50,
        //     }
        // },
        title: {
          display: true,
          text: title,
          fontSize: 20,
          fontColor: '#191F2D',
          padding: 40,
        //   fontStyle: 'normal',
        },
        legend: {
          display: true,
          position: 'bottom',
          labels:{boxWidth:20, fontSize:12 } 
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display:false
                },
                ticks: {
                    fontSize: 12
                },
            }],
            yAxes: [{
                gridLines: {
                    drawBorder: false,
                    display:true
                },
                ticks: {
                    display: true,
                    beginAtZero: true 
                }
            }],
        },
        tooltips: {
            // enabled: false,
            // mode : 'label',
        },
        hover: {mode: null},
        plugins: {
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'top',
                // align: function(context) {
                //     return context.dataset.data[context.dataIndex]/context.chart.scales['y-axis-0'].max > 0.075?'middle':'top';
                // },
                font: {
                    // weight: 'bold'
                },
            }
        },
    };
    return(
        <Bar
          width={500}
          height={height?height:150}
          data={chartData}
          options={options}
        />
    )

})

export default withStyles(styles)(BarChart);