import { createStore } from 'redux';
import { SET_INITIAL_DATA, SET_STRIKE_INITIAL_DATA } from './actions';

const INITIAL_STATE = {
    identifierColumns: [],
    departmentAlias: "Department",
    departments: [],
    filters: [],
    unions: [],
    shifts: [],
  };

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_INITIAL_DATA:
            return Object.assign({},state,{
                identifierColumns:action.data.identifierColumns,
                departmentAlias:action.data.departmentAlias,
                departments:action.data.departments,
                filters:action.data.filters,
        });
        case SET_STRIKE_INITIAL_DATA:
            return Object.assign({},state,{
                unions:action.data.unions,
                shifts:action.data.shifts,
            });
      default:
        return state;
    }
  };

const store = createStore(reducer);
export default store