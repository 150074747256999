import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';

const styles = theme => ({
});

const ResourceBarChart = React.memo((props)=>{
    const { classes, colors, resourceItems } = props
    const labels = ["Total On Hand","Available to Deploy"];
    const chartData = {
      labels: resourceItems.map(n=>n.name),
      datasets: [{
        label: labels[0],
        backgroundColor: colors[0],
        data: resourceItems.map(n=>n.onHand),
      },{
        label: labels[1],
        backgroundColor: colors[1],
        data: resourceItems.map(n=>n.availableForDeployment),
      }]
    }
    const options = {
        // responsive: false,
        plugins: {
          title: {
            display: true,
            text: "Resource",
            font: {size: 14},
            color: '#191F2D',
          //   fontStyle: 'normal',
          },
          legend: {
            display: true,
            position: 'bottom',
            labels:{boxWidth:20, font:{size: 10} } 
          },
          tooltips: {
            mode : 'label',
          },
        },
        
        scales: {
            x: {
                grid: {
                    display:false
                }
            },
            y: {
                grid: {
                    display:false
                }   
            }
        },

    };
    return(
        <Bar
        //   width={380}
          height={200}
          data={chartData}
          options={options}
        />
    )

})

export default withStyles(styles)(ResourceBarChart);