import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Typography, Link,
        TableSortLabel, Grid, IconButton } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, parseISO } from 'date-fns';
import {SortableLabel, stableSort, getSorting } from '../TableUtils'
import EditIcon from '@material-ui/icons/Edit';
import EditStrikeReportDialog from './EditStrikeReportDialog';
import { connect } from 'react-redux';

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 120,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
    },
    tableWrapper: {
        maxHeight: 450,
        overflow: 'auto',
    },
    tableHeaderRow: {
        height: 40,
    },
    tableRow: {
        height: 40,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    searchGrid: {
        marginBottom: 20,
    },
    totalText: {
        marginLeft: 15,
    },
    tableBody:{
        // display:'block',
        overflowY: 'scroll',
        maxHeight: 300,
        width: '100%',
    },
})

const StrikeDashboardTable = React.memo((props)=>{
    const { classes, strikeReports, identifierColumns, departmentAlias } = props
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('submit_time');
    const [editReport, setEditReport] = useState(undefined);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const handleRequestSort = property => event => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    const HeaderCell = (props) => {
        return (
        <TableCell className={classes.tableHeaderCell} align="center">
            <SortableLabel
                order={order}
                active={orderBy===props.property}
                handleSort={handleRequestSort(props.property)}
                label={props.label}
            />
        </TableCell>
    )}
    function handleClose(){
        setEditDialogOpen(false);
        setEditReport(undefined);
    }
    function downloadCSV(){
        const rows = [identifierColumns.map(n=>'"'+n.name+'"').concat([departmentAlias,"Union","Event","Shift","Normal Schedule Staffing",
            "Reduced Schedule Staffing","Safe Minimum Staffing","Actual Staff Who Showed","Strike Staff","Replacement Staff Used",
            "Total Work Staff","Walkout Rate Based on Reduced","Not Receive Replacement","Strike Plan","Last Modified",
            "Last Modified By"])].concat(
            stableSort(strikeReports, getSorting(order, orderBy)).map(report=>{
                return (
                    identifierColumns.map(n=>report[n.id+"_name"]).concat(
                        ['"'+report.department_name+'"',report.union_name,report.strike_name,report.shift_name,report.normal_staffing,
                        report.reduced_staffing,report.safe_staffing,report.actual_staffing,report.reduced_staffing-report.actual_staffing,
                        report.used_replacement_staffing,report.total_work_staff,report.reduced_walkout_rate,
                        report.request_replacement_failed?'Yes':'No','"'+report.strike_plan+'"',
                        '"'+format(parseISO(report.last_modified),'MMM dd, yyyy hh:mm a')+'"',
                        report.user_first_name + ' ' + report.user_last_name]
                    )
                )
            })
        );
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "intelhs_strike_dashboard.csv");
        document.body.appendChild(link);
        link.click();
    }
    return (
        <div>
            <EditStrikeReportDialog
                open={editDialogOpen}
                setOpen={setEditDialogOpen}
                report={editReport}
                handleClose={handleClose}
            />
            <Grid container justify='space-between'>
                <Typography style={{fontSize:20,fontWeight:"bold"}} display="inline">Summary Table</Typography>
                <div>
                    <IconButton className={classes.iconButton}
                        onClick={()=>downloadCSV()}
                    >
                        <FontAwesomeIcon size="xs" icon={["fa","file-download"]}/>
                    </IconButton>
                </div>
            </Grid>
            <div className={classes.tableWrapper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow className={classes.tableHeaderRow}>
                            {/* <TableCell className={classes.tableHeaderCell} align="center">
                                <SortableLabel
                                    order={order}
                                    active={orderBy==="name"}
                                    handleSort={handleRequestSort("name")}
                                    label={departmentAlias}
                                />
                            </TableCell> */}

                            {/* <HeaderCell property={''} label={"Director"}/> */}
                            <TableCell className={classes.tableHeaderCell}/>
                            {identifierColumns.map(n=>
                                <HeaderCell key={n.id} property={''} label={n.name}/>
                            )}
                            <HeaderCell property={'department_name'} label={departmentAlias}/>
                            <HeaderCell property={'union_name'} label={"Union"}/>
                            <HeaderCell property={'strike_name'} label={"Event"}/>
                            <HeaderCell property={'shift_name'} label={"Shift"}/>
                            <HeaderCell property={'normal_staffing'} label={"Normal Schedule Staffing"}/>
                            <HeaderCell property={'reduced_staffing'} label={"Reduced Schedule Staffing"}/>
                            <HeaderCell property={'safe_staffing'} label={"Safe Minimum Staffing"}/>
                            <HeaderCell property={'actual_staffing'} label={"Actual Staff Who Showed"}/>
                            <HeaderCell property={''} label={"Strike Staff"}/>
                            <HeaderCell property={'used_replacement_staffing'} label={"Replacement Staff Used"}/>
                            <HeaderCell property={'total_work_staff'} label={"Total Work Staff"}/>
                            <HeaderCell property={'reduced_walkout_rate'} label={"Walkout Rate Based on Reduced"}/>
                            <HeaderCell property={''} label={"Not Receive Replacement"}/>
                            <HeaderCell property={''} label={"Strike Plan"}/>
                            <HeaderCell property={''} label={"Last Modified"}/>
                            <HeaderCell property={''} label={"Last Modified By"}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(strikeReports, getSorting(order, orderBy))
                        .map(report=>
                        <TableRow className={classes.tableRow} key={report.id}>
                            <TableCell className={classes.tableCell} align="center">
                                <IconButton
                                    fontSize="15"
                                    onClick={()=>{
                                        setEditReport(report);
                                        setEditDialogOpen(true);
                                    }}
                                >
                                    <EditIcon/>
                                </IconButton>
                            </TableCell>
                            {identifierColumns.map(n=>
                                <TableCell key={n.id} className={classes.tableCell} align="center">
                                    <Typography>
                                        {report[n.id+"_name"]}
                                    </Typography>
                                </TableCell>
                            )}
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{report.department_name}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{report.union_name}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{report.strike_name}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{report.shift_name}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{report.normal_staffing}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{report.reduced_staffing}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{report.safe_staffing}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{report.actual_staffing}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{report.reduced_staffing-report.actual_staffing}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{report.used_replacement_staffing}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{report.total_work_staff}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{report.reduced_walkout_rate}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{report.request_replacement_failed?'Yes':'No'}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{report.strike_plan}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{format(parseISO(report.last_modified),'MMM dd, yyyy hh:mm a')}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>{report.user_first_name + ' ' + report.user_last_name}</Typography>
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}) 

const mapStateToProps = (state) => {
    const { departmentAlias, identifierColumns } = state
    return { departmentAlias, identifierColumns }
};

export default withStyles(styles)(connect(mapStateToProps)(StrikeDashboardTable));