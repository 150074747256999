import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Link, Typography, IconButton,
        Tooltip, TableSortLabel, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, 
        InputAdornment } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormInput } from './CustomHooks'
import CustomizedTextField from './CustomizedTextField';
import Pagination from 'react-bootstrap/Pagination'
import './custom.scss';
import axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import { handleAxiosError, getAPIUrl } from './AppUtils';

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 100,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 30,
    },
    tableRow: {
        height: 40,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    searchGrid: {
        marginBottom: 20,
    },
    totalText: {
        marginLeft: 15,
    },
    pagination:{
        marginTop: 30,
    },
    editButton: {
        float: "right",
        padding: 5,
    },
    select: {
        color: "#555555",
        fontSize: 14,
    },
    formControl: {
        minHeight: 20,
        marginLeft: 5
    },
    emailTextField: {
        width: 300
    },
})
function SortableLabel(props){
    return(
        <Tooltip
            title="Sort"
            placement='bottom-end'
            enterDelay={300}
        >
            <TableSortLabel
                active={props.active}
                hideSortIcon
                direction={props.order}
                onClick={props.handleSort}
                IconComponent={(props) => <FontAwesomeIcon {...props} size="lg" icon={["fa","caret-down"]}/>}
            >
                {props.label}
            </TableSortLabel>
        </Tooltip>
    )
}

function SettingsUsersTab(props) {
    const { classes } = props
    const source = axios.CancelToken.source();
    const [users, setUsers] = useState([]);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    // const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    // const [editUser, setEditUser] = useState({});
    const [removeAdminDialogOpen, setRemoveAdminDialogOpen] = useState(false);
    const [removeAdminUser, setRemoveAdminUser] = useState({});
    const searchText = useFormInput("");
    const addEmail = useFormInput("");
    const addName = useFormInput("");
    const selectedUserType = useFormInput(1);
    const rowsPerPage = 10;
    const userTypes= {
        0: "Non-Admin",
        1: "Admin",
        2: "Hospital Admin",
        3: "Super Admin"
    }
    useEffect(()=>{
        getUsers();
        return () => {source.cancel("unmount");}
    },[]);
    useEffect(()=>{
        setPage(0);
    },[searchText.value]);
    function getUsers(){
        axios.get(getAPIUrl()+'/users',{cancelToken: source.token})
        .then(response => {
            setUsers(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    const handleRequestSort = property => event => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    function stableSort(array, cmp) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map(el => el[0]);
    }
    function getSorting(order, orderBy) {
      return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
    }
    function desc(a, b, orderBy) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
    function filterFunc(input){
        if(searchText.value===""){
            return true;
        }
        const searchTerms = searchText.value.split(/[\s,]+/);
        const notContained = searchTerms.some(searchTerm=>{
            let containsTerm = false;
            Object.values(input).forEach(field=>{
                if(field.toString().toLowerCase().includes(searchTerm.toLowerCase())){
                    containsTerm = true;
                }
            })
            return !containsTerm
        })
        return !notContained;
    }
    function handlePageChange(pageNum){
        setPage(pageNum);
    }
    const filteredUsers = users?users.filter(n=>n.is_admin>0).filter(n=>filterFunc(n)):[]
    const numPages = Math.ceil(filteredUsers.length/rowsPerPage);
    function handlePageIncrease(){
        if(page<numPages-1){
            setPage(page+1);
        }
    }
    function handlePageDecrease(){
        if(page>0){
            setPage(page-1);
        }
    }
    // function handleEditUser(user){
    //     setEditUser(user);
    //     selectedUserType.onChange({target:{value:user.is_admin}});
    //     setEditDialogOpen(true);
    // }
    // function handleEditClose(){
    //     setEditDialogOpen(false);
    // }
    // function handleEditSave(){
    //     axios.post(getAPIUrl()+`/editUser`,{id:editUser.id,is_admin:selectedUserType.value})
    //     .then(response=>{
    //         getUsers();
    //     })
    //     .catch(error=>handleAxiosError(error))
    //     handleEditClose();
    // }
    function handleRemoveAdminClose(){
        setRemoveAdminDialogOpen(false);
    }
    function handleRemoveAdmin(user){
        setRemoveAdminUser(user);
        setRemoveAdminDialogOpen(true);
    }
    function confirmRemoveAdmin(){
        axios.post(getAPIUrl()+'/removeAdmin',{id:removeAdminUser.id})
        .then(response=>{
            getUsers();
        })
        .catch(error=>handleAxiosError(error))
        handleRemoveAdminClose();
    }
    function handleAddAdmin(){
        setAddDialogOpen(true);
    }
    function handleAddSave(){
        axios.post(getAPIUrl()+`/addAdmin`,{email:addEmail.value,type:selectedUserType.value,name:addName.value})
        .then(response=>{
            getUsers();
        })
        .catch(error=>handleAxiosError(error))
        handleAddClose();
    }
    function handleAddClose(){
        setAddDialogOpen(false);
        addEmail.onChange({target:{value:''}})
        addName.onChange({target:{value:''}})
        selectedUserType.onChange({target:{value:1}})
    }
    function downloadCSV(){
        const rows = [["Name","Email","Type"]].concat(
            stableSort(users.filter(n=>n.is_admin>0), getSorting(order, orderBy)).map(user=>
                [user.name,user.email,userTypes[user.is_admin]]
            )
        );
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "intelhs_statrep_admin_list.csv");
        document.body.appendChild(link);
        link.click();
    }
    return (
        <div style={{marginTop: 30}}>
            {/* <Dialog 
                open={editDialogOpen}
                onClose={handleEditClose}
            >
                <DialogTitle>Edit User</DialogTitle>
                <DialogContent style={{minWidth:500}}>
                    <Typography display="inline" style={{fontWeight:"bold"}}>Email: </Typography>
                    <Typography display="inline">{editUser.email}</Typography>
                    <div style={{marginTop:10}}>
                        <Typography display="inline" style={{fontWeight:"bold"}}>User Type: </Typography>
                        <FormControl className={classes.formControl}>
                            <select
                                className={classes.select}
                                name="input"
                                {...selectedUserType}
                            >
                                <option className={classes.option} value={0}>Non-Admin</option>
                                <option className={classes.option} value={1}>Admin</option>
                            </select>
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.button} onClick={handleEditSave} variant="contained" color="secondary">
                        Save
                    </Button>
                    <Button className={classes.button} onClick={handleEditClose} variant="contained" color="default">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog> */}
            <Dialog 
                open={removeAdminDialogOpen}
                onClose={handleRemoveAdminClose}
            >
                <DialogTitle>Remove Admin Privileges from {removeAdminUser.email}</DialogTitle>
                <DialogActions>
                    <Button className={classes.button} onClick={confirmRemoveAdmin} variant="contained" color="secondary">
                        Confirm
                    </Button>
                    <Button className={classes.button} onClick={handleRemoveAdminClose} variant="contained" color="default">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog 
                open={addDialogOpen}
                onClose={handleAddClose}
            >
                <DialogTitle>Add Admin</DialogTitle>
                <DialogContent style={{minWidth:500}}>
                    <Grid container alignItems="center" style={{marginBottom: 20}}>
                        <Typography style={{fontWeight:"bold", marginRight: 20}}>Type: </Typography>
                        <FormControl className={classes.formControl}>
                            <select
                                className={classes.select}
                                name="input"
                                {...selectedUserType}
                            >
                                {/* <option className={classes.option} value={0}>Non-Admin</option> */}
                                <option className={classes.option} value={1}>Admin</option>
                                <option className={classes.option} value={3}>Super Admin</option>
                            </select>
                        </FormControl>
                    </Grid>
                    <Grid container alignItems="center" style={{marginBottom: 20}}>
                        <Grid item xs={2}>
                            <Typography style={{fontWeight:"bold", marginRight: 20}}>Name: </Typography>
                        </Grid>
                        <CustomizedTextField
                            className={classes.emailTextField}
                            variant="outlined"
                            {...addName}
                        />
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item xs={2}>
                            <Typography style={{fontWeight:"bold", marginRight: 20}}>Email: </Typography>
                        </Grid>
                        <CustomizedTextField
                            className={classes.emailTextField}
                            variant="outlined"
                            {...addEmail}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.button} onClick={handleAddSave} variant="contained" color="secondary">
                        Add
                    </Button>
                    <Button className={classes.button} onClick={handleAddClose} variant="contained" color="default">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container justify="center">
                <Typography style={{fontWeight: "bold", marginBottom:10}} variant="h4">Admins</Typography>
            </Grid>
            <Grid className={classes.searchGrid} container alignItems='center'>
                <Grid item xs={6}>
                    <CustomizedTextField
                        variant="outlined"
                        endAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>}
                        {...searchText}
                    />
                </Grid>
                <Grid item container xs={6} justify="flex-end">
                    <Button className={classes.addButton} onClick={handleAddAdmin} variant="contained" color="secondary">
                        Add Admin
                    </Button>
                    <Button style={{marginLeft: 10}} className={classes.addButton}
                        onClick={()=>downloadCSV()}
                        variant="contained" color="secondary"
                    >
                        Download
                    </Button>
                </Grid>
            </Grid>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="name"}
                                handleSort={handleRequestSort("name")}
                                label="Name"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="email"}
                                handleSort={handleRequestSort("email")}
                                label="Email"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="is_admin"}
                                handleSort={handleRequestSort("is_admin")}
                                label="Type"
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stableSort(filteredUsers, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(user=>
                    <TableRow className={classes.tableRow} key={user.id}>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{user.name}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{user.email}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Grid container alignItems="center">
                                <Grid item xs={3}/>
                                <Grid item xs={6}>
                                    <Typography>{userTypes[user.is_admin]}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    {user.is_admin<3?
                                        <IconButton className={classes.editButton} 
                                            onClick={()=>handleRemoveAdmin(user)}
                                        >
                                            <FontAwesomeIcon size="xs" icon={["fa","trash-alt"]}/>
                                        </IconButton>:undefined
                                    }
                                    {/* {user.is_admin<3?
                                        <IconButton className={classes.editButton} 
                                            onClick={()=>handleEditUser(user)}
                                        >
                                            <FontAwesomeIcon size="xs" icon={["fa","edit"]}/>
                                        </IconButton>:undefined
                                    } */}
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
            <div>
                
            <Pagination className={classes.pagination}>
                <Pagination.First onClick={()=>handlePageChange(0)}/>
                <Pagination.Prev onClick={handlePageDecrease} disabled={page===0}/>
                {page-10>0?<Pagination.Ellipsis/>:undefined}
                {Array(numPages).fill().map((_, i) => {
                    if(i>page-10 && i<page+10){
                        return(
                            <Pagination.Item key={i} 
                                active={page===i} 
                                onClick={()=>handlePageChange(i)}
                            >
                                {i+1}
                            </Pagination.Item>
                        );
                    }
                })}
                {page+10<numPages-1?<Pagination.Ellipsis/>:undefined}
                <Pagination.Next onClick={handlePageIncrease} disabled={page>=numPages-1}/>
                <Pagination.Last onClick={()=>handlePageChange(numPages-1)}/>
            </Pagination>
            </div>
        </div>
    )
} 

export default withStyles(styles)(SettingsUsersTab);