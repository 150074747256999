import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, ListItem, List, ListItemText, Paper, Typography } from '@material-ui/core'
import SettingsUsersTab from './SettingsUsersTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = theme => ({
    page: {
        minHeight: 750,
        paddingTop: 10,
        paddingBottom: 10,
    },
    buttons:{
        paddingTop: 20,
    },
    listItem:{
        height: 40,
    },
    selectedItem:{
        fontWeight: "bold",
    },
    list: {
        width: 200,
        // backgroundColor: theme.palette.background.paper,
        // borderStyle: "solid",
        // borderWidth: 1,
        // borderColor: theme.palette.secondary.main,
        // borderRadius: 5,
    },
});

function SettingsPage(props) {
    const { classes } = props;
    const [selectedTab, setSelectedTab] = useState("admins");
    const navigationTabs = [
        {id: "admins", label: "Admins"}
    ];
    return (
        <Grid className={classes.page} container justify='center'>
            <Grid className={classes.buttons} item xs={2} container direction = 'column' justify='flex-start' alignItems='center'>
                <List component="nav" className={classes.list}>
                    {navigationTabs.map(n=>
                        <ListItem key={n.id} button className={classes.listItem} 
                            selected={selectedTab===n.id}
                            onClick={()=>setSelectedTab(n.id)}
                            classes={{selected:classes.selectedItem}}
                        >
                            <FontAwesomeIcon size="1x" icon={["fa","users"]} style={{marginRight:12}}/>{n.label}
                        </ListItem>
                    )}
                </List>
            </Grid>
            <Grid item xs={9}>
                <SettingsUsersTab/>
            </Grid>
            <Grid item xs={1}/>
        </Grid>
    );
}

export default withStyles(styles)(SettingsPage);