import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Grid, Select, MenuItem, FormControl, Typography } from '@material-ui/core'
import BackgroundImage from './images/iStock-695360068.jpg';
import { useFormInput, useFormInputClear } from './CustomHooks';
import axios from 'axios';
import { handleAxiosError, getAPIUrl } from './AppUtils';
import { connect } from 'react-redux';

const inputWidth = 300
const styles = theme => ({
    background: {
        backgroundSize: "cover",
        backgroundImage: `url(${BackgroundImage})`,
        height: "90vh",
        width: "100%"
    },
    gridContainer: {
        height: "100%",
        width: "100%",
        paddingTop: 65,
    },
    companyLogo: {
        borderRadius: 5,
        width: inputWidth,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#e2e2e2",
        backgroundColor: "#FFF",
    },
    formControl: {
        width: inputWidth-2,
        backgroundColor: "#FFF",
        borderRadius: 5,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#e2e2e2"
    },
    option: {
        // overflow: "hidden",
    },
    select: {
        color: "#555555",
        fontSize: 14,
    },
    startButton: {
        width: inputWidth,
    },
    startDisabled:{
        width: inputWidth,
        opacity: 0.6,
        '&:hover': {
            cursor: "not-allowed",
        },
    }
});

function StartPage(props) {
    const { classes, logo, orgSettings, departmentAlias, filters, departments } = props;
    const source = axios.CancelToken.source();
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [startDisabled, setStartDisabled] = useState(false);
    const [department,setDepartment] = useState(undefined);
    const [filterChoice, setFilterChoice] = useState({});
    const [departmentDisabled, setDepartmentDisabled] = useState(true);
    const [filterOptions, setFilterOptions] = useState([]);
    const [quickStatRepAggregates, setQuickStatRepAggregates] = useState(undefined);
    const [quickStatRepEnabled, setQuickStatRepEnabled] = useState(false);
    //mount, unmount
    useEffect(() => {
        if(props.adminView){
            props.setAdminView(false);
        }
        if(orgSettings.quickStatRep){
            getQuickStatRepAggregates()
        }
        return () => {source.cancel("unmount");}
    }, []);
    useEffect(() => {
        if(filters.length > 0 && filters.every(n=>filterChoice[n.info.key])){
            let filteredDepartments = departments.filter(n=>
                filters.every(filter=>parseInt(n[filter.info.key])===parseInt(filterChoice[filter.info.key]))
            )
            if(orgSettings.quickStatRep){
                //Hard coded, fix later
                filteredDepartments = [{id:'notapplicable',name:'Not Applicable'}].concat(filteredDepartments);
            }
            setDepartmentOptions(filteredDepartments)
            if(filteredDepartments.length===0){
                setDepartmentDisabled(true);
                setDepartment("");
            }
            else if(filteredDepartments.length===1){
                setDepartmentDisabled(false);
                setDepartment(filteredDepartments[0].id)
            }
            else{
                setDepartmentDisabled(false);
                setDepartment("");
            }
        }
        else{
            setDepartmentDisabled(true);
            setDepartment("");
        }
    },[filterChoice])
    useEffect(() => {
        //HARD CODED notapplicable right now, fix later
        if(department=='notapplicable'){
            let filteredDepartments = departments.filter(n=>
                filters.every(filter=>parseInt(n[filter.info.key])===parseInt(filterChoice[filter.info.key]))
            )
            let selectedDepartment = filteredDepartments[0];
            //HARD CODED FIX LATER TO MATCH AGGREGATE TO FILTEROPTION
            props.setDepartmentInfo({
                labels: quickStatRepAggregates.map((n,i)=>{
                    return n.name + ': ' + filterOptions[i].find(m=>parseInt(m.id)===parseInt(filterChoice[n.id])).name;
                }),
                departmentId: selectedDepartment.id,
                hospitalId: selectedDepartment.hospital,
            });
            setQuickStatRepEnabled(true);
        }
        else if(parseInt(department)>0){
            let selectedDepartment = departmentOptions.find(n=>n.id.toString()===department.toString())
            props.setDepartmentInfo({
                labels: filters.map((n,i)=>{
                    return n.info.name + ': ' + filterOptions[i].find(m=>parseInt(m.id)===parseInt(filterChoice[n.info.key])).name;
                }).concat([departmentAlias + ': ' + selectedDepartment.name]),
                departmentId: selectedDepartment.id,
                hospitalId: selectedDepartment.hospital,
            });
            setQuickStatRepEnabled(false);
        }
        else{
            props.setDepartmentInfo(undefined);
            setQuickStatRepEnabled(false);
        }
    },[department]);
    useEffect(() => {
        if(props.departmentInfo && !quickStatRepEnabled){
            setStartDisabled(false);
        }
        else{
            setStartDisabled(true);
        }
    },[props.departmentInfo])
    useEffect(()=>{
        setFilterOptions(filters.map((n,i)=>{
            if(i===0){
                return n.options
            }
            else{
                return [];
            }
        }))
    },[filters])
    function getQuickStatRepAggregates(){
        axios.get(getAPIUrl()+'/quickStatRepAggregates',{cancelToken: source.token})
        .then(response => {
            setQuickStatRepAggregates(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function goToCreateStatusReport(event) {
        if(!startDisabled){
            props.history.push("/emstatrep");
        }
    }
    function goToCreateStrikeReport(event) {
        if(!startDisabled){
            props.history.push("/createStrikeReport");
        }
    }
    function goToCreateQuickStatRep(event) {
        if(quickStatRepEnabled){
            props.history.push("/createQuickStatRep");
        }
    }
    function handleFilterChange(index, key, e){
        let newFilterChoice = {
            ...filterChoice,
            [key]: e.target.value
        }
        var newFilterOptions = filterOptions.slice();
        filters.slice(index+1).forEach((n,i)=>{
            newFilterChoice[n.info.key]="";
            if(i===0){
                //filter subsequent option to show only still possible options
                let relevantFilters = filters.slice(0,index+1);
                let possibleOptions = departments.filter(n=>
                    relevantFilters.every(filter=>parseInt(n[filter.info.key])===parseInt(newFilterChoice[filter.info.key]))
                ).reduce((obj,n)=>{
                    obj[n[filters[index+1].info.key]] = true;
                    return obj
                },{});
                newFilterOptions[index+1] = filters[index+1].options.filter(n=>possibleOptions[n.id]);
            }
            else{
                newFilterOptions[index+1+i] = [];
            }
        });
        setFilterOptions(newFilterOptions);
        setFilterChoice(newFilterChoice);
    }
    if(filters.length!==filterOptions.length){
        return (<div className={classes.background}/>)
    }
    return (
        <div className={classes.background}>
            <Grid className={classes.gridContainer} container direction='column' justify='flex-start' 
                alignItems='center' spacing={2}
            >
                <Grid item>
                    <img className={classes.companyLogo}
                        src={logo}
                        alt=''
                    />
                </Grid>
                {filters.map((n,i)=>
                <Grid item key={n.info.key}>
                    <FormControl className={classes.formControl}>
                        <Select
                            className={classes.select}
                            disableUnderline 
                            native
                            onChange={e=>handleFilterChange(i,n.info.key,e)}
                            value={filterChoice[n.info.key]}
                            disabled={filterOptions[i].length===0}
                        >
                            <option className={classes.option} value="">
                                &nbsp;&nbsp;&nbsp;&nbsp;Select {n.info.name}
                            </option>
                            {filterOptions[i].map(option =>
                                <option key={option.id} className={classes.option} value={option.id}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;{option.name}
                                </option>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                )}
                <Grid item>
                    <FormControl className={classes.formControl}>
                        <Select
                            disabled={departmentDisabled}
                            className={classes.select}
                            disableUnderline 
                            native
                            value={department}
                            onChange={e=>setDepartment(e.target.value)}
                        >
                            <option className={classes.option} value="">
                                &nbsp;&nbsp;&nbsp;&nbsp;Select {departmentAlias}
                            </option>
                            {departmentOptions.map(option =>
                                <option key={option.id} className={classes.option} value={option.id}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;{option.name}
                                </option>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Button className={startDisabled?classes.startDisabled:classes.startButton} color="secondary"
                        variant="contained"
                        onClick = {goToCreateStatusReport} 
                    >
                        Start StatRep
                    </Button>
                </Grid>
                {orgSettings.strikeDashboard?
                    <Grid item>
                        <Button className={startDisabled?classes.startDisabled:classes.startButton} color="secondary"
                            variant="contained"
                            onClick = {goToCreateStrikeReport} 
                        >
                            Start Strike Report
                        </Button>
                    </Grid>:undefined
                }
                {orgSettings.quickStatRep?
                    <Grid item>
                        <Button className={!quickStatRepEnabled?classes.startDisabled:classes.startButton} color="secondary"
                            variant="contained"
                            onClick = {goToCreateQuickStatRep} 
                        >
                            Start Quick StatRep
                        </Button>
                    </Grid>:undefined
                }
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { departmentAlias, departments, filters } = state
    return { departmentAlias, departments, filters }
};

export default withStyles(styles)(connect(mapStateToProps)(StartPage));