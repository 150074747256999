import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Link, Typography } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

const styles = theme => ({
    text: {
        fontSize: 26,
        fontWeight: 500,
        color: 'black',
    },
    page: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 250,
    }
});

function ReportSubmittedPage(props) {
    const { classes } = props;
    React.useEffect(() => {
        if(props.adminView){
            props.setAdminView(false);
        }
    }, []);
    return (
        <Grid className={classes.page} container direction='column' justify='center' alignItems='center'>
            <Typography className={classes.text} variant="h4">Your report has been submitted!</Typography>
            <br/>
            <Typography className={classes.text}  variant="h4">
                Click <Link color="secondary" component={RouterLink} to="/report-list">here</Link> to see all your reports.
            </Typography>
        </Grid>
    );
}

export default withStyles(styles)(ReportSubmittedPage);