import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Typography, Link,
        TableSortLabel, Grid, IconButton } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns';

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 120,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
        // position: "sticky",
        // top: 0
    },
    tableHeaderRow: {
        height: 30,
    },
    tableRow: {
        height: 40,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    greenCell: {
        backgroundColor: '#38a438',
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    redCell: {
        backgroundColor: 'red',
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    searchGrid: {
        marginBottom: 20,
    },
    totalText: {
        marginLeft: 15,
    },
    pagination:{
        marginTop: 30,
    },
    tableBody:{
        // display:'block',
        // overflowY: 'scroll',
        // maxHeight: 300,
        // width: '100%',
    },
})
function SortableLabel(props){
    return(
        <TableSortLabel
            active={props.active}
            hideSortIcon
            direction={props.order}
            onClick={props.handleSort}
            IconComponent={(props) => <FontAwesomeIcon {...props} size="lg" icon={["fa","caret-down"]}/>}
        >
            {props.label}
        </TableSortLabel>
    )
}

function EventStaffingTable(props) {
    const { classes, eventId, allStaffTitles, totalStaffing, aggregates, filterValue, identifierColumns, departmentAlias, 
        additionalColumns } = props
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('present');
    const handleRequestSort = property => event => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    function stableSort(array, cmp) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map(el => el[0]);
    }
    function getSorting(order, orderBy) {
      return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
    }
    function desc(a, b, orderBy) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
    function downloadCSV(){
        const rows = [identifierColumns.map(n=>'"'+n.name+'"').concat([departmentAlias,"Staff Title","Present"])
            .concat([additionalColumns.map(n=>'"'+n.name+'"')]).concat(["Available","Submit Time"])].concat(
            stableSort(totalStaffing, getSorting('asc', 'department_name')).map(staff=>{
                return (
                    identifierColumns.map(n=>staff[n.id+"_name"]).concat(
                        ['"'+staff.department_name+'"',staff.title_name,staff.present]
                        .concat(additionalColumns.map(n=>staff[n.id])).concat([staff.available,'"'+format(parseISO(staff.submit_time),'MMM dd, yyyy hh:mm a')+'"'])
                    )
                )
            })
        );
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "intelhs_staffing_summary.csv");
        document.body.appendChild(link);
        link.click();
    }
    allStaffTitles.forEach(title=>{
        const titleStaffing = totalStaffing.filter(n=>n.title===title.id);
        title.present = titleStaffing.reduce((sum,entry)=>sum+entry.present,0)
        title.available = titleStaffing.reduce((sum,entry)=>sum+entry.available,0)
        additionalColumns.forEach(col=>{
            title[col.id] = titleStaffing.reduce((sum,entry)=>entry[col.id]?sum+entry[col.id]:sum,0)
        })
    })
    // const widths = 
    //     <colgroup>
    //         <col style={{width:'40%'}}/>
    //         <col style={{width:'30%'}}/>
    //         <col style={{width:'30%'}}/>
    //     </colgroup>
    const eventUrl = eventId?'/event/'+eventId:'/event';
    return (
        <div>
            <Grid container justify='space-between'>
                <Typography style={{fontSize:20,fontWeight:"bold"}}>
                    <Link component={RouterLink} 
                        to={`/staffing/${eventId?eventId:''}`}
                    >
                        Staffing
                    </Link>
                    </Typography>
                <IconButton className={classes.iconButton}
                    onClick={()=>downloadCSV()}
                >
                    <FontAwesomeIcon size="xs" icon={["fa","file-download"]}/>
                </IconButton>
            </Grid>
            <Table>
                {/* {widths} */}
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="name"}
                                handleSort={handleRequestSort("name")}
                                label="Title"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="present"}
                                handleSort={handleRequestSort("present")}
                                label="Number Present"
                            />
                        </TableCell>
                        {additionalColumns.map(n=>
                            <TableCell key={n.id} className={classes.tableHeaderCell} align="center">
                                <SortableLabel
                                    order={order}
                                    active={orderBy===n.id}
                                    handleSort={handleRequestSort(n.id)}
                                    label={n.name}
                                />
                            </TableCell>
                        )}
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="available"}
                                handleSort={handleRequestSort("available")}
                                label="Available to Labor Pool"
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
            {/* </Table>
            <div  className={classes.tableBody}>
            <Table>
                {widths} */}
                <TableBody>
                    {stableSort(allStaffTitles.filter(n=>n.present>0||n.available>0), getSorting(order, orderBy))
                    .map(staffTitle=>
                    <TableRow className={classes.tableRow} key={staffTitle.id}>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>
                                <Link component={RouterLink} 
                                    to={filterValue?
                                        eventUrl+`/staffing/${staffTitle.id}/aggregate/${aggregates}/filter/${filterValue}`
                                        :eventUrl+`/staffing/${staffTitle.id}/aggregate/${aggregates}`}
                                >
                                    {staffTitle.name}
                                </Link>
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{staffTitle.present}</Typography>
                        </TableCell>
                        {additionalColumns.map(n=>
                            <TableCell key={n.id} className={classes.tableCell} align="center">
                                <Typography>{staffTitle[n.id]}</Typography>
                            </TableCell>
                        )}
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{staffTitle.available}</Typography>
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
            {/* </div> */}
        </div>
    )
} 

const mapStateToProps = (state) => {
    const { departmentAlias, identifierColumns } = state
    return { departmentAlias, identifierColumns }
};

export default withStyles(styles)(connect(mapStateToProps)(EventStaffingTable));