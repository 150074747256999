import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';

const styles = theme => ({
});

const WalkoutByDateChart = React.memo((props)=>{
    const { classes, reports, labels, colors, eventName } = props
    const walkoutNormal = reports.reduce((total, next) => total+next.normal_walkout_rate,0)/reports.length;
    const walkoutReduced = reports.reduce((total, next) => total+next.reduced_walkout_rate,0)/reports.length;
    const chartData = {
      labels: [eventName],
      datasets: [{
        label: labels[0],
        backgroundColor: colors[0],
        data: [Math.round((walkoutReduced + Number.EPSILON) * 100) / 100],
      },{
        label: labels[1],
        backgroundColor: colors[1],
        data: [Math.round((walkoutNormal + Number.EPSILON) * 100) / 100],
      }]
    }
    const options = {
        // responsive: false,
        title: {
          display: true,
          text: "Walkout Rate",
          fontSize: 14,
          fontColor: '#191F2D',
        //   fontStyle: 'normal',
        },
        legend: {
          display: true,
          position: 'bottom',
          labels:{boxWidth:20, fontSize:10 } 
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display:false
                }
            }],
            yAxes: [{
                ticks: {
                    min: 0,
                },
                gridLines: {
                    display:false
                }   
            }]
        },
        tooltips: {
            mode : 'label',
        },
    };
    return(
        <Bar
          width={380}
          height={300}
          data={chartData}
          options={options}
        />
    )

})

export default withStyles(styles)(WalkoutByDateChart);