import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios';
import StrikeEventsTable from './StrikeEventsTable';
import { handleAxiosError, getAPIUrl } from '../AppUtils';
import CreateStrikeEventButton from './CreateStrikeEventButton';

const styles = theme => ({
    page: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 300,
    }
});

function StrikeEventsPage(props) {
    const { classes, orgSettings } = props;
    const source = axios.CancelToken.source();
    const [events, setEvents] = useState(undefined);

    useEffect(() => {
        getEvents();
        return () => {source.cancel("unmount");}
    }, []);
    function getEvents(){
        axios.get(getAPIUrl()+'/strikeEvents',{cancelToken: source.token})
        .then(response => {
            setEvents(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    return (
        <div className={classes.page}>
            <CreateStrikeEventButton
                refreshEvents={getEvents}
            />
            <StrikeEventsTable
                events={events}
                history={props.history}
                refreshEvents={getEvents}
            />
        </div>
    );
}

export default withStyles(styles)(StrikeEventsPage);