import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogContent, DialogActions, Typography, DialogTitle, CircularProgress, Grid } from '@material-ui/core'
import axios from 'axios';
import { handleAxiosError, getAPIUrl } from '../AppUtils';
import { useFormInput } from '../CustomHooks';
import CustomizedTextField from '../CustomizedTextField';

const styles = theme => ({
})

function CreateEventButton(props){
    const { classes, refreshEvents } = props;
    const source = axios.CancelToken.source();
    const [open, setOpen] = useState(false);
    const [creating, setCreating] = useState(false);
    const eventName = useFormInput("");

    function handleClose(){
        setOpen(false);
    }
    function createEvent(){
        setCreating(true);
        axios.post(getAPIUrl()+'/createStrikeEvent',{name:eventName.value})
        .then(response => {
            setOpen(false);
            refreshEvents();
        })
        .catch(error=>{
            setOpen(false);
            handleAxiosError(error)
        })
    }
    function handleExited(){
        setCreating(false);
    }

    return (
        <div>
            <Button style={{marginBottom: 30}} variant="contained" color="secondary" onClick={()=>setOpen(true)}>Create Strike Event</Button>
            <Dialog 
                open={open}
                onClose={handleClose}
                fullWidth
                onExited={handleExited}
            >
                <DialogTitle>Create Strike Event</DialogTitle>
                <DialogContent>
                    <Grid container direction="row" alignItems="center">
                        <Typography style={{marginRight: 10}}>Event Name</Typography>
                        <CustomizedTextField
                            className={classes.textField}
                            variant="outlined"
                            {...eventName}
                            autoFocus
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {creating?
                        <CircularProgress size={30} color="secondary" style={{marginRight: 15}}/>:
                        <Button className={classes.button} onClick={createEvent} variant="contained" color="secondary">
                            Create
                        </Button>
                    }
                    <Button className={classes.button} onClick={()=>setOpen(false)} variant="contained" color="default">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default withStyles(styles)(CreateEventButton);