import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios';
import { handleAxiosError, getAPIUrl, getSocketUrl } from '../AppUtils';
import { Typography, Grid, Button, CircularProgress, Checkbox, FormGroup, FormControl, 
    FormControlLabel, ButtonGroup } from '@material-ui/core';
import TrendsBarChart from './TrendsBarChart';
import TrendsLineChart from './TrendsLineChart';
import SelectEventsDialog from '../SelectEventsDialog';

const styles = theme => ({
    page: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 300,
    },
    loading: {
        minHeight: '100vh',
    },
    spacing: {
        height: 50
    }
});

function TrendsPage(props) {
    const { classes } = props;
    const [trends, setTrends] = useState({});
    const [allEvents, setAllEvents] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState(null);
    const [selectEventDialogOpen, setSelectEventDialogOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [technologyStatus, setTechnologyStatus] = useState('technologyFunctional');
    const [resourceStatus, setResourceStatus] = useState('resourceOnHand');
    const [selectedTrainTypes, setSelectedTrainTypes] = useState({});
    const [selectedTrainCategories, setSelectedTrainCategories] = useState({});
    const [submissionType, setSubmissionType] = useState('submission_percent');
    const source = axios.CancelToken.source();
    
    // const colors = ["#3AC7FF","#009D00","#fdd835","#f57c00","#e00e3c","#512da8", "#6d4c41","#546e7a"]
    const colors = ["#0087BF","#006691","#054660","#223749","#91dfff","#66d3ff","#3ac7ff","#00b7ff","#4287f5"];
    useEffect(() => {
        getEvents();
        return () => {
            source.cancel("unmount");
        }
    }, []);
    useEffect(()=>{
        if(selectedEvents!=null){
            getTrends();
        }
    },[selectedEvents])
    function getEvents(){
        axios.get(getAPIUrl()+'/events',{cancelToken: source.token})
        .then(response => {
            setAllEvents(response.data);
            setSelectedEvents(response.data.slice(-8).reduce((obj,n)=>{
                obj[n.id] = true;
                return obj;
            },{}));
        })
        .catch(error=>{
            handleAxiosError(error)
        });
    }
    function getTrends(){
        setLoading(true);
        axios.post(getAPIUrl()+'/trends',Object.keys(selectedEvents).filter(n=>selectedEvents[n]),{cancelToken: source.token})
        .then(response => {
            if(response.data.trainCategories){
                setSelectedTrainCategories(response.data.trainCategories.reduce((obj,n)=>{
                    obj[n.id] = true;
                    return obj;
                },{}))
                setSelectedTrainTypes(response.data.trainTypes.reduce((obj,n)=>{
                    obj[n.id] = true;
                    return obj;
                },{}))
            }
            setTrends(response.data);
            setLoading(false);
        })
        .catch(error=>{
            handleAxiosError(error)
            setLoading(false);
        });
    }
    if(loading){
        return (
            <Grid className={classes.loading} container direction="column" justify='center' alignItems='center'>
                {/* <Typography variant="h6">Loading...</Typography> */}
                <div style={{height:20}}/>
                <CircularProgress/>
            </Grid>
        )
    }
    if(!trends){
        return(
            <Typography>Error loading page</Typography>
        )
    }
    if(trends.trainTypes){
        trends.events.forEach(event=>{
            let sum = 0;
            let typesArr = new Array(trends.trainTypes.length+1).fill(0);
            let categoriesArr = new Array(trends.trainCategories.length+1).fill(0);
            trends.trainTypes.forEach((type,typeIndex)=>{
                trends.trainCategories.forEach((category,categoryIndex)=>{
                    let num = event['train'][type.id][category.id]
                    // if(selectedTrainCategories[category.id] && selectedTrainTypes[type.id]){
                    //     sum += num;
                    // }
                    if(selectedTrainTypes[type.id]){
                        categoriesArr[categoryIndex] += num;
                        categoriesArr[categoriesArr.length-1] += num;
                    }
                    if(selectedTrainCategories[category.id]){
                        typesArr[typeIndex] += num;
                        typesArr[typesArr.length-1] += num;
                    }
                })
            })
            // event['train_sum'] = [sum];
            event['train_show_categories'] = categoriesArr.concat([sum]);
            event['train_show_types'] = typesArr.concat([sum]);
        })
    }
    return (
        <Grid className={classes.page} container direction="column" justify='center' alignItems='center'>
            <SelectEventsDialog
                open={selectEventDialogOpen}
                setOpen={setSelectEventDialogOpen}
                setSelectedEvents={setSelectedEvents}
                events={allEvents}
                selectedEvents={selectedEvents}
            />
            {/* <TrendsBarChart
                events = {trends.events}
                columns = {trends.staffingColumns}
                dataKey={"staffing"}
                title={"Staffing"}
                colors={colors}
            />
            <div className={classes.spacing}/>
            <TrendsBarChart
                events = {trends.events}
                columns = {trends.patientStatusColumns}
                dataKey="patientStatus"
                title="Patient Status"
                colors={colors}
            />
            <div className={classes.spacing}/>
            <TrendsBarChart
                events = {trends.events}
                columns = {trends.bedStatusColumns}
                dataKey="bedStatus"
                title="Bed Status"
                colors={colors}
            /> */}
            <Grid item container justify="flex-end">
                <Button className={classes.button} onClick={()=>setSelectEventDialogOpen(true)} variant="contained" color="secondary">
                    Select Events
                </Button>
            </Grid>
            <TrendsLineChart
                events = {trends.events}
                columns = {trends.staffingColumns}
                dataKey={"staffing"}
                title={"Staffing"}
                colors={colors}
            />
            <div className={classes.spacing}/>
            {"staffingDemandColumns" in trends 
                && 
                <TrendsLineChart
                    events = {trends.events}
                    columns = {trends.staffingDemandColumns}
                    dataKey={"staffingDemand"}
                    title={"Staffing Demand"}
                    colors={colors}
                />
            }
            
            <div className={classes.spacing}/>

            <TrendsLineChart
                events = {trends.events}
                columns = {trends.patientStatusColumns}
                dataKey={"patientStatus"}
                title={"Patient Status"}
                colors={colors}
            />
            <div className={classes.spacing}/>
            <TrendsLineChart
                events = {trends.events}
                columns = {trends.bedStatusColumns}
                dataKey={"bedStatus"}
                title={"Bed Status"}
                colors={colors}
            />
            <div className={classes.spacing}/>
            <TrendsLineChart
                events = {trends.events}
                columns = {trends.technologyColumns}
                dataKey={technologyStatus}
                title={"Technology Status"}
                colors={colors}
            />
            <ButtonGroup style={{marginTop:10}} color="primary" aria-label="technology button group">
                <Button variant={technologyStatus==='technologyFunctional'?"contained":undefined} onClick={()=>setTechnologyStatus('technologyFunctional')}>Functional</Button>
                <Button variant={technologyStatus==='technologyNonfunctional'?"contained":undefined} onClick={()=>setTechnologyStatus('technologyNonfunctional')}>Nonfunctional</Button>
            </ButtonGroup>
            <div className={classes.spacing}/>
            <TrendsLineChart
                events = {trends.events}
                columns = {trends.resourceColumns}
                dataKey={resourceStatus}
                title={"Resource Status"}
                colors={colors}
            />
            <ButtonGroup style={{marginTop:10}} color="primary" aria-label="resource button group">
                <Button variant={resourceStatus==='resourceOnHand'?"contained":undefined} onClick={()=>setResourceStatus('resourceOnHand')}>On Hand</Button>
                <Button variant={resourceStatus==='resourceAvailableForDeployment'?"contained":undefined} onClick={()=>setResourceStatus('resourceAvailableForDeployment')}>Available for Deployment</Button>
            </ButtonGroup>
            {trends.trainTypes?
            <React.Fragment>
                {/* <div className={classes.spacing}/>
                <FormGroup row>
                    {trends.trainCategories.map(n=>
                        <FormControlLabel    
                            key={n.id}                          
                            control={<Checkbox checked={Boolean(selectedTrainCategories[n.id])} 
                                onChange={(event)=>{setSelectedTrainCategories({...selectedTrainCategories,[n.id]:event.target.checked})}}/>}
                            label={n.name}
                        />  
                    )}
                </FormGroup>
                <TrendsLineChart
                    events = {events}
                    columns = {trends.trainTypes.map(n=>n.name).concat(['Total'])}
                    dataKey={'train_show_types'}
                    title={"TRAIN"}
                    colors={colors}
                /> */}
                <div className={classes.spacing}/>
                <TrendsLineChart
                    events = {trends.events}
                    columns = {trends.trainCategories.map(n=>n.name).concat(['Total'])}
                    dataKey={'train_show_categories'}
                    title={"TRAIN"}
                    colors={["#3AC7FF","#009D00","#fdd835","#f57c00","#e00e3c","#223749"]}
                />
                <FormGroup style={{marginTop:10}} row>
                    {trends.trainTypes.map(n=>
                        <FormControlLabel    
                            key={n.id}                          
                            control={<Checkbox color="primary" checked={Boolean(selectedTrainTypes[n.id])} 
                                onChange={(event)=>{setSelectedTrainTypes({...selectedTrainTypes,[n.id]:event.target.checked})}}/>}
                            label={n.name}
                            labelPlacement="start"
                        />  
                    )}
                </FormGroup>
            </React.Fragment>:undefined}
            <div className={classes.spacing}/>
            <TrendsLineChart
                events = {trends.events}
                columns = {["Submission Status"]}
                dataKey={submissionType}
                title={"Submission Status"}
                colors={colors}
            />
            <ButtonGroup style={{marginTop:10}} color="primary" aria-label="submission button group">
                <Button variant={submissionType==='submission_percent'?"contained":undefined} onClick={()=>setSubmissionType('submission_percent')}>Percent</Button>
                <Button variant={submissionType==='submission_number'?"contained":undefined} onClick={()=>setSubmissionType('submission_number')}>Number</Button>
            </ButtonGroup>
        </Grid>
    );
}

export default withStyles(styles)(TrendsPage);