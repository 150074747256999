import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';

const styles = theme => ({
});

const AncillaryStaffChart = React.memo((props)=>{
    const { classes, reports, eventName } = props
    const labels = ["Normal Schedule Staffing", "Reduced Schedule Staffing", "Actual Staff Who Showed", "Replacement Used Staff"]
    const colors = ["#223749", "#006691", "#3AC7FF", "#91DFFF"]
    const normal = reports.reduce((total, next) => total+next.normal_staffing,0);
    const reduced = reports.reduce((total, next) => total+next.reduced_staffing,0);
    const actual = reports.reduce((total, next) => total+next.actual_staffing,0);
    const replacement = reports.reduce((total, next) => total+next.used_replacement_staffing,0);
    const chartData = {
      labels: [eventName],
      datasets: [{
        label: labels[0],
        backgroundColor: colors[0],
        data: [normal],
      },{
        label: labels[1],
        backgroundColor: colors[1],
        data: [reduced],
      },{
        label: labels[2],
        backgroundColor: colors[2],
        data: [actual],
      },{
        label: labels[3],
        backgroundColor: colors[3],
        data: [replacement],
      },]
    }
    const options = {
        // responsive: false,
        title: {
          display: true,
          text: "Ancillary Staff Report",
          fontSize: 14,
          fontColor: '#191F2D',
        //   fontStyle: 'normal',
        },
        legend: {
          display: true,
          position: 'bottom',
          labels:{boxWidth:20, fontSize:10 } 
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display:false
                }
            }],
            yAxes: [{
                ticks: {
                    min: 0,
                },
                gridLines: {
                    display:false
                }   
            }]
        },
        tooltips: {
            mode : 'label',
        },
    };
    return(
        <Bar
          width={500}
          height={200}
          data={chartData}
          options={options}
        />
    )

})

export default withStyles(styles)(AncillaryStaffChart);