import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogContent, DialogActions, Typography, DialogTitle, RadioGroup, Radio,
    FormControl, Tooltip, FormControlLabel, Checkbox, Grid, CircularProgress, FormGroup, Select, MenuItem } from '@material-ui/core'
import axios from 'axios';
import { handleAxiosError, getAPIUrl } from './AppUtils';
import { useFormInput, useCheckbox } from './CustomHooks';
import CustomizedTextField from './CustomizedTextField';
import { format, parseISO, isValid, setHours, setMinutes } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DateTimePicker,
} from '@material-ui/pickers';
import InfoIcon from '@material-ui/icons/Info';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: 200,
    },
})

function EditScheduledEventDialog(props){
    const { classes, refreshEvents, open, event, edit, createEventRoute, editScheduledEventRoute, createScheduledEventRoute, title, editTitle } = props;
    const source = axios.CancelToken.source();
    const [saving, setSaving] = useState(false);
    const eventName = useFormInput("");
    const frequency = useFormInput("none");
    const daysOfWeek = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
    const fullDaysOfWeek = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const numberLabels = ["First","Second","Third","Fourth","Fifth"];
    const [selectedDays, setSelectedDays] = useState({});
    // const openTime = useFormInput(format(new Date(),"yyyy-MM-dd")+"T07:30");
    const [openTime, setOpenTime] = useState(setMinutes(setHours(new Date(),7),30));
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate()+1)
    // const closeTime = useFormInput(format(tomorrow,"yyyy-MM-dd")+"T07:30");
    const [closeTime, setCloseTime] = useState(setMinutes(setHours(tomorrow,7),30));
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const closeAfterTime = useFormInput(24);
    const closeAfterType = useFormInput('nextevent');
    const closeAfterTimeInterval = useFormInput('hours');
    const active = useCheckbox(false);
    const recurringEvent = useCheckbox(false);
    const recurMonthlyDate = useFormInput("date");

    var monthlyWeekdayLabel = "";
    var monthlyDateLabel = "";
    if(openTime){
        // const openDate = parseISO(openTime);
        const dayOfMonth = openTime.getDate();
        monthlyWeekdayLabel = numberLabels[Math.floor(dayOfMonth/7)] + " " +fullDaysOfWeek[openTime.getDay()] + " Every Month";
        monthlyDateLabel = "Day " +dayOfMonth + " of Every Month";
    }

    useEffect(()=>{
        return () => {source.cancel("unmount");}
    },[]);

    useEffect(()=>{
        if(event){
            eventName.onChange({target:{value:event.name}});
            var freq = "none"
            if(event.recurrenceString=="Daily"){
                freq = "daily"
            }
            else if(event.recurrenceString=="Weekdays"){
                freq = "weekdays"
            }
            else if(event.recurrenceString=="Weekly"){
                freq = "weekly"
            }
            else if(event.recurrence_type.startsWith('monthly')){
                freq = "monthly"
                recurMonthlyDate.onChange({target:{value:event.recurrence_type.split(':')[1]}})
            }
            if(event.close_after_hours > 0){
                closeAfterTime.onChange({target:{value:event.close_after_hours}})
                closeAfterTimeInterval.onChange({target:{value:'hours'}})
            }
            if(event.close_after_days > 0){
                closeAfterTime.onChange({target:{value:event.close_after_days}})
                closeAfterTimeInterval.onChange({target:{value:'days'}})
            }
            if(event.close_after_weeks > 0){
                closeAfterTime.onChange({target:{value:event.close_after_weeks}})
                closeAfterTimeInterval.onChange({target:{value:'weeks'}})
            }
            closeAfterType.onChange({target:{value:event.close_type}})
            frequency.onChange({target:{value:freq}});
            setSelectedDays(event.weeklyDays);
            setOpenTime(parseISO(event.openTime));
            setCloseTime(parseISO(event.closeTime));
            setEndDate(event.endDate?parseISO(event.endDate):null);
            setStartDate(event.startDate?parseISO(event.startDate):null);
            active.onChange({target:{checked:Boolean(event.active)}})
        }
    },[event])
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    function handleClose(){
        props.handleClose();
        eventName.onChange({target:{value:""}});
        recurringEvent.onChange({target:{checked:false}});
        frequency.onChange({target:{value:"none"}});
        setSelectedDays({});
        setOpenTime(setMinutes(setHours(new Date(),7),30));
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate()+1)
        setCloseTime(setMinutes(setHours(tomorrow,7),30));
        setEndDate(null);
        setStartDate(new Date());
        setErrorMessage("");
    }
    function saveEvent(){
        setErrorMessage("");
        if(!edit && !recurringEvent.checked){
            setSaving(true);
            axios.post(getAPIUrl()+createEventRoute,{name:eventName.value})
            .then(response => {
                refreshEvents();
                handleClose();
            })
            .catch(error=>{
                handleAxiosError(error);
                setSaving(false);
                setErrorMessage("There was an error creating this event.")
            })
        }
        else{
            if(frequency.value=="weekly"&&daysOfWeek.every(n=>!selectedDays[n])){
                setErrorMessage("No days selected.");
            }
            else if(endDate == "Invalid Date"){
                setErrorMessage("Invalid Recur Until Date.");
            }
            // else if(startDate == "Invalid Date" || !startDate){
            //     setErrorMessage("Invalid Start Date.");
            // }
            else if(!openTime || !isValid(openTime)){
                setErrorMessage('Invalid Open Time.')
            }
            else if(!closeTime || !isValid(closeTime)){
                setErrorMessage('Invalid Close Time.')
            }
            else if(openTime >= closeTime){
                setErrorMessage('Close Time must be after Open Time.')
            }
            else{
                var recurrenceType="none:";
                if(frequency.value=="daily"){
                    recurrenceType = "weekly:"+ daysOfWeek.join(",");
                }
                else if(frequency.value=="weekdays"){
                    recurrenceType = "weekly:"+ ["Mon,Tue,Wed,Thu,Fri"];
                }
                else if(frequency.value=="weekly"){
                    var scheduledDays = Object.assign(selectedDays,{});
                    recurrenceType += "weekly:"+Object.keys(scheduledDays).reduce((arr,key)=>{return scheduledDays[key]?arr.concat([key]):arr},[]).join(",");
                }
                else if(frequency.value=="monthly"){
                    const dayOfMonth = openTime.getDate();
                    recurrenceType = "monthly:"+recurMonthlyDate.value+":"
                    if(recurMonthlyDate.value==="date"){
                        recurrenceType += dayOfMonth
                    }
                    else if(recurMonthlyDate.value==="weekday"){
                        recurrenceType += daysOfWeek[openTime.getDay()]+","+Math.floor(dayOfMonth/7);
                    }
                }
                const data = {id:event?event.id:null,name:eventName.value,recurrenceType,openTime:format(openTime,"yyyy-MM-dd'T'HH:mm"),closeTime:format(closeTime,"yyyy-MM-dd'T'HH:mm"),timezone,
                    endDate:endDate?format(endDate,"yyyy-MM-dd"):null,active:active.checked};
                if(edit){
                    setSaving(true);
                    axios.post(getAPIUrl()+editScheduledEventRoute,data)
                    .then(response => {
                        refreshEvents();
                        handleClose();
                    })
                    .catch(error=>{
                        handleAxiosError(error);
                        setSaving(false);
                        setErrorMessage("There was an error saving this event.")
                    })
                }
                else{
                    setSaving(true);
                    axios.post(getAPIUrl()+createScheduledEventRoute, data)
                    .then(response => {
                        refreshEvents();
                        handleClose();
                    })
                    .catch(error=>{
                        handleAxiosError(error);
                        setSaving(false);
                        setErrorMessage("There was an error creating this event.")
                    })
                }
            }
        }
    }
    function handleExited(){
        setSaving(false);
        closeAfterTime.onChange({target:{value:24}})
        closeAfterTimeInterval.onChange({target:{value:'hours'}})
        setErrorMessage("");
    }
    const dialogTitle = title ? title:"Create StatRep Event";
    const editDialogTitle = editTitle? editTitle:"Edit Scheduled StatRep Event";
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Dialog 
                open={open}
                onClose={handleClose}
                onExited={handleExited}
                maxWidth="md"
            >
                <DialogTitle>{edit?editDialogTitle:dialogTitle}</DialogTitle>
                <DialogContent>
                    {edit?<Grid container direction="row" alignItems="center">
                        <FormControlLabel                       
                            control={<Checkbox color="secondary" {...active}/>}
                            label="Active"
                        />
                        {/* <Tooltip style={{marginLeft: 5, paddingTop: 0, marginTop: -8}} title={"Inactive events will not create new events even if the the date is within start and end dates"}>
                            <InfoIcon color="disabled"/>
                        </Tooltip> */}
                    </Grid>:undefined}
                    <Grid container direction="row" alignItems="center">
                        <Typography style={{marginRight: 10}}>Event Name</Typography>
                        <CustomizedTextField
                            className={classes.textField}
                            variant="outlined"
                            {...eventName}
                            autoFocus
                        />
                        {edit?<Tooltip style={{marginLeft: 10}} title={"Events will be named with format: "+eventName.value+format(new Date(),"_yyyy_MM_dd")}>
                            <InfoIcon color="disabled"/>
                        </Tooltip>:undefined}
                    </Grid>
                    
                    {edit?undefined:<Grid container direction="row" alignItems="center">
                        <Checkbox style={{marginLeft: 20}} {...recurringEvent}/>
                        <Typography>Recurring/Scheduled Event</Typography>
                        <Tooltip style={{marginLeft: 10}} title={"An event will be created at the specified time on each of the specified days. Events will be named with format: "+(eventName.value?eventName.value:"event_name")+format(new Date(),"_yyyy_MM_dd")}>
                            <InfoIcon color="disabled"/>
                        </Tooltip>
                    </Grid>}
                    {edit||recurringEvent.checked?
                    <Grid container direction="column">
                        <Grid style={{marginTop: 10}} container direction="row" alignItems="center">
                            <Typography style={{marginRight: 10}}>Open Time</Typography>
                            <form className={classes.container} noValidate>
                                <DateTimePicker
                                    value={openTime}
                                    onChange={(value)=>setOpenTime(value)}
                                    onError={console.log}
                                    // minDate={new Date("2018-01-01T00:00")}
                                    format="yyyy/MM/dd hh:mm a"
                                />
                                {/* <CustomizedTextField
                                    type="datetime-local"
                                    variant="outlined"
                                    {...openTime}
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                /> */}
                            </form>
                        </Grid>
                        <Grid style={{marginTop: 10}} container direction="row" alignItems="center">
                            <Typography style={{marginRight: 10}}>Close Time</Typography>
                            <form className={classes.container} noValidate>
                                <DateTimePicker
                                    value={closeTime}
                                    onChange={(value)=>setCloseTime(value)}
                                    onError={console.log}
                                    // minDate={new Date("2018-01-01T00:00")}
                                    format="yyyy/MM/dd hh:mm a"
                                />
                                {/* <CustomizedTextField
                                    type="datetime-local"
                                    variant="outlined"
                                    {...closeTime}
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                /> */}
                            </form>
                        </Grid>
                        {/* <Typography variant="subtitle1">Detected Timezone: {timezone}</Typography> */}
                        {/* <Grid style={{marginTop: 10}} container direction="row" alignItems="center">
                            <Typography style={{marginRight: 10}}>Close After</Typography>
                            <Select {...closeAfterType}>
                                <MenuItem value={"nextevent"}>Next Event is Opened</MenuItem>
                                <MenuItem value={"time"}>Amount of Time</MenuItem>
                                <MenuItem value={"never"}>Never</MenuItem>
                            </Select>
                        </Grid>
                        {closeAfterType.value=='time'?
                            <Grid style={{marginTop: 5}} container direction="row" alignItems="center">
                                <CustomizedTextField
                                    variant="outlined"
                                    type="number"
                                    {...closeAfterTime}
                                />
                                <Select style={{marginLeft: 10}} {...closeAfterTimeInterval}>
                                    <MenuItem value={"hours"}>Hours</MenuItem>
                                    <MenuItem value={"days"}>Days</MenuItem>
                                    <MenuItem value={"weeks"}>Weeks</MenuItem>
                                </Select>
                            </Grid>:undefined
                        } */}
                        {/* <Grid style={{marginTop: 10}} container direction="row" alignItems="center">
                                <Grid item xs={3}>
                                    <Typography style={{marginRight: 10}}>Start Date</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="start-date-picker-inline"
                                        inputVariant="outlined"
                                        // label="Strike Date"
                                        value={startDate}
                                        onChange={(value)=>setStartDate(value)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                        </Grid> */}
                        <FormControl style={{marginTop:20}} component="fieldset">
                            <Typography>Recur</Typography>
                        <RadioGroup aria-label="frequency" name="frequency" {...frequency} row={true}>
                            <FormControlLabel value="none" control={<Radio />} label="No" />
                            <FormControlLabel value="daily" control={<Radio />} label="Daily" />
                            <FormControlLabel value="weekdays" control={<Radio />} label="Weekdays" />
                            <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
                            <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                        </RadioGroup>
                        </FormControl>
                        {frequency.value=="weekly"?
                            <FormGroup row>
                                {daysOfWeek.map(n=>
                                    <FormControlLabel    
                                        key={n}                          
                                        control={<Checkbox color="secondary" checked={Boolean(selectedDays[n])} 
                                            onChange={(event)=>{setSelectedDays({...selectedDays,[n]:event.target.checked})}}/>}
                                        label={n}
                                        // labelPlacement="start"
                                    />  
                                )}
                            </FormGroup>:undefined}
                        {frequency.value=="monthly"?
                            openTime?<FormControl component="fieldset">
                                <RadioGroup aria-label="monthlyrecur" name="monthlyrecur" {...recurMonthlyDate} row={true}>
                                    <FormControlLabel value={"date"} control={<Radio />} label={monthlyDateLabel} />
                                    <FormControlLabel value={"weekday"} control={<Radio />} label={monthlyWeekdayLabel} />
                                </RadioGroup>
                            </FormControl>:
                            <Typography style={{color:"red"}}>Please select valid open time.</Typography>
                        :undefined}
                        {frequency.value!=="none"?
                        <Grid style={{marginTop: 0}} container direction="row" alignItems="center">
                                <Grid item xs={2}>
                                    <Typography style={{marginRight: 10}}>Until</Typography>
                                </Grid>
                                <Grid item xs={8} container direction="row" alignItems="center">
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="end-date-picker-inline"
                                        inputVariant="outlined"
                                        // label="Strike Date"
                                        value={endDate}
                                        onChange={(value)=>setEndDate(value)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip style={{marginLeft: 10}} title={"If this field is left blank events will be created indefinitely."}>
                                        <InfoIcon color="disabled"/>
                                    </Tooltip>
                                </Grid>
                        </Grid>:undefined}
                    </Grid>:undefined}
                </DialogContent>
                <DialogActions>
                    {errorMessage?<Typography style={{color:"red"}}>{errorMessage}</Typography>:undefined}
                    {saving?
                        <CircularProgress size={30} color="secondary" style={{marginRight: 15}}/>:
                        <Button className={classes.button} onClick={saveEvent} variant="contained" color="secondary">
                            {edit?"Save":"Create"}
                        </Button>
                    }
                    <Button className={classes.button} onClick={handleClose} variant="contained" color="default">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </MuiPickersUtilsProvider>
    )
}

export default withStyles(styles)(EditScheduledEventDialog);