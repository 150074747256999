import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogContent, DialogActions, Typography, DialogTitle, RadioGroup, Radio,
    FormControl, Tooltip, FormControlLabel, Checkbox, Grid, CircularProgress, FormGroup, Select, MenuItem } from '@material-ui/core'
import axios from 'axios';
import { handleAxiosError, getAPIUrl } from './AppUtils';
import { useFormInput, useCheckbox } from './CustomHooks';
import CustomizedTextField from './CustomizedTextField';
import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import InfoIcon from '@material-ui/icons/Info';
import EditScheduledEventDialog from './EditScheduledEventDialog';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: 200,
    },
})

function CreateEventButton(props){
    const { classes, refreshEvents } = props;
    // const source = axios.CancelToken.source();
    const [open, setOpen] = useState(false);
    // const eventName = useFormInput("");
    // const recurringEvent = useCheckbox(false);
    // const frequency = useFormInput("daily");
    // const daysOfWeek = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"];
    // const [selectedDays, setSelectedDays] = useState({});
    // const openTime = useFormInput("07:30");
    // const closeAfterTime = useFormInput(24);
    // const closeAfterType = useFormInput('nextevent');
    // const closeAfterTimeInterval = useFormInput('hours');
    // const [endDate, setEndDate] = useState(null);
    // const [startDate, setStartDate] = useState(new Date());
    // const [errorMessage, setErrorMessage] = useState("");

    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div>
            <Button variant="contained" color="secondary" onClick={()=>setOpen(true)}>Create StatRep Event</Button>
            <EditScheduledEventDialog
                open={open}
                handleClose={()=>setOpen(false)}
                refreshEvents={refreshEvents}
                createEventRoute='/createEvent'
                editScheduledEventRoute='/editScheduledEvent'
                createScheduledEventRoute='/createScheduledEvent'
            />
            {/* <Dialog 
                open={open}
                onClose={handleClose}
                onExited={handleExited}
                maxWidth="md"
            >
                <DialogTitle>Create StatRep Event</DialogTitle>
                <DialogContent>
                    <Grid container direction="row" alignItems="center">
                        <Typography style={{marginRight: 10}}>Event Name</Typography>
                        <CustomizedTextField
                            className={classes.textField}
                            variant="outlined"
                            {...eventName}
                            autoFocus
                        />
                    </Grid>
                    {recurringEvent.checked?
                        <Grid container direction="column">
                            <FormControl component="fieldset">
                            <RadioGroup aria-label="frequency" name="frequency" {...frequency} row={true}>
                                <FormControlLabel value="daily" control={<Radio />} label="Daily" />
                                <FormControlLabel value="weekdays" control={<Radio />} label="Weekdays" />
                                <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
                            </RadioGroup>
                            </FormControl>
                            {frequency.value=="weekly"?
                                <FormGroup row>
                                    {daysOfWeek.map(n=>
                                        <FormControlLabel    
                                            key={n}                          
                                            control={<Checkbox color="secondary" checked={Boolean(selectedDays[n])} 
                                                onChange={(event)=>{setSelectedDays({...selectedDays,[n]:event.target.checked})}}/>}
                                            label={n}
                                            // labelPlacement="start"
                                        />  
                                    )}
                                </FormGroup>:undefined}
                            <Grid style={{marginTop: 10}} container direction="row" alignItems="center">
                                <Typography style={{marginRight: 10}}>Open Time</Typography>
                                <form className={classes.container} noValidate>
                                    <CustomizedTextField
                                        id="time"
                                        type="time"
                                        variant="outlined"
                                        {...openTime}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                    />
                                </form>
                            </Grid>
                            <Typography variant="subtitle1">Detected Timezone: {timezone}</Typography>
                            <Grid style={{marginTop: 10}} container direction="row" alignItems="center">
                                <Typography style={{marginRight: 10}}>Close After</Typography>
                                <Select {...closeAfterType}>
                                    <MenuItem value={"nextevent"}>Next Event is Opened</MenuItem>
                                    <MenuItem value={"time"}>Amount of Time</MenuItem>
                                    <MenuItem value={"never"}>Never</MenuItem>
                                </Select>
                            </Grid>
                            {closeAfterType.value=='time'?
                                <Grid style={{marginTop: 5}} container direction="row" alignItems="center">
                                    <CustomizedTextField
                                        variant="outlined"
                                        type="number"
                                        {...closeAfterTime}
                                    />
                                    <Select style={{marginLeft: 10}} {...closeAfterTimeInterval}>
                                        <MenuItem value={"hours"}>Hours</MenuItem>
                                        <MenuItem value={"days"}>Days</MenuItem>
                                        <MenuItem value={"weeks"}>Weeks</MenuItem>
                                    </Select>
                                </Grid>:undefined
                            }
                            <Grid style={{marginTop: 10}} container direction="row" alignItems="center">
                                <Grid item xs={3}>
                                    <Typography style={{marginRight: 10}}>Start Date</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="start-date-picker-inline"
                                        inputVariant="outlined"
                                        // label="Strike Date"
                                        minDate={new Date()}
                                        value={startDate}
                                        onChange={(value)=>setStartDate(value)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid style={{marginTop: 10}} container direction="row" alignItems="center">
                                <Grid item xs={3}>
                                    <Typography style={{marginRight: 10}}>End Date</Typography>
                                </Grid>
                                <Grid item xs={8} container direction="row" alignItems="center">
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="end-date-picker-inline"
                                        inputVariant="outlined"
                                        // label="Strike Date"
                                        minDate={new Date()}
                                        value={endDate}
                                        onChange={(value)=>setEndDate(value)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip style={{marginLeft: 10}} title={"The last event will be created on this date if it matches other requirements (eg. weekday).\n If this field is left blank events will be created indefinitely."}>
                                        <InfoIcon color="disabled"/>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    :undefined}
                </DialogContent>
                <DialogActions>
                    {errorMessage?<Typography style={{color:"red"}}>{errorMessage}</Typography>:undefined}
                    {creating?
                        <CircularProgress size={30} color="secondary" style={{marginRight: 15}}/>:
                        <Button className={classes.button} onClick={createEvent} variant="contained" color="secondary">
                            Create
                        </Button>
                    }
                    <Button className={classes.button} onClick={()=>setOpen(false)} variant="contained" color="default">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog> */}
        </div>
        </MuiPickersUtilsProvider>
    )
}

export default withStyles(styles)(CreateEventButton);