import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Typography, Grid } from '@material-ui/core'

const styles = theme => ({
    tableHeaderCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 40,
    },
    tableCellRow: {
        height: 40,
    },
    tableCell: {
        width: 50,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    input: {
        width: 50,
        textAlign: "center",
        paddingTop: 2,
        paddingBottom: 2,
        color: "#555555",
        fontSize: 14,
    },
    specializedNeedsInput: {
        width: '90%',
        paddingTop: 2,
        paddingBottom: 2,
        color: "#555555",
        fontSize: 14,
    },
    notApplicableCell: {
        width: 130,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        padding: 0,
    },
})

const CreateTRAINTable = React.memo((props)=>{
    const { classes, TRAINTypes, TRAINCategories, TRAINData, setTRAINData, trainNotApplicable } = props
    function handleDataChange(type,category){
        return function(event){
            let newVal = parseInt(event.target.value)
            if(isNaN(newVal)){
               newVal = 0;
            }
            setTRAINData({
                ...TRAINData, 
                [type]:{
                    ...TRAINData[type],
                    [category]:newVal,
                } 
            });
        };
    }
    function handleSpecializedNeedsChange(type,category){
        return function(event){
            let newVal = event.target.value
            setTRAINData({
                ...TRAINData, 
                [type]:{
                    ...TRAINData[type],
                    specializedNeeds: newVal,
                } 
            });
        };
    }
    function selectOnFocus(event) {
        event.target.select();
    }
    if(!TRAINData){
        return null;
    }
    const colors = ["rgba(58, 199, 255, 0.7)","rgba(0, 157, 0, 0.7)","rgba(253, 216, 53, 0.7)","rgba(245, 124, 0, 0.7)","rgba(224, 14, 60, 0.7)"]
    const colors2 = ["rgba(58, 199, 255, 0.1)","rgba(0, 157, 0, 0.1)","rgba(253, 216, 53, 0.1)","rgba(245, 124, 0, 0.1)","rgba(224, 14, 60, 0.1)"]
    return (
        <Table>
            <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                    <TableCell className={classes.notApplicableCell} align="center">
                        <Grid container justify='center' alignItems='center'>
                            <input type="checkbox" {...trainNotApplicable}/>
                            &nbsp;Not Applicable
                        </Grid>
                    </TableCell>
                    {TRAINCategories.map((category,i)=>
                        <TableCell key={category.id} className={classes.tableHeaderCell} 
                            align="center"
                            style={{backgroundColor: colors[i]}}
                        >
                            {category.name}
                        </TableCell>
                    )}
                    <TableCell className={classes.tableHeaderCell} 
                        align="center"
                    >
                        Specialized Needs (ISOLATION, etc.)
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {TRAINTypes.map(type=>
                    <TableRow key={type.id} className={classes.tableCellRow}>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{type.name}</Typography>
                        </TableCell>
                        {TRAINCategories.map((category,i)=>
                            <TableCell key={category.id}className={classes.tableCell} align="center"
                                style={{backgroundColor: colors[i]}}
                            >
                                {trainNotApplicable.checked?
                                    <Typography></Typography>:
                                    <input className={classes.input}
                                        type="number"
                                        min={0}
                                        value={TRAINData[type.id][category.id]}
                                        onChange={handleDataChange(type.id,category.id)}
                                        onFocus={selectOnFocus}
                                        style={{backgroundColor: colors2[i]}}
                                    />
                                }
                            </TableCell>
                        )}
                        <TableCell className={classes.tableCell} align="center">
                            {trainNotApplicable.checked?
                                <Typography></Typography>:
                                <input className={classes.specializedNeedsInput} type="text" 
                                    value={TRAINData[type.id].specializedNeeds}
                                    onChange={handleSpecializedNeedsChange(type.id)}
                                />
                            }
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    )
}) 

export default withStyles(styles)(CreateTRAINTable);