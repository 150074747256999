import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Typography, Link,
        TableSortLabel, Grid, IconButton } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, parseISO } from 'date-fns';
import {SortableLabel, stableSort, getSorting } from '../TableUtils'
import { connect } from 'react-redux';

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 120,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableWrapper: {
        maxHeight: 450,
        overflow: 'auto',
    },
    tableHeaderRow: {
        height: 40,
    },
    tableRow: {
        height: 40,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    searchGrid: {
        marginBottom: 20,
    },
    totalText: {
        marginLeft: 15,
    },
    tableBody:{
        // display:'block',
        overflowY: 'scroll',
        maxHeight: 300,
        width: '100%',
    },
})

const QuickStatRepSummaryTable = React.memo((props)=>{
    const { classes, reports, fields, statuses } = props
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('submit_time');
    const [editReport, setEditReport] = useState(undefined);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const handleRequestSort = property => event => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    const HeaderCell = (props) => {
        return (
        <TableCell className={classes.tableHeaderCell} align="center" style={props.style}>
            <SortableLabel
                order={order}
                active={orderBy===props.property}
                handleSort={handleRequestSort(props.property)}
                label={props.label}
            />
        </TableCell>
    )}
    function handleClose(){
        setEditDialogOpen(false);
        setEditReport(undefined);
    }
    function downloadCSV(){
        const fieldsData = fields.map(field=>
            reports.reduce((obj,report)=>{
                statuses.forEach(status=>
                    obj[status.id] += (report['field_'+field.id] && report['field_'+field.id].status == status.id) ? 1:0
                )
                return obj;
            },statuses.reduce((obj,n)=>{obj[n.id]=0;return obj},field))
        )
        const rows = [[""].concat(statuses.map(n=>'"'+n.name+'"'))].concat(
            stableSort(fieldsData, getSorting(order, orderBy)).map(field=>{
                return (
                    ['"'+field.name+'"'].concat(statuses.map(status=>field[status.id]))
                )
            })
        );
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "intelhs_quick_statrep_summary.csv");
        document.body.appendChild(link);
        link.click();
    }
    const fieldsData = fields.map(field=>
        reports.reduce((obj,report)=>{
            statuses.forEach(status=>
                obj[status.id] += (report['field_'+field.id] && report['field_'+field.id].status == status.id) ? 1:0
            )
            return obj;
        },statuses.reduce((obj,n)=>{obj[n.id]=0;return obj},field))
    )
    return (
        <div>
            <Grid container justify='space-between'>
                <Typography style={{fontSize:20,fontWeight:"bold"}} display="inline">Summary Table</Typography>
                <div>
                    <IconButton className={classes.iconButton}
                        onClick={()=>downloadCSV()}
                    >
                        <FontAwesomeIcon size="xs" icon={["fa","file-download"]}/>
                    </IconButton>
                </div>
            </Grid>
            <div className={classes.tableWrapper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow className={classes.tableHeaderRow}>
                            {/* <TableCell className={classes.tableHeaderCell} align="center">
                                <SortableLabel
                                    order={order}
                                    active={orderBy==="name"}
                                    handleSort={handleRequestSort("name")}
                                    label={departmentAlias}
                                />
                            </TableCell> */}

                            {/* <HeaderCell property={''} label={"Director"}/> */}
                            <TableCell className={classes.tableHeaderCell}/>
                            {statuses.map(n=>
                                <HeaderCell key={n.id} property={n.id} label={n.name} style={{backgroundColor: n.color, color:'white'}}/>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(fieldsData, getSorting(order, orderBy))
                        .map(field=>
                        <TableRow className={classes.tableRow} key={field.id}>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>
                                    {field.name}
                                </Typography>
                            </TableCell>
                            {statuses.map(status=>
                                <TableCell key={status.id} className={classes.tableCell} align="center">
                                    <Typography>
                                        {field[status.id]}
                                    </Typography>
                                </TableCell>
                            )}
                        </TableRow>)}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}) 

export default withStyles(styles)(QuickStatRepSummaryTable);