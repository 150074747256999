import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Typography, FormControl, InputLabel, Select, Grid, Table, TableBody, TableRow, TableCell, Tooltip } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CustomizedTextField from '../CustomizedTextField'
import { useCheckbox, useFormInput } from '../CustomHooks';
import InfoIcon from '@material-ui/icons/Info'

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 150,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 35,
    },
    tableCellRow: {
        height: 40,
    },
    tableCell: {
        // width: 50,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    input: {
        // width: 50,
        textAlign: "center",
        paddingTop: 2,
        paddingBottom: 2,
        color: "#555555",
        fontSize: 14,
    },
    formControl: {
        minWidth: 130,
    },
    spacing: {
        height: 25,
    },
    select: {
        color: "#555555",
        fontSize: 14,
    },
})

const StrikeInput = React.memo((props)=>{
    const { classes, selectedUnion, selectedShift, normalStaffing, reducedStaffing, safeStaffing, actualStaffing,
        usedReplacementStaffing, requestReplacementFailed, strikePlan, shifts, unions, selectedStrikeEvent, strikeEvents, strikeEvent } = props

    function selectOnFocus(event) {
        event.target.select();
    }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
                <Table>
                    <colgroup>
                        <col width="33%"/>
                        <col width="33%"/>
                        <col width="33%"/>
                    </colgroup>
                    <TableBody>
                        <TableRow className={classes.tableHeaderRow}>
                            <TableCell className={classes.tableHeaderCell} align="center">
                                Strike Event
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell} align="center">
                                Union
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell} align="center">
                                Shift
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableCellRow}>
                            <TableCell className={classes.tableCell} align="center">
                                {strikeEvent?
                                    <Typography>{strikeEvent.name}</Typography>:
                                    <FormControl className={classes.formControl}>
                                        <select
                                            className={classes.select}
                                            {...selectedStrikeEvent}
                                        >
                                            <option value="" />
                                            {strikeEvents.map(n=>
                                                <option key={n.id} value={n.id}>{n.name}</option>
                                            )}
                                        </select>
                                    </FormControl>
                                }
                                {/* <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                // label="Strike Date"
                                {...strikeDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                /> */}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <FormControl className={classes.formControl}>
                                    <select
                                        className={classes.select}
                                        {...selectedUnion}
                                    >
                                        <option value="" />
                                        {unions.map(n=>
                                            <option key={n.id} value={n.id}>{n.name}</option>
                                        )}
                                    </select>
                                </FormControl>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <FormControl className={classes.formControl}>
                                    <select
                                        className={classes.select}
                                        {...selectedShift}
                                    >
                                        <option value="" />
                                        {shifts.map(n=>
                                            <option key={n.id} value={n.id}>{n.name}</option>
                                        )}
                                    </select>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableHeaderRow}>
                            <TableCell className={classes.tableHeaderCell} align="center">
                                Normal Schedule Staffing
                                <Tooltip title="# of staff scheduled for normal operations">
                                    <InfoIcon color="disabled" style={{marginLeft:10}}/>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell} align="center">
                                Reduced Schedule Staffing
                                <Tooltip title="# of staff scheduled to work during the strike">
                                    <InfoIcon color="disabled" style={{marginLeft:10}}/>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell} align="center">
                                Safe Minimum Staffing
                                <Tooltip title="# of staff needed to maintain regulatory/patient safety levels">
                                    <InfoIcon color="disabled" style={{marginLeft:10}}/>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableCellRow}>
                            <TableCell className={classes.tableCell} align="center">
                                <input className={classes.input}
                                    type="number"
                                    min={0}
                                    {...normalStaffing}
                                    onFocus={selectOnFocus}
                                />
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <input className={classes.input}
                                    type="number"
                                    min={0}
                                    {...reducedStaffing}
                                    onFocus={selectOnFocus}
                                />
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <input className={classes.input}
                                    type="number"
                                    min={0}
                                    {...safeStaffing}
                                    onFocus={selectOnFocus}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableHeaderRow}>
                            <TableCell className={classes.tableHeaderCell} align="center">
                                Actual Staff Who Showed 
                                <Tooltip title="# of staff that showed up day of strike">
                                    <InfoIcon color="disabled" style={{marginLeft:10}}/>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell} align="center">
                                Replacement Staff Used
                                <Tooltip title="# of replacement staff used">
                                    <InfoIcon color="disabled" style={{marginLeft:10}}/>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell} align="center">
                                Requested Replacement Staff, did not receive any
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableCellRow}>
                            <TableCell className={classes.tableCell} align="center">
                                <input className={classes.input}
                                    type="number"
                                    min={0}
                                    {...actualStaffing}
                                    onFocus={selectOnFocus}
                                />
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <input className={classes.input}
                                    type="number"
                                    min={0}
                                    {...usedReplacementStaffing}
                                    onFocus={selectOnFocus}
                                />
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <input type="checkbox" {...requestReplacementFailed}/>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Grid item className={classes.spacing} xs={12}></Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center">
                        <Typography variant="h5">Strike Plan for Your Unit</Typography>
                    </Grid>
                    <CustomizedTextField
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        rowsMax={10}
                        {...strikePlan}
                    />
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    )
}) 

export default withStyles(styles)(StrikeInput);