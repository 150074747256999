/* global google */
import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import axios from 'axios';
import { handleAxiosError, getAPIUrl } from './AppUtils';
import { Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core'
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";

const styles = theme => ({
    gridContainer: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 20,
        width: "100%",
    },
    bold: {
        fontWeight: "bold",
    },
    normal: {
        fontWeight: "normal",
    },
    mapContainer: {
        height: 600,
        width: 600,
    },
    selectedRow: {
        backgroundColor: "#90caf9",
    },
    table: {
        marginTop: 40,
    },
    tableRow: {
        '&:nth-child(even)': { 
            backgroundColor: '#f5f5f5',
        }
    }
});

function HospitalsMapPage(props){ 
    const { classes } = props;
    const source = axios.CancelToken.source();
    const [hospitals, setHospitals] = useState([]);
    const [mapCenter, setMapCenter] = useState({ lat: 40.792831, lng: -73.958381 });
    const [mapZoom, setMapZoom] = useState(11);
    const [selectedHopsital, setSelectedHospital] = useState(undefined);
    useEffect(() => {
        getHospitals();
        return () => {source.cancel("unmount");}
    }, []);
    function getHospitals() {
        axios.get(getAPIUrl()+'/hospitals',{cancelToken: source.token})
        .then(response => {
            setHospitals(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function handleHospitalClick(hospital) {
        if(hospital.latitude && hospital.longitude){
            setMapCenter({ lat: hospital.latitude, lng: hospital.longitude });
            setMapZoom(16);
            setSelectedHospital(hospital.id);
        }
    }
    function onMapDrag(){
        setSelectedHospital(undefined);
    }
    return(
        <Grid container justify='center' direction='row' className={classes.gridContainer}>
            <Grid item xs={5} style={{paddingRight: 20}}>
                <Typography variant="h6" style={{paddingLeft:20}}>
                    Hospitals and Ambulatory Units
                </Typography>
                <Table className={classes.table}>
                    <colgroup>
                        <col style={{width:"20%"}}/>
                        <col style={{width:"80%"}}/>
                    </colgroup>
                    {/* <TableHead>
                        <TableRow className={classes.tableHeaderRow}>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography className={classes.bold}>Label</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography align="left" className={classes.bold}>Information</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead> */}
                <TableBody>
                    {hospitals
                    .map((hospital,i)=>
                    <TableRow className={selectedHopsital==hospital.id?classes.selectedRow:classes.tableRow} key={hospital.id} 
                        onClick={() => handleHospitalClick(hospital)}
                        >
                        <TableCell className={classes.tableCell} align="center">
                            <Typography className={selectedHopsital==hospital.id?classes.bold:undefined}>
                                {i+1}
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography align="left" className={selectedHopsital==hospital.id?classes.bold:undefined}>
                                {hospital.name}
                            </Typography>
                            <Typography align="left" style={{whiteSpace: 'pre-line'}} className={selectedHopsital==hospital.id?classes.bold:undefined}>
                                {hospital.address}
                            </Typography>
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
            </Grid>
            <Grid item xs={7} >
                <HospitalMap
                    googleMapURL={"https://maps.googleapis.com/maps/api/js?key=AIzaSyB819SwsCr4frzBLDm-47ahWLNzKPKCXRQ&v=3.exp"}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `500px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    hospitals={hospitals}
                    center={mapCenter}
                    zoom={mapZoom}
                    setZoom={setMapZoom}
                    onDrag={onMapDrag}
                    handleHospitalClick={handleHospitalClick}
                />
            </Grid>
        </Grid>
    )
}
export default withStyles(styles)(HospitalsMapPage);

const HospitalMap = withScriptjs(withGoogleMap((props) =>{
    const { classes, hospitals, center, zoom, setZoom, onDrag, handleHospitalClick } = props;
    const mapRef = useRef(null);
    function onZoomChanged(){
        setZoom(mapRef.current.getZoom());
    }
    return (
        <GoogleMap
            // defaultCenter={{ lat: 40.792831, lng: -73.958381 }}
            // defaultZoom={11}
            ref={mapRef}
            center={center}
            zoom={zoom}
            onZoomChanged={onZoomChanged}
            onDrag={onDrag}
        >
            {/* <MarkerWithLabel
                position={{ lat: 40.856446, lng: -73.973789 }}
                labelAnchor={new google.maps.Point(0, 0)}
                labelStyle={{backgroundColor: "yellow", fontSize: "32px", padding: "16px"}}
            >
                <div>Hello There!</div>
            </MarkerWithLabel> */}
            {hospitals.map((hospital,i)=>
                <Marker 
                    onClick={()=>handleHospitalClick(hospital)}
                    key={hospital.id}
                    position={{lat: hospital.latitude, lng: hospital.longitude}} 
                    label={(i+1).toString()}
                />
            )}
        </GoogleMap>
    )
}))
