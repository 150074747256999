import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios';
import { handleAxiosError, getAPIUrl, getSocketUrl } from '../AppUtils';
import { Typography, Grid, Button, CircularProgress, Checkbox, FormGroup, FormControl, 
    FormControlLabel, ButtonGroup } from '@material-ui/core';
import TrendsBarChart from '../Trends/TrendsBarChart';
import TrendsLineChart from '../Trends/TrendsLineChart';
import SelectEventsDialog from '../SelectEventsDialog';

const styles = theme => ({
    page: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 300,
    },
    loading: {
        minHeight: '100vh',
    },
    spacing: {
        height: 50
    }
});

function TrendsPage(props) {
    const { classes } = props;
    const [trends, setTrends] = useState({fields:[],statuses:[]});
    const [allEvents, setAllEvents] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState(null);
    const [selectEventDialogOpen, setSelectEventDialogOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [submissionType, setSubmissionType] = useState('submission_percent');
    const source = axios.CancelToken.source();
    
    // const colors = ["#3AC7FF","#009D00","#fdd835","#f57c00","#e00e3c","#512da8", "#6d4c41","#546e7a"]
    const colors = ["#0087BF","#006691","#054660","#223749","#91dfff","#66d3ff","#3ac7ff","#00b7ff","#4287f5"];
    useEffect(() => {
        getEvents();
        return () => {
            source.cancel("unmount");
        }
    }, []);
    useEffect(()=>{
        if(selectedEvents!=null){
            getTrends();
        }
    },[selectedEvents])
    function getEvents(){
        axios.get(getAPIUrl()+'/quickStatRepEvents',{cancelToken: source.token})
        .then(response => {
            setAllEvents(response.data);
            setSelectedEvents(response.data.slice(-8).reduce((obj,n)=>{
                obj[n.id] = true;
                return obj;
            },{}));
        })
        .catch(error=>{
            handleAxiosError(error)
        });
    }
    function getTrends(){
        setLoading(true);
        axios.post(getAPIUrl()+'/quickStatRepTrends',Object.keys(selectedEvents).filter(n=>selectedEvents[n]),{cancelToken: source.token})
        .then(response => {
            setTrends(response.data);
            setLoading(false);
        })
        .catch(error=>{
            handleAxiosError(error)
            setLoading(false);
        });
    }
    if(loading){
        return (
            <Grid className={classes.loading} container direction="column" justify='center' alignItems='center'>
                {/* <Typography variant="h6">Loading...</Typography> */}
                <div style={{height:20}}/>
                <CircularProgress/>
            </Grid>
        )
    }
    if(!trends){
        return(
            <Typography>Error loading page</Typography>
        )
    }
    return (
        <Grid className={classes.page} container direction="column" justify='center' alignItems='center'>
            <SelectEventsDialog
                open={selectEventDialogOpen}
                setOpen={setSelectEventDialogOpen}
                setSelectedEvents={setSelectedEvents}
                events={allEvents}
                selectedEvents={selectedEvents}
            />
            <Grid item container justify="flex-end">
                <Button className={classes.button} onClick={()=>setSelectEventDialogOpen(true)} variant="contained" color="secondary">
                    Select Events
                </Button>
            </Grid>
            {trends.fields.map(field=>
                <TrendsLineChart
                    key={field.id}
                    events = {trends.events}
                    columns = {trends.statuses.map(n=>n.name)}
                    dataKey={'field_'+field.id}
                    title={field.name}
                    colors={trends.statuses.map(n=>n.color)}
                />
            )}
            <div className={classes.spacing}/>
            <TrendsLineChart
                events = {trends.events}
                columns = {["Submission Status"]}
                dataKey={submissionType}
                title={"Submission Status"}
                colors={colors}
            />
            <ButtonGroup style={{marginTop:10}} color="primary" aria-label="submission button group">
                <Button variant={submissionType==='submission_percent'?"contained":undefined} onClick={()=>setSubmissionType('submission_percent')}>Percent</Button>
                <Button variant={submissionType==='submission_number'?"contained":undefined} onClick={()=>setSubmissionType('submission_number')}>Number</Button>
            </ButtonGroup>
        </Grid>
    );
}

export default withStyles(styles)(TrendsPage);