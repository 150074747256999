import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Typography, Grid } from '@material-ui/core'

const styles = theme => ({
    tableHeaderCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 40,
    },
    tableCellRow: {
        height: 40,
    },
    tableCell: {
        width: 50,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
})

const TRAINTable = React.memo((props)=>{
    const { classes, TRAINTypes, TRAINCategories, TRAINData } = props
    if(!TRAINData){
        return null;
    }
    const colors = ["rgba(58, 199, 255, 0.7)","rgba(0, 157, 0, 0.7)","rgba(253, 216, 53, 0.7)","rgba(245, 124, 0, 0.7)","rgba(224, 14, 60, 0.7)"]
    return (
        <Table>
            <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                    <TableCell className={classes.tableCell} align="center">
                    </TableCell>
                    {TRAINCategories.map((category,i)=>
                        <TableCell key={category.id} className={classes.tableHeaderCell} 
                            align="center"
                            style={{backgroundColor: colors[i]}}
                        >
                            {category.name}
                        </TableCell>
                    )}
                    <TableCell className={classes.tableHeaderCell} 
                        align="center"
                    >
                        Specialized Needs (ISOLATION, etc.)
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {TRAINTypes.map(type=>
                    <TableRow key={type.id} className={classes.tableCellRow}>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{type.name}</Typography>
                        </TableCell>
                        {TRAINCategories.map((category,i)=>
                            <TableCell key={category.id}className={classes.tableCell} align="center"
                                style={{backgroundColor: colors[i]}}
                            >
                                {TRAINData[type.id][category.id]}
                            </TableCell>
                        )}
                        <TableCell className={classes.tableCell} align="center">
                            {TRAINData[type.id].specializedNeeds}
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    )
}) 

export default withStyles(styles)(TRAINTable);