import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const styles = theme => ({
});

const TrendsLineChart = React.memo((props)=>{
    const { classes, events, columns, dataKey, title, colors, height } = props
    if(!events || !columns){
        return <div/>
    }
    const labels = columns;
    const chartData = {
      labels: events.map(n=>n.name),
      datasets: columns.map((event,i)=>{
          return {
            label: labels[i],
            backgroundColor: colors[i],
            data: events.map(n=>n[dataKey][i]),
            fill: false,
            borderColor: colors[i],
        }})
    }
    const options = {
        plugins: {
            title: {
                display: true,
                text: title,
                font: {size: 20},
                padding: 10,
                color: "#191F2D",
                //   fontStyle: 'normal',
            },
            legend: {
                display: true,
                position: 'bottom',
                labels:{boxWidth:20, font: {size: 15}},
            },
        },
        // responsive: false,
        
        
        scales: {
            x: {
                grid: {
                    display:false
                },
                ticks: {
                    font: {
                        size: 12
                    }
                },
            },
            y: {
                grid: {
                    drawBorder: false,
                    display:true
                },
                ticks: {
                    display: true,
                    beginAtZero: true 
                }
            },
        },
        tooltips: {
            // enabled: false,
            // mode : 'label',
        },
        hover: {mode: null},
    
    };
    return(
        <Line
            width={500}
            height={height?height:150}
            data={chartData}
            options={options}
        />
    )

})

export default withStyles(styles)(TrendsLineChart);