import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Grid, Typography, CircularProgress, Tooltip } from '@material-ui/core'
import { useCheckbox, useFormInput } from '../CustomHooks';
import axios from 'axios';
import { handleAxiosError, getAPIUrl } from '../AppUtils';
import { connect } from 'react-redux';
import QuickStatRepInput from './QuickStatRepInput';

const styles = theme => ({
    page: {
        width: "100%",
    },
    gridContainer: {
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        width: "100%"
    },
    companyLogo: {
        backgroundColor: theme.palette.background.default,
        maxWidth: "100%",
    },
    spacing: {
        height: 25,
    },
    title: {
        fontFamily: "'Lato', sans-serif",
        fontSize: 25,
        fontWeight: "bold",
    },
    submitErrorMessage: {
        color: 'red',
        marginTop: 10,
        marginLeft: 10,
    },
});

function CreateQuickStatRepPage(props) {
    const { classes, departmentInfo, logo, initialDetails, orgSettings, unions, shifts } = props;
    const source = axios.CancelToken.source();
    
    const [invalidReport, setInvalidReport] = useState(false);
    const [submittingReport, setSubmittingReport] = useState(false);
    // const strikeDate = useFormInput(new Date());
    const [data, setData] = useState(undefined);
    const selectedEvent = useFormInput("");
    const [events, setEvents] = useState([]);
    const [fields, setFields] = useState([]);
    const [statuses, setStatuses] = useState([]);
    //mount, unmount
    useEffect(() => {
        getEvents();
        if(initialDetails){
            selectedEvent.onChange({target:{value:initialDetails.event_id}});
            setData(initialDetails.field_data.reduce((obj,n)=>{
                obj[n.field_id] = n;
                return obj;
            },{}))
        }
        getFields();
        getStatuses();
        return () => {source.cancel("unmount");}
    }, []);
    useEffect(()=>{
        if(!data && fields.length > 0){
            setData(fields.reduce((obj,n)=>{
                obj[n.id] = {status: 1, description: ""}
                return obj;
            },{}));
        }
    },[fields])

    function getEvents(){
        axios.get(getAPIUrl()+'/openQuickStatRepEvents',{cancelToken: source.token})
        .then(response => {
            setEvents(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getFields(){
        axios.get(getAPIUrl()+'/quickStatRepFields',{cancelToken: source.token})
        .then(response => {
            setFields(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getStatuses(){
        axios.get(getAPIUrl()+'/quickStatRepStatuses',{cancelToken: source.token})
        .then(response => {
            setStatuses(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function submitReport(){
        setInvalidReport(false);
        if(!allFieldsValid()){
            setInvalidReport(true);
        }
        else{
            let reportData = {
                departmentInfo,
                eventId:selectedEvent.value,
                data,
            }
            setSubmittingReport(true);
            axios.post(getAPIUrl()+'/submitQuickStatRep',{...reportData},{cancelToken: source.token})
            .then(response => {
                setSubmittingReport(false);
                props.setDepartmentInfo(undefined);
                props.history.push("/quickStatRepSubmitted");
            })
            .catch(error => {
                if(handleAxiosError(error)!=='cancel'){
                    setSubmittingReport(false);
                }
            });
        }
    }
    function allFieldsValid(){
        let valid = true;
        if(selectedEvent.value===""){
            valid = false;
        }
        fields.forEach(field=>{
            if(statuses.find(status=>status.id==data[field.id].status).description_required && !data[field.id].description){
                valid = false;
            }
        })
        return valid;
    }
    function handleStatusChange(fieldId, value){
        setData({
            ...data,
            [fieldId]: {
                ...data[fieldId],
                status: value
            }
        })
    }
    function handleDescriptionChange(fieldId, value){
        setData({
            ...data,
            [fieldId]: {
                ...data[fieldId],
                description: value
            }
        })
    }
    return (
        <div className={classes.page}>
            <Grid container justify='center' className={classes.gridContainer}>
                <Grid item md={4}>
                    <img className={classes.companyLogo}
                        src={logo}
                        alt=''
                    />
                </Grid>
                <Grid container item md={4} direction="column" justify='center' alignItems='center'>
                    <Typography className={classes.title} variant="h3">Quick StatRep</Typography>
                </Grid>
                <Grid item md={4}/>
                <Grid item className={classes.spacing} xs={12}></Grid>
                <Grid item sm={12}>
                    <div className={classes.departmentInfoBox}>
                        {departmentInfo.labels.map((n,i)=>
                            <Typography key={i} style={{fontWeight:"bold"}}>{n}</Typography>
                        )}
                    </div>
                </Grid>
                <Grid item className={classes.spacing} xs={12}></Grid>
                <Grid item xs={12}>
                    {/* <Typography variant="h5">Strike Table</Typography> */}
                    <QuickStatRepInput
                        data={data}
                        fields={fields}
                        events={events}
                        statuses={statuses}
                        selectedEvent={selectedEvent}
                        handleStatusChange={handleStatusChange}
                        handleDescriptionChange={handleDescriptionChange}
                        // event={initialDetails?{id:initialDetails.strike_event_id,name:initialDetails.strike_name}:undefined}
                    />
                </Grid>
                <Grid item className={classes.spacing} xs={12}></Grid>
                <Grid item container alignItems='center'>
                    {submittingReport?
                        <CircularProgress color="secondary"/>:
                        <Button className={classes.button} color="secondary" variant="contained" size="small"
                            onClick={()=>submitReport()}
                        >
                            Submit Report
                        </Button>
                    }
                    {invalidReport?<Typography className={classes.submitErrorMessage}>Please make sure all fields are filled.</Typography>:undefined}
                </Grid>
                <Grid item className={classes.spacing} xs={12}></Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { unions, shifts } = state
    return { unions, shifts }
};

export default withStyles(styles)(connect(mapStateToProps)(CreateQuickStatRepPage));