import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Link, Typography, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import axios from 'axios';
import { stableSort, getSorting, SortableLabel } from '../TableUtils'
import { handleAxiosError, getAPIUrl, getSocketUrl } from '../AppUtils';
import io from 'socket.io-client';

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 120,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
        // position: "sticky",
        // top: 0
    },
    tableHeaderRow: {
        height: 30,
    },
    tableRow: {
        height: 40,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    greenCell: {
        backgroundColor: '#38a438',
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    redCell: {
        backgroundColor: 'red',
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    searchGrid: {
        marginBottom: 20,
    },
    totalText: {
        marginLeft: 15,
    },
    pagination:{
        marginTop: 30,
    },
    tableBody:{
        // display:'block',
        // overflowY: 'scroll',
        // maxHeight: 300,
        // width: '100%',
    },
})

function EventStaffingDemandAggregatedTable(props) {
    const { classes, eventId, staffId, aggregates, filters, colLabel } = props;
    const source = axios.CancelToken.source();

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('present');
    const [aggregateStaff, setAggregateStaff] = useState([]);
    const [staffTitle, setStaffTitle] = useState("");
    const [extraIdentifierColumns, setExtraIdentifierColumns] = useState([]);

    useEffect(()=>{
        getAggregateStaff();
        getStaffTitle();
        const socket = io(getSocketUrl());
        socket.on('socket_msg', getAggregateStaff);
        return () => {
            socket.close();
            source.cancel("unmount");
        }
    }, []);

    useEffect(()=>{
        getAggregateStaff();
    }, [aggregates,filters]);

    function getStaffTitle() {
        axios.get(getAPIUrl()+`/staffTitle/${staffId}`,{cancelToken: source.token})
        .then(response => {
            setStaffTitle(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getAggregateStaff() {
        var url =  eventId?getAPIUrl()+`/event/${eventId}/staffingDemand/${staffId}/aggregate/${aggregates}`:
            getAPIUrl()+`/event/staffingDemand/${staffId}/aggregate/${aggregates}`
        if(filters){
            url += `/filter/${filters}`
        }
        axios.get(url,{cancelToken: source.token})
        .then(response => {
            setExtraIdentifierColumns(response.data.extraIdentifierColumns)
            setAggregateStaff(response.data.entries);
        })
        .catch(error => handleAxiosError(error));
    }
    const handleRequestSort = property => event => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    function getLinkURL(newFilter){
        var filtersString = filters?`${filters}&${newFilter}`:newFilter;
        var url = '/event';
        if(eventId){
            url += `/${eventId}`
        }
        url += `/staffingDemand/${staffId}/aggregate/${aggregates}/filter/${filtersString}`
        return url;
    }
    function getLinkOrText(link,label){
        if(filters&&filters.split('&').length===aggregates.split('&').length){
            return label;
        }
        else{
            return (
                <Link component={RouterLink} 
                    to={getLinkURL(link)}
                >
                    {label}
                </Link>
            )
        }
    }

    console.log("aggregateStaff", aggregateStaff)
    return (
        <div>
            <Typography style={{fontSize:20,fontWeight:"bold"}} display="inline">DETAILS: </Typography>
            <Typography style={{fontSize:20}} display="inline"> STAFFING DEMAND ({staffTitle.toUpperCase()}) </Typography>
            <div style={{height:20}}/>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        {extraIdentifierColumns.map(n=>
                            <TableCell key={n.id} className={classes.tableHeaderCell} align="center">
                                <SortableLabel
                                    order={order}
                                    active={orderBy===n.id}
                                    handleSort={handleRequestSort(n.id)}
                                    label={n.name}
                                />
                            </TableCell>
                        )}
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="name"}
                                handleSort={handleRequestSort("name")}
                                label={colLabel}
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="director_name"}
                                handleSort={handleRequestSort("director_name")}
                                label="Sr.Director/CAO Name"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="positions"}
                                handleSort={handleRequestSort("positions")}
                                label="Number of Positions"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="status"}
                                handleSort={handleRequestSort("status")}
                                label="Status"
                            />
                        </TableCell>
                        
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stableSort(aggregateStaff, getSorting(order, orderBy))
                    .map((n, index)=>
                    <TableRow className={classes.tableRow} key={index}>
                        {extraIdentifierColumns.map(col=>
                            <TableCell key={col.id} className={classes.tableCell} align="center">
                                <Typography>{n[col.id]}</Typography>
                            </TableCell>
                        )}
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>
                                {getLinkOrText(n.id,n.name)}
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{n.director_name}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{n.positions}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            {n.status === "perm" 
                                ?<Typography>Permanent</Typography>
                                :<Typography>Temporary</Typography>}
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
            {/* </div> */}
        </div>
    )
} 

export default withStyles(styles)(EventStaffingDemandAggregatedTable);