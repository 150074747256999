import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Link, Typography, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import axios from 'axios';
import { handleAxiosError, getAPIUrl, getSocketUrl } from '../AppUtils';
import io from 'socket.io-client';

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 120,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 30,
    },
    tableRow: {
        height: 40,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    searchGrid: {
        marginBottom: 20,
    },
    totalText: {
        marginLeft: 15,
    },
    pagination:{
        marginTop: 30,
    },
})

function EventAvailableBedsAggregatedTable(props) {
    const { classes, eventId, typeId, aggregates, filters, colLabel } = props;
    const source = axios.CancelToken.source();

    const [bedType, setBedType] = useState("");
    const [bedInfo, setBedInfo] = useState({aggregates:[],statuses:[],availability:[]});
    const [extraColumns, setExtraColumns] = useState([]);

    useEffect(()=>{
        getBedAvailability();
        getBedType();
        const socket = io(getSocketUrl());
        socket.on('socket_msg', getBedAvailability)
        return () => {
            socket.close();
            source.cancel("unmount");
        }
    }, []);

    useEffect(()=>{
        getBedAvailability();
    }, [aggregates,filters]);

    function getBedType() {
        axios.get(getAPIUrl()+`/bedType/${typeId}`,{cancelToken: source.token})
        .then(response => {
            setBedType(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getBedAvailability() {
        var url =  eventId?getAPIUrl()+`/event/${eventId}/available-beds/${typeId}/aggregate/${aggregates}`:
            getAPIUrl()+`/event/available-beds/${typeId}/aggregate/${aggregates}`
        if(filters){
            url += `/filter/${filters}`
        }
        axios.get(url,{cancelToken: source.token})
        .then(response => {
            setExtraColumns(response.data.extraColumns)
            setBedInfo(response.data.data);
        })
        .catch(error => handleAxiosError(error));
    }
    function getLinkURL(newFilter){
        var filtersString = filters?`${filters}&${newFilter}`:newFilter;
        var url = '/event';
        if(eventId){
            url += `/${eventId}`
        }
        url += `/available-beds/${typeId}/aggregate/${aggregates}/filter/${filtersString}`
        return url;
    }
    function getLinkOrText(link,label){
        if(filters&&filters.split('&').length===aggregates.split('&').length){
            return label;
        }
        else{
            return (
                <Link component={RouterLink} 
                    to={getLinkURL(link)}
                >
                    {label}
                </Link>
            )
        }
    }
    var bedAvailability = bedInfo.availability.reduce((obj, entry) => {
        obj[[entry.id,entry.status,entry.category]] = entry.number;
        return obj;
    }, {});
    return (
        <div>
            <Typography style={{fontSize:20,fontWeight:"bold"}} display="inline">DETAILS: </Typography>
            <Typography style={{fontSize:20}} display="inline"> AVAILABLE-BEDS ({bedType.toUpperCase()}) </Typography>
            <div style={{height:20}}/>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        {extraColumns.map(n=>
                            <TableCell key={n.id} className={classes.tableHeaderCell} align="center">
                                {n.name}
                            </TableCell>
                        )}
                        <TableCell className={classes.tableHeaderCell} align="center">
                            {colLabel}
                        </TableCell>
                        {bedInfo.statuses.map(status=>
                            <TableCell className={classes.tableHeaderCell} 
                                align="center" 
                                key={status.id}
                                colSpan={status.categories.length} 
                            >
                                {status.name}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow padding="none" className={classes.tableCellRow}>
                        {extraColumns.map(col=>
                            <TableCell key={col.id} className={classes.tableCell} align="center"></TableCell>
                        )}
                        <TableCell className={classes.tableCell} align="center"></TableCell>
                        {bedInfo.statuses.map(status=>status.categories.map(category=>
                            <TableCell key={category.id} className={classes.tableCell} align="center">
                                <Typography>{category.name}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                    {bedInfo.aggregates.map(n=>
                    <TableRow className={classes.tableRow} key={n.id}>
                        {extraColumns.map(col=>
                            <TableCell key={col.id} className={classes.tableCell} align="center">
                                <Typography>{n[col.id]}</Typography>
                            </TableCell>
                        )}
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>
                                {getLinkOrText(n.id,n.name)}
                            </Typography>
                        </TableCell>
                        {bedInfo.statuses.map(status=>status.categories.map(category=>
                            <TableCell key={category.id}className={classes.tableCell} align="center">
                                <Typography>
                                    {getLinkOrText(n.id,bedAvailability[[n.id,status.id,category.id]])}
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>)}
                </TableBody>
            </Table>
            {/* </div> */}
        </div>
    )
} 

export default withStyles(styles)(EventAvailableBedsAggregatedTable);