import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Typography, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

const styles = theme => ({
    tableHeaderCell: {
        // minWidth: 150,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 35,
    },
    tableCellRow: {
        height: 40,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
})

const EventResourceStatusTable = React.memo((props)=>{
    const { classes, eventId, resourceItems, aggregates, filterValue } = props
    const eventUrl = eventId?'/event/'+eventId:'/event';
    return (
        <div>
            <Typography style={{fontSize:20,fontWeight:"bold"}} display="inline">Resource Status</Typography>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        <TableCell className={classes.tableHeaderCell} align="center">Resource</TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">On Hand</TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">Available for Deployment</TableCell>
                        {resourceItems.length>1?
                            <React.Fragment>
                                <TableCell className={classes.tableHeaderCell} align="center">Resource</TableCell>
                                <TableCell className={classes.tableHeaderCell} align="center">On Hand</TableCell>
                                <TableCell className={classes.tableHeaderCell} align="center">Available for Deployment</TableCell>
                            </React.Fragment>
                            :undefined
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {resourceItems.map((item,index)=>
                        index%2===0?
                        <TableRow className={classes.tableCellRow} key={item.id}>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>
                                    <Link component={RouterLink} 
                                        to={filterValue?
                                            eventUrl+`/resource/${item.id}/aggregate/${aggregates}/filter/${filterValue}`
                                            :eventUrl+`/resource/${item.id}/aggregate/${aggregates}`}
                                    >
                                        {item.name}
                                    </Link>
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>
                                    <Link component={RouterLink} 
                                        to={filterValue?
                                            eventUrl+`/resource/${item.id}/aggregate/${aggregates}/filter/${filterValue}`
                                            :eventUrl+`/resource/${item.id}/aggregate/${aggregates}`}
                                    >
                                        {item.onHand}
                                    </Link>
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>
                                    <Link component={RouterLink} 
                                        to={filterValue?
                                            eventUrl+`/resource/${item.id}/aggregate/${aggregates}/filter/${filterValue}`
                                            :eventUrl+`/resource/${item.id}/aggregate/${aggregates}`}
                                    >
                                        {item.availableForDeployment}
                                    </Link>
                                </Typography>
                            </TableCell>
                            {resourceItems[index+1]?
                                <React.Fragment>
                                    <TableCell className={classes.tableCell} align="center">
                                        <Typography>
                                            <Link component={RouterLink} 
                                                to={filterValue?
                                                    eventUrl+`/resource/${resourceItems[index+1].id}/aggregate/${aggregates}/filter/${filterValue}`
                                                    :eventUrl+`/resource/${resourceItems[index+1].id}/aggregate/${aggregates}`}
                                            >
                                                {resourceItems[index+1].name}
                                            </Link>
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="center">
                                        <Typography>
                                            <Link component={RouterLink} 
                                                to={filterValue?
                                                    eventUrl+`/resource/${resourceItems[index+1].id}/aggregate/${aggregates}/filter/${filterValue}`
                                                    :eventUrl+`/resource/${resourceItems[index+1].id}/aggregate/${aggregates}`}
                                            >
                                                {resourceItems[index+1].onHand}
                                            </Link>
                                        </Typography>
                                        <Typography></Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="center">
                                        <Typography>
                                            <Link component={RouterLink} 
                                                to={filterValue?
                                                    eventUrl+`/resource/${resourceItems[index+1].id}/aggregate/${aggregates}/filter/${filterValue}`
                                                    :eventUrl+`/resource/${resourceItems[index+1].id}/aggregate/${aggregates}`}
                                            >
                                                {resourceItems[index+1].availableForDeployment}
                                            </Link>
                                        </Typography>
                                    </TableCell>
                                </React.Fragment>
                                :undefined
                            }
                        </TableRow>
                        :undefined
                    )}
                </TableBody>
            </Table>
        </div>
    )
}) 

export default withStyles(styles)(EventResourceStatusTable);