import React, {useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Typography } from '@material-ui/core'

const styles = theme => ({
    tableHeaderCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
    },
    tableHeaderRow: {
        height: 30,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
})

function StaffingDemandTable(props) {
    const { classes, staffingDemand } = props;
    if(!staffingDemand){
        return null;
    }
    return (
        <Table>
            <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                    <TableCell className={classes.tableHeaderCell} align="center">Sr.Director/CAO Name</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">Position Title</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">Number of Positions</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">Status</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">Start Date</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">End Date</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {staffingDemand.map((staff, i) =>
                    <TableRow key={i}>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{staff.name}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{staff.title}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{staff.positions}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            {staff.status === "perm" 
                            ? <Typography>Permanent</Typography> 
                            : <Typography>Temporary</Typography>}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{staff.start_date.slice(0,10)}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{staff.end_date.slice(0,10)}</Typography>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    )
} 

export default withStyles(styles)(StaffingDemandTable);