import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core'
import WalkoutByDateChart from './WalkoutByDateChart';
import WalkoutByPropertyChart from './WalkoutByPropertyChart';
import AncillaryStaffChart from './AncillaryStaffChart';
import axios from 'axios';
import { handleAxiosError, getAPIUrl } from '../AppUtils';
import { connect } from 'react-redux';

const styles = theme => ({
});

const StrikeCharts = React.memo((props)=>{
    const { classes, reports, eventName, unions } = props
    const labels = ["Walkout Rate Based on Reduced Staff for the Day","Walkout Rate Based on Normal Staff for the Day"];
    const colors = ["#223749","#3AC7FF"]
    // const reports = reports.filter(n=>n.normal_walkout_rate!=='N/A'&&n.reduced_walkout_rate!=='N/A')
    const source = axios.CancelToken.source();
    
    const [hospitals, setHospitals] = useState([]);
    const [departments, setDepartments] = useState([]);

    //mount, unmount
    useEffect(() => {
        getHospitals();
        getDepartments();
        return () => {source.cancel("unmount");}
    }, []);

    function getHospitals(){
        axios.get(getAPIUrl()+'/hospitals',{cancelToken: source.token})
        .then(response => {
            setHospitals(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getDepartments(){
        //note this is UCSD 'departments', not the usual department
        axios.get(getAPIUrl()+'/departmentTagTypeOptions/1',{cancelToken: source.token})
        .then(response => {
            setDepartments(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    return (
        <Grid container>
            <Grid item xs={6}>
                <WalkoutByDateChart
                    labels={labels}
                    colors = {colors}
                    reports = {reports}
                    eventName={eventName}
                />
            </Grid>
            <Grid item xs={6}>
                <WalkoutByPropertyChart
                    labels={labels}
                    colors = {colors}
                    reports = {reports}
                    property = "union_id"
                    title = "Walkout Rate by Union"
                    propertyTypes = {unions}
                />
            </Grid>
            <WalkoutByPropertyChart
                labels={labels}
                colors = {colors}
                reports = {reports}
                property = "hospital_id"
                title = "Walkout Rate by Location"
                propertyTypes = {hospitals}
                fullWidth={true}
            />
            <WalkoutByPropertyChart
                labels={labels}
                colors = {colors}
                reports = {reports}
                property = "1"
                title = "Walkout Rate by Department A-G"
                propertyTypes = {departments.filter(n=>/^[a-g]/i.test(n.name.toLowerCase()))}
                fullWidth={true}
            />
            <WalkoutByPropertyChart
                labels={labels}
                colors = {colors}
                reports = {reports}
                property = "1"
                title = "Walkout Rate by Department H-O"
                propertyTypes = {departments.filter(n=>/^[h-o]/i.test(n.name.toLowerCase()))}
                fullWidth={true}
            />
            <WalkoutByPropertyChart
                labels={labels}
                colors = {colors}
                reports = {reports}
                property = "1"
                title = "Walkout Rate by Department P-Z"
                propertyTypes = {departments.filter(n=>/^[p-z]/i.test(n.name.toLowerCase()))}
                fullWidth={true}
            />
            <AncillaryStaffChart
                labels={labels}
                colors = {colors}
                reports = {reports}
                eventName={eventName}
            />
        </Grid>
    )

})

const mapStateToProps = (state) => {
    const { unions } = state
    return { unions }
};

export default withStyles(styles)(connect(mapStateToProps)(StrikeCharts));