import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Pie } from 'react-chartjs-2';

const styles = theme => ({
});

const StaffingPieChart = React.memo((props)=>{
    const { classes, title, labels, data, colors } = props
    const chartData = {
      labels: labels,
      datasets: [{
        backgroundColor: colors,
        data: data
      }]
    }
    const options = {
        responsive: false,
        plugins:{
          title: {
            display: true,
            text: title,
            font: {size: 15},
            color: '#191F2D',
          //   fontStyle: 'normal',
          },
          legend: {
            display: true,
            position: 'bottom',
            labels:{boxWidth:20, font:{size: 10} } 
          }
        }
        
    };
    return(
        <Pie
          width={380}
          height={350}
          data={chartData}
          options={options}
        />
    )

})

export default withStyles(styles)(StaffingPieChart);