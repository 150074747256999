import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Link, Typography, IconButton,
        Tooltip, TableSortLabel, Grid, CircularProgress } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from 'react-bootstrap/Pagination'
import './custom.scss';
import { format, parseISO } from 'date-fns'
import { stableSort, getSorting, SortableLabel } from './TableUtils'
import PlusIcon from '@material-ui/icons/AddCircleOutline';
import MinusIcon from '@material-ui/icons/RemoveCircleOutline';
import clsx from "clsx"

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 120,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 6,
        '&:last-child':{
            paddingRight: 6
        }
    },
    clickable: {
        '&:hover': {
            cursor: "pointer",
        },
    },
    tableHeaderRow: {
        height: 30,
    },
    tableRow: {
        height: 40,
        // '&:hover': {
        //     cursor: "pointer",
        // },
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 6,
    },
})

const TableFlexible = React.memo((props)=>{
    const { classes, data, aggregateColumns, dataColumns, expanded, setExpanded } = props
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('id');

    // const handleRequestSort = property => event => {
    //     const isDesc = orderBy === property && order === 'desc';
    //     setOrder(isDesc ? 'asc' : 'desc');
    //     setOrderBy(property);
    // }
    
    function GetExpandedRows(aggColsLeft, dataColumns, filteredData){
        const agg = aggColsLeft[0];
        var newRows = []
        agg.options.forEach(m=>{
            var newData = filteredData.filter(x=>x[agg.info.key]===m.id);
            if(filteredData.length===0){
                return [];
            }
            if(expanded[[agg.info.key,m.id]]){
                var newAggCols = aggColsLeft.slice(1);
                if(newAggCols.length === 0){
                    newRows = newRows.concat(newData.map(n=>{
                        const dataObj = dataColumns.reduce((obj,col)=>{
                            obj[col.id] = n[col.id];
                            return obj;
                        },{})
                        return {
                            // department: n.department,
                            // department_name: n.department_name,
                            [agg.info.key]: m.id,
                            [agg.info.key+'_name']: m.name,
                            ...dataObj
                        }
                    }));
                }
                else{
                    var expandedRows = GetExpandedRows(newAggCols, dataColumns, newData);
                    if(expandedRows.length > 0){
                        expandedRows[0] = {
                                ...expandedRows[0],
                                [agg.info.key]: m.id,
                                [agg.info.key+'_name']: m.name,
                        }
                        newRows = newRows.concat(expandedRows);
                    }
                }
            }
            else{
                const initialSum = dataColumns.reduce((obj,col)=>{
                    obj[col.id] = 0;
                    return obj;
                },{})
                const values = newData.reduce((obj,m)=>{
                    dataColumns.forEach(col=>{
                        obj[col.id] += m[col.id] ? m[col.id]: 0;
                    })
                    return obj;
                },initialSum);
                if(dataColumns.some(col=>values[col.id])){
                    newRows.push({
                        [agg.info.key]: m.id,
                        [agg.info.key+'_name']: m.name,
                        ...values
                    });
                }
            }
        })
        return newRows;
    }
    var rows = GetExpandedRows(aggregateColumns,dataColumns,data);
    return (
        <div>
        <Table>
            <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                    {aggregateColumns.map((n,i)=>
                        <TableCell key={n.info.key} className={clsx(classes.tableHeaderCell,i<aggregateColumns.length-1 && classes.clickable)} align="center"
                            onClick={()=>{
                                if(data.every(row=>expanded[[n.info.key,row[n.info.key]]])){
                                    let newExpanded = n.options.reduce((obj,option)=>{
                                        obj[[n.info.key,option.id]]=false; return obj;
                                    },{})
                                    setExpanded({...expanded,...newExpanded})
                                }
                                else{
                                    let newExpanded = n.options.reduce((obj,option)=>{
                                        obj[[n.info.key,option.id]]=true; return obj;
                                    },{})
                                    setExpanded({...expanded,...newExpanded})
                                }
                            }}
                        >
                            {/* <SortableLabel
                                order={order}
                                active={orderBy===n.info.key}
                                handleSort={handleRequestSort(n.info.key)}
                                label={n.info.name}
                            /> */}
                            <Grid container direction="row" alignItems="center">
                                <Grid item xs={i<aggregateColumns.length-1?10:12}>
                                    {n.info.name}
                                </Grid>
                                {i<aggregateColumns.length-1?
                                <Grid item xs={2}>
                                    {data.every(row=>expanded[[n.info.key,row[n.info.key]]])?<MinusIcon fontSize={"small"}/>:<PlusIcon fontSize={"small"}/>}
                                </Grid>:undefined}
                            </Grid>
                        </TableCell>
                    )}
                    {dataColumns.map(col=>
                        <TableCell key={col.id} className={classes.tableHeaderCell} align="center">
                            {/* <SortableLabel
                                order={order}
                                active={orderBy===col.id}
                                handleSort={handleRequestSort(col.id)}
                                label={col.name}
                            /> */}
                            {col.name}
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {stableSort(rows, getSorting(order, orderBy))
                .map((row,i)=>
                <TableRow className={classes.tableRow} key={i}>
                    {aggregateColumns.map((agg,aggIndex)=>
                        <TableCell className={clsx(classes.tableCell, aggIndex<aggregateColumns.length-1 && classes.clickable)} align="center" key={agg.info.key} 
                            onClick={()=>setExpanded({...expanded,[[agg.info.key,row[agg.info.key]]]:!expanded[[agg.info.key,row[agg.info.key]]]})}>
                            {row[agg.info.key+'_name']?
                            <Grid container direction="row" alignItems="center">
                                <Grid item xs={aggIndex<aggregateColumns.length-1?10:12}>
                                    <Typography style={{marginRight: 5}}>
                                        {row[agg.info.key+'_name']}
                                    </Typography>
                                </Grid>
                                {aggIndex<aggregateColumns.length-1?
                                <Grid item xs={2}>
                                    {expanded[[agg.info.key,row[agg.info.key]]]?<MinusIcon fontSize={"small"}/>:<PlusIcon fontSize={"small"}/>}
                                </Grid>:undefined}
                            </Grid>:undefined}
                        </TableCell>
                    )}
                    {dataColumns.map(col=>
                        <TableCell key={col.id} className={classes.tableCell} align="center">
                            <Typography>
                                {row[col.id]?row[col.id]:0}
                            </Typography>
                        </TableCell>
                    )}
                </TableRow>)}
                <TableRow>
                    <TableCell className={classes.tableCell} align="center">
                        <Typography>
                            Grand Total
                        </Typography>
                    </TableCell>
                    {aggregateColumns.slice(1).map(agg=><TableCell key={agg.info.key} className={classes.tableCell}></TableCell>)}
                    {dataColumns.map(col=>
                        <TableCell key={col.id} className={classes.tableCell} align="center">
                            <Typography>
                                {data.reduce((sum,n)=>{
                                    if(n[col.id]){
                                        return sum + n[col.id];
                                    }
                                    return sum;
                                },0)}
                            </Typography>
                        </TableCell>
                    )}

                </TableRow>
            </TableBody>
        </Table>
        </div>
    )
}) 

export default withStyles(styles)(TableFlexible);