import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios';
import EventsTable from './EventsTable';
import { handleAxiosError, getAPIUrl } from './AppUtils';
import CreateEventButton from './CreateEventButton';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';

const styles = theme => ({
    page: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 300,
    },
    button: {
        '&:hover': {
            color: theme.palette.secondary.contrastText,
            textDecoration: 'none'
        },
    }
});

function EventsPage(props) {
    const { classes, orgSettings } = props;
    const source = axios.CancelToken.source();
    const [events, setEvents] = useState(undefined);

    useEffect(() => {
        getEvents();
        return () => {source.cancel("unmount");}
    }, []);
    function getEvents(){
        axios.get(getAPIUrl()+'/events',{cancelToken: source.token})
        .then(response => {
            setEvents(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    return (
        <div className={classes.page}>
            {orgSettings.noEventCreation?undefined:
                <Grid style={{marginBottom: 30}} container direction="row" justify="space-between">
                    <CreateEventButton refreshEvents={getEvents}/>
                    <Button variant="contained" component={RouterLink} to="/scheduledEvents" color="secondary" className={classes.button}>
                        View Scheduled Events
                    </Button>
                </Grid>
            }
            <EventsTable
                events={events}
                history={props.history}
                refreshEvents={getEvents}
            />
        </div>
    );
}

export default withStyles(styles)(EventsPage);