import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import BarChart from './BarChart';

const styles = theme => ({
})

const TableFlexible = React.memo((props)=>{
    const { classes, data, aggregateColumns, dataColumns, title, expanded } = props
    
    function GetExpandedRows(aggColsLeft, dataColumns, filteredData){
        const agg = aggColsLeft[0];
        var newRows = []
        agg.options.forEach(m=>{
            var newData = filteredData.filter(x=>x[agg.info.key]===m.id);
            if(filteredData.length===0){
                return [];
            }
            if(expanded[[agg.info.key,m.id]]){
                var newAggCols = aggColsLeft.slice(1);
                if(newAggCols.length === 0){
                    newRows = newRows.concat(newData.map(n=>{
                        const dataObj = dataColumns.reduce((obj,col)=>{
                            obj[col.id] = n[col.id];
                            return obj;
                        },{})
                        return {
                            // department: n.department,
                            // department_name: n.department_name,
                            [agg.info.key]: m.id,
                            [agg.info.key+'_name']: m.name,
                            ...dataObj
                        }
                    }));
                }
                else{
                    var expandedRows = GetExpandedRows(newAggCols, dataColumns, newData);
                    if(expandedRows.length > 0){
                        expandedRows[0] = {
                                ...expandedRows[0],
                                [agg.info.key]: m.id,
                                [agg.info.key+'_name']: m.name,
                        }
                        newRows = newRows.concat(expandedRows);
                    }
                }
            }
            else{
                const initialSum = dataColumns.reduce((obj,col)=>{
                    obj[col.id] = 0;
                    return obj;
                },{})
                const values = newData.reduce((obj,m)=>{
                    dataColumns.forEach(col=>{
                        obj[col.id] += m[col.id] ? m[col.id]: 0;
                    })
                    return obj;
                },initialSum);
                if(dataColumns.some(col=>values[col.id])){
                    newRows.push({
                        [agg.info.key]: m.id,
                        [agg.info.key+'_name']: m.name,
                        ...values
                    });
                }
            }
        })
        return newRows;
    }
    var rows = GetExpandedRows(aggregateColumns,dataColumns,data);
    var prevLabel = {};
    const labelCols = aggregateColumns.map(agg=>agg.info.key+'_name');
    const labels = rows.reduce((arr,row)=>{
        const vals = labelCols.map(col=>row[col]);
        let maxIndex = 0;
        vals.forEach((val,index)=>{
            if(val){
                maxIndex = index;
            }
        })
        let i = 0;
        var labelVals = [];
        for(i = 0; i < vals.length; i++){
            if(i <= maxIndex){
                let val = vals[i]?vals[i]:prevLabel[labelCols[i]];
                prevLabel[labelCols[i]] = val;
                labelVals.push(val);
            }
            else{
                prevLabel[labelCols[i]] = null;
            }
        }
        arr.push(labelVals);
        return arr;
    },[])
    const colors = ["#0087BF","#006691","#054660","#223749","#91dfff","#66d3ff","#3ac7ff","#00b7ff","#4287f5"];
    const datasets = dataColumns.map((col,i)=>{
        return {
            label: col.name,
            backgroundColor: colors[i],
            data: rows.map(row=>row[col.id]?row[col.id]:0)
        }
    });
    return (
            <BarChart
                labels={labels}
                datasets={datasets}
                title={title}
            />
    )
}) 

export default withStyles(styles)(TableFlexible);