import React, { useState, useEffect } from 'react'

export function useFormInput(initialValue){
    const [value, setValue] = useState(initialValue);
    function handleChange(e){
        setValue(e.target.value)
    }
    return { value, onChange: handleChange };
}

export function useCheckbox(initialValue){
    const [checked, setValue] = useState(initialValue);
    function handleChange(e){
        setValue(e.target.checked)
    }
    return { checked, onChange: handleChange };
}

export function useFormInputAutoSelect(initialValue){
    const [value, setValue] = useState(initialValue);
    function handleChange(e){
        setValue(e.target.value)
    }
    function selectOnFocus(event) {
        event.target.select();
    }
    return { value, onChange: handleChange, onFocus: selectOnFocus };
}

export function useFormInputClear(initialValue){
    const [value, setValue] = useState(initialValue);
    function handleChange(e){
        setValue(e.target.value)
    }
    function clear(){
        setValue(initialValue)
    }
    return [{ value, onChange: handleChange },clear];
}

export function useNewTimer(currentDate) {
    const [date, setDate] = useState(currentDate);
    useEffect(() => {
        var timerID = setInterval(() => tick(), 1000);
        return function cleanup() {
            clearInterval(timerID);
        };
    });
    function tick() {
        setDate(new Date());
    }
    return date;
}