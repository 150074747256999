import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios';
import { handleAxiosError, getAPIUrl, getSocketUrl } from './AppUtils';
import { Typography, Grid, Button, CircularProgress, Checkbox, FormGroup, FormControl, 
    FormControlLabel, ButtonGroup, Dialog, DialogContent, DialogActions, DialogTitle, MenuItem } from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import TrendsLineChart from './Trends/TrendsLineChart';
import { connect } from 'react-redux';
import TableFlexible from './TableFlexible';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import SelectEventsDialog from './SelectEventsDialog';
import StandardTable from './StandardTable';
import BarChartFlexible from './BarChartFlexible';
import { stableSort, getSorting } from './TableUtils';

const ExpansionPanel = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
    },
})(MuiExpansionPanel);


const ExpansionPanelSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 30,
      '&$expanded': {
        minHeight: 30,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const styles = theme => ({
    page: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 300,
    },
    loading: {
        minHeight: '100vh',
    },
    spacing: {
        height: 50
    },
    filterOption: {
        paddingLeft: 5,
        paddingRight: 5,
    },
    titleFont:{
        fontSize: 20, fontColor: '#191F2D', fontFamily:"'Helvetica Neue', 'Helvetica', 'Arial', sans-serif", fontWeight:"bold"
    }
});

function StaffingPage(props) {
    const { classes, departmentAlias, filters, departments, match } = props;
    const [trends, setTrends] = useState({});
    const [selectedStaffTitles, setSelectedStaffTitles] = useState({});
    const [selectedEvents, setSelectedEvents] = useState(null);
    const [selectEventDialogOpen, setSelectEventDialogOpen] = useState(false);
    const [selectEventsDialogOpen, setSelectEventsDialogOpen] = useState(false);
    const [event, setEvent] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingTrends, setLoadingTrends] = useState(true);
    const [eventStaffing, setEventStaffing] = useState([]);
    const [staffTitles, setStaffTitles] = useState([]);
    const [dataColumns, setDataColumns] = useState([]);
    const [selectedDataColumns, setSelectedDataColumns] = useState({});
    const [events, setEvents] = useState([]);
    const [staffTitleFirst, setStaffTitleFirst] = useState(true);
    const [expanded, setExpanded] = useState({})
    const [showPivotTable, setShowPivotTable] = useState(false);
    
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('id');

    const handleRequestSort = property => event => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    const source = axios.CancelToken.source();
    
    useEffect(() => {
        getEvents();
        return () => {
            source.cancel("unmount");
        }
    }, []);
    useEffect(()=>{
        getEventData();
    },[match.params.id])
    useEffect(()=>{
        if(selectedEvents!=null){
            getStaffingTrends();
        }
    },[selectedEvents])
    function getEventData(){
        getEventStaffing();
        getEvent();
    }
    function getEvent(){
        const url = match.params.id?
            getAPIUrl()+`/eventDetails/${match.params.id}`
            :getAPIUrl()+'/eventDetails'
        axios.get(url,{cancelToken: source.token})
        .then(response => {
            setEvent(response.data);
        })
        .catch(error=>{
            handleAxiosError(error)
        });
    }
    function getEvents(){
        axios.get(getAPIUrl()+'/events',{cancelToken: source.token})
        .then(response => {
            setEvents(response.data);
            setSelectedEvents(response.data.slice(-8).reduce((obj,n)=>{
                obj[n.id] = true;
                return obj;
            },{}));
        })
        .catch(error=>{
            handleAxiosError(error);
        });
    }
    function getEventStaffing(){
        const url = match.params.id?
            getAPIUrl()+`/event/${match.params.id}/staffing`
            :getAPIUrl()+'/event/staffing'
        axios.get(url,{cancelToken: source.token})
        .then(response => {
            setStaffTitles(response.data.allTitles.slice().sort((a, b)=> (a.name > b.name) ? 1 : -1));
            setSelectedStaffTitles(response.data.allTitles.reduce((obj,n)=>{obj[n.id]=true;return obj;},{}));
            setEventStaffing(response.data.staffing);
            let cols = [{id:'present',name:'Number Present'}].concat(response.data.additionalColumns).concat([{id:'available',name:'Available to Labor Pool'}]);
            setDataColumns(cols);
            setSelectedDataColumns(cols.reduce((obj,n)=>{obj[n.id]=true;return obj;},{}))
            setLoading(false);
        })
        .catch(error=>{
            handleAxiosError(error)
            setLoading(false);
        });
    }
    function getStaffingTrends(){
        setLoadingTrends(true);
        axios.post(getAPIUrl()+'/staffingTrends',Object.keys(selectedEvents).filter(n=>selectedEvents[n]),{cancelToken: source.token})
        .then(response => {
            setTrends(response.data);
            setLoadingTrends(false);
        })
        .catch(error=>{
            handleAxiosError(error)
            setLoadingTrends(false);
        });
    }
    if(loading){
        return (
            <Grid className={classes.loading} container direction="column" justify='center' alignItems='center'>
                {/* <Typography variant="h6">Loading...</Typography> */}
                <div style={{height:20}}/>
                <CircularProgress/>
            </Grid>
        )
    }
    const colors = ["#0087BF","#006691","#054660","#223749","#91dfff","#66d3ff","#3ac7ff","#00b7ff","#4287f5"];
    var usedTitles = trends.events?trends.events.reduce((obj,event)=>{
        event.staffingEntries.forEach(n=>{
            obj[n.title] = true;
        })
        return obj;
    },{}):{};
    eventStaffing.forEach(n=>{
        usedTitles[n.title] = true;
    })
    const filteredStaffTitles = staffTitles.filter(n=>usedTitles[n.id]);
    const aggregateColumns = staffTitleFirst?[{info:{key: 'title',name:'Staff Title'},options:filteredStaffTitles}].concat(filters.concat({info:{key:'department',name:departmentAlias},options:departments}))
        :filters.concat({info:{key:'department',name:departmentAlias},options:departments}).concat([{info:{key: 'title',name:'Staff Title'},options:filteredStaffTitles}]);
    const filteredDataColumns = dataColumns.filter(n=>selectedDataColumns[n.id]);
    const filteredStaffing = eventStaffing.filter(n=>selectedStaffTitles[n.title]);
    const eventsTableCols = [{id:"id",name:"ID"},{id:"name",name:"Name"}].concat(filteredDataColumns).concat([{id:"submission_status",name:"Submission %"},{id:"close_time",name:"Close Time"}])
    const trendEvents = trends.events?trends.events.map(n=>{
        let chartData = n.staffingEntries.filter(n=>selectedStaffTitles[n.title]).reduce((obj,n)=>{
            filteredDataColumns.forEach(col=>{
                obj[col.id] += n[col.id]?n[col.id]:0;
            });
            return obj;
        },filteredDataColumns.reduce((obj,n)=>{obj[n.id] = 0; return obj;},{}))
        return {
            ...n,
            chart: filteredDataColumns.map(col=>chartData[col.id]),
            ...chartData
        }
    }):[];

    
    return (
        <div className={classes.page}>
            <Dialog 
                style={{minWidth: 300}}
                open={selectEventDialogOpen}
                onClose={()=>setSelectEventDialogOpen(false)}
            >
                <DialogTitle>Select Event</DialogTitle>
                <DialogContent>
                    <FormControl>
                        {events.slice().reverse().map(n=>
                            <MenuItem key={n.id} component={RouterLink} to={`/staffing/${n.id}`} onClick={()=>setSelectEventDialogOpen(false)}>{n.name}</MenuItem>
                        )}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.button} onClick={()=>setSelectEventDialogOpen(false)} variant="contained" color="default">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <SelectEventsDialog
                open={selectEventsDialogOpen}
                setOpen={setSelectEventsDialogOpen}
                setSelectedEvents={setSelectedEvents}
                events={events}
                selectedEvents={selectedEvents}
            />
            <Grid style={{marginBottom:20}} item container direction="row" alignItems="center">
                <Typography variant="h5">Event: {event.name}</Typography>
                <Button variant="contained" color="secondary" style={{marginLeft: 20}} onClick={()=>setSelectEventDialogOpen(true)}>Change</Button>
            </Grid>
            
            <ExpansionPanel square style={{width: "100%", marginTop: 20, marginBottom: 10}}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                <Typography className={classes.heading}>Advanced Options</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container>
                        <Grid item container direction="column" md={4} alignItems="center" className={classes.filterOption}>
                            <Typography style={{marginBottom: 10}} variant="h6">Staff Titles</Typography>
                            <FormControl style={{maxHeight: 300, overflowY: 'scroll'}}>
                                <FormControlLabel
                                    control={
                                    <Checkbox 
                                        checked={Boolean(filteredStaffTitles.every(n=>selectedStaffTitles[n.id]))}
                                        indeterminate={filteredStaffTitles.some(n=>selectedStaffTitles[n.id])&&!filteredStaffTitles.every(n=>selectedStaffTitles[n.id])}
                                        onChange={(event)=>{
                                            if(event.target.checked){
                                                setSelectedStaffTitles(staffTitles.reduce((obj,n)=>{obj[n.id]=true;return obj;},{}))
                                            }
                                            else{
                                                setSelectedStaffTitles({})
                                            }
                                        }}
                                    />}
                                    label="All"
                                />
                                {filteredStaffTitles.map(n=>
                                    <FormControlLabel
                                        key={n.id}
                                        control={<Checkbox checked={Boolean(selectedStaffTitles[n.id])} onChange={(event)=>setSelectedStaffTitles({...selectedStaffTitles,[n.id]: event.target.checked})}/>}
                                        label={n.name}
                                    />
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item container direction="column" md={4} alignItems="center" className={classes.filterOption}>
                            <Typography style={{marginBottom: 10}} variant="h6">Data Columns</Typography>
                            <FormControl style={{maxHeight: 300, overflowY: 'scroll'}}>
                                <FormControlLabel
                                    control={
                                    <Checkbox 
                                        checked={Boolean(dataColumns.every(n=>selectedDataColumns[n.id]))}
                                        indeterminate={dataColumns.some(n=>selectedDataColumns[n.id])&&!dataColumns.every(n=>selectedDataColumns[n.id])}
                                        onChange={(event)=>{
                                            if(event.target.checked){
                                                setSelectedDataColumns(dataColumns.reduce((obj,n)=>{obj[n.id]=true;return obj;},{}))
                                            }
                                            else{
                                                setSelectedDataColumns({})
                                            }
                                        }}
                                    />}
                                    label="All"
                                />
                                {dataColumns.map(n=>
                                    <FormControlLabel
                                        key={n.id}
                                        control={<Checkbox checked={Boolean(selectedDataColumns[n.id])} onChange={(event)=>setSelectedDataColumns({...selectedDataColumns,[n.id]: event.target.checked})}/>}
                                        label={n.name}
                                    />
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item container direction="column" md={4} alignItems="center" className={classes.filterOption}>
                            <FormControl>
                                <FormControlLabel
                                    control={<Checkbox checked={staffTitleFirst} onChange={(event)=>setStaffTitleFirst(event.target.checked)}/>}
                                    label={"Group by Staff Title First"}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <BarChartFlexible
                aggregateColumns={aggregateColumns}
                dataColumns={filteredDataColumns}
                data = {filteredStaffing}
                expanded = {expanded}
                title = {"Staffing By Title"}
            />
            {showPivotTable?
                <>
                <Button style={{marginBottom: 5}} variant="contained" color="secondary" size="small" onClick={()=>setShowPivotTable(false)}>Hide Table</Button>
                <Grid style={{marginBottom: 10}} container justify="center" >
                    <Typography className={classes.titleFont}>Staffing by Title</Typography>
                </Grid>
                <TableFlexible
                    aggregateColumns={aggregateColumns}
                    dataColumns={filteredDataColumns}
                    data = {filteredStaffing}
                    expanded = {expanded}
                    setExpanded = {setExpanded}
                /></>:<Button variant="contained" color="secondary" onClick={()=>setShowPivotTable(true)} size="small">Show Table</Button>
            }
            <div style={{height:30}} />
            <Grid container justify="flex-end">
                <Button className={classes.button} onClick={()=>setSelectEventsDialogOpen(true)} variant="contained" color="secondary">
                    Select Events
                </Button>
            </Grid>
            {loadingTrends?
                <Grid container justify="center"><CircularProgress/></Grid>:
                <React.Fragment>
                <TrendsLineChart
                    events = {stableSort(trendEvents, getSorting(order, orderBy)).reverse()}
                    columns = {filteredDataColumns.map(n=>n.name)}
                    dataKey={"chart"}
                    title={"Staffing Trend"}
                    colors={colors}
                />
                <div style={{height:30}} />
                <Grid style={{marginBottom: 10}} container justify="center"><Typography className={classes.titleFont}>Staffing Trend</Typography></Grid>
                <StandardTable
                    data = {trendEvents}
                    columns = {eventsTableCols}
                    order = {order}
                    handleRequestSort ={handleRequestSort}
                    orderBy = {orderBy}
                />
                </React.Fragment>
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    const { departmentAlias, departments, filters } = state
    return { departmentAlias, departments, filters }
};

export default withStyles(styles)(connect(mapStateToProps)(StaffingPage));