import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios';
import { handleAxiosError, getAPIUrl } from '../AppUtils';
import { Typography, Grid, FormControl, Select,
    Dialog, DialogContent, DialogTitle, DialogActions, Button  } from '@material-ui/core';
import StrikeInput from './StrikeInput';
import { useCheckbox, useFormInput } from '../CustomHooks';
import { connect } from 'react-redux';

const styles = theme => ({
    page: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 300,
    },
    formControl: {
        width: 320,
    },
    select: {
        borderRadius: 5,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#e2e2e2",
        backgroundColor: "#FFF",
        color: "#555555",
        fontSize: 14,
    },
});

function EditStrikeReportDialog(props) {
    const { classes, open, setOpen, report, handleClose, unions, shifts } = props;
    const source = axios.CancelToken.source();
    
    const selectedUnion = useFormInput("");
    const selectedShift = useFormInput("");
    const normalStaffing = useFormInput(0);
    const reducedStaffing = useFormInput(0);
    const safeStaffing = useFormInput(0);
    const actualStaffing = useFormInput(0);
    const usedReplacementStaffing = useFormInput(0);
    const requestReplacementFailed = useCheckbox(false);
    const strikePlan = useFormInput("");
    const [strikeEvents, setStrikeEvents] = useState([]);
    const [invalidReport, setInvalidReport] = useState(false);
    const [strikeEvent, setStrikeEvent] = useState({});
    //mount, unmount
    useEffect(() => {
        getStrikeEvents();
        return () => {source.cancel("unmount");}
    }, []);
    useEffect(()=>{
        if(report){
            selectedUnion.onChange({target:{value:report.union_id}});
            selectedShift.onChange({target:{value:report.shift_id}});
            normalStaffing.onChange({target:{value:report.normal_staffing}});
            reducedStaffing.onChange({target:{value:report.reduced_staffing}});
            safeStaffing.onChange({target:{value:report.safe_staffing}});
            actualStaffing.onChange({target:{value:report.actual_staffing}});
            usedReplacementStaffing.onChange({target:{value:report.used_replacement_staffing}});
            requestReplacementFailed.onChange({target:{checked:report.request_replacement_failed}});
            strikePlan.onChange({target:{value:report.strike_plan}});
        }
    },[report])

    useEffect(()=>{
        if(report){
            setStrikeEvent(strikeEvents.find(n=>n.id==report.strike_event_id))
        }
    },[report,strikeEvents])

    function getStrikeEvents(){
        axios.get(getAPIUrl()+'/strikeEvents',{cancelToken: source.token})
        .then(response => {
            setStrikeEvents(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function saveEdit(){
        setInvalidReport(false);
        if(!allFieldsValid()){
            setInvalidReport(true);
        }
        else{
            let reportData = {
                departmentInfo: {departmentId: report.department_id},
                eventId:report.strike_event_id,
                union:selectedUnion.value,
                shift:selectedShift.value,
                normalStaffing:normalStaffing.value,
                reducedStaffing:reducedStaffing.value,
                safeStaffing:safeStaffing.value,
                actualStaffing:actualStaffing.value,
                usedReplacementStaffing:usedReplacementStaffing.value,
                requestReplacementFailed:requestReplacementFailed.checked,
                strikePlan:strikePlan.value
            }
            axios.post(getAPIUrl()+'/submitStrikeReport',{...reportData},{cancelToken: source.token})
            .then(response => {
                setOpen(false);
            })
            .catch(error => {
                if(handleAxiosError(error)!=='cancel'){
                    setOpen(false);
                }
            });
        }
    }
    function allFieldsValid(){
        let valid = true;
        if(selectedUnion.value===""){
            valid = false;
        }
        if(selectedShift.value===""){
            valid = false;
        }
        if(normalStaffing.value===""){
            valid = false;
        }
        if(reducedStaffing.value===""){
            valid = false;
        }
        if(safeStaffing.value===""){
            valid = false;
        }
        if(actualStaffing.value===""){
            valid = false;
        }
        if(usedReplacementStaffing.value===""){
            valid = false;
        }
        return valid;
    }
    return (
        <Dialog 
            open={open}
            onClose={handleClose}
            fullWidth
        >
            {/* <DialogTitle>{'Confirmation Message'}</DialogTitle> */}
            <DialogContent>
                <StrikeInput
                    selectedUnion={selectedUnion}
                    selectedShift={selectedShift}
                    normalStaffing={normalStaffing}
                    reducedStaffing={reducedStaffing}
                    safeStaffing={safeStaffing}
                    actualStaffing={actualStaffing}
                    usedReplacementStaffing={usedReplacementStaffing}
                    requestReplacementFailed={requestReplacementFailed}
                    strikePlan={strikePlan}
                    unions={unions}
                    shifts={shifts}
                    strikeEvents={strikeEvents}
                    strikeEvent={strikeEvent}
                />
            </DialogContent>
            <DialogActions>
                <Button className={classes.button} onClick={saveEdit} variant="contained" color="secondary">
                    Save
                </Button>
                <Button className={classes.button} onClick={()=>setOpen(false)} variant="contained" color="default">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state) => {
    const { unions, shifts } = state
    return { unions, shifts }
};

export default withStyles(styles)(connect(mapStateToProps)(EditStrikeReportDialog));