import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Typography, Link,
        TableSortLabel, Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link as RouterLink } from 'react-router-dom'
import { format, parseISO } from 'date-fns';
import { connect } from 'react-redux';

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 120,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
    },
    tableWrapper: {
        maxHeight: 450,
        overflow: 'auto'
    },
    tableHeaderRow: {
        height: 40,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    tableBody:{
        // display:'block',
        overflowY: 'scroll',
        maxHeight: 300,
        width: '100%',
    },
})
function SortableLabel(props){
    return(
        <TableSortLabel
            active={props.active}
            hideSortIcon
            direction={props.order}
            onClick={props.handleSort}
            IconComponent={(props) => <FontAwesomeIcon {...props} size="lg" icon={["fa","caret-down"]}/>}
        >
            {props.label}
        </TableSortLabel>
    )
}


const EventProblemsTable = React.memo((props)=>{
    const { classes, departments, identifierColumns, departmentAlias } = props
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('submit_time');
    const handleRequestSort = property => event => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    function stableSort(array, cmp) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map(el => el[0]);
    }
    function getSorting(order, orderBy) {
      return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
    }
    function desc(a, b, orderBy) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
    const widths = 
        <colgroup>
            {identifierColumns.map(n=><col key={n.id}/>)}
            <col/>
            <col style={{width:'35%'}}/>
        </colgroup>
    return (
        <div>
            <Typography style={{fontSize:20,fontWeight:"bold"}} display="inline">Problems/Progress</Typography>
            <div className={classes.tableWrapper}>
                <Table stickyHeader>
                    {widths}
                    <TableHead>
                        <TableRow className={classes.tableHeaderRow}>
                            {identifierColumns.map(n=>
                                <TableCell key={n.id} className={classes.tableHeaderCell} align="center">
                                    <SortableLabel
                                        order={order}
                                        active={orderBy===n.id+"_name"}
                                        handleSort={handleRequestSort(n.id+"_name")}
                                        label={n.name}
                                    />
                                </TableCell>
                            )}
                            <TableCell className={classes.tableHeaderCell} align="center">
                                <SortableLabel
                                    order={order}
                                    active={orderBy==="name"}
                                    handleSort={handleRequestSort("name")}
                                    label={departmentAlias}
                                />
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell} align="center">
                                <SortableLabel
                                    order={order}
                                    active={orderBy==="prob_or_prog_text"}
                                    handleSort={handleRequestSort("prob_or_prog_text")}
                                    label={"Problems/Progress"}
                                />
                            </TableCell>
                            <TableCell className={classes.tableHeaderCell} align="center">
                                <SortableLabel
                                    order={order}
                                    active={orderBy==="submit_time"}
                                    handleSort={handleRequestSort("submit_time")}
                                    label="Submit Time"
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(departments, getSorting(order, orderBy))
                        .map(department=>
                        <TableRow className={classes.tableRow} key={department.id}>
                            {identifierColumns.map(n=>
                                <TableCell key={n.id} className={classes.tableCell} align="center">
                                    <Typography>
                                        <Link component={RouterLink} to={`/reportDetails/${department.report_id}`} target="_blank">
                                            {department[n.id+"_name"]}
                                        </Link>
                                    </Typography>
                                </TableCell>
                            )}
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>
                                    <Link component={RouterLink} to={`/reportDetails/${department.report_id}`} target="_blank">
                                        {department.name}
                                    </Link>
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>
                                    {department.prob_or_prog_text}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>
                                    <Link component={RouterLink} to={`/reportDetails/${department.report_id}`} target="_blank">
                                        {department.submit_time==='Pending'?'Pending':format(parseISO(department.submit_time),'MMM dd, yyyy hh:mm a')}
                                    </Link>
                                </Typography>
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}) 

const mapStateToProps = (state) => {
    const { departmentAlias, identifierColumns } = state
    return { departmentAlias, identifierColumns }
};

export default withStyles(styles)(connect(mapStateToProps)(EventProblemsTable));