import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';

const styles = theme => ({
});

const WalkoutByPropertyChart = React.memo((props)=>{
    const { classes, reports, labels, colors, title, property, propertyTypes, fullWidth } = props
    const groupedReports = reports.reduce((obj, entry) => {
        if(!obj[entry[property]]){
            obj[entry[property]] = {
                normal_walkout_rate: entry.normal_walkout_rate,
                reduced_walkout_rate: entry.reduced_walkout_rate,
                count: 1
            };
            return obj;
        };
        obj[entry[property]].normal_walkout_rate += entry.normal_walkout_rate;
        obj[entry[property]].reduced_walkout_rate += entry.reduced_walkout_rate;
        obj[entry[property]].count += 1;
        return obj; 
    },{});
    const propertyIds = propertyTypes.map(n=>n.id);
    const walkoutReduced = propertyIds.map(n=>{
        if(!groupedReports[n]){return 0;}
        return Math.round((groupedReports[n].reduced_walkout_rate/groupedReports[n].count + Number.EPSILON) * 100) / 100;
    });
    const walkoutNormal = propertyIds.map(n=>{
        if(!groupedReports[n]){return 0;}
        return Math.round((groupedReports[n].normal_walkout_rate/groupedReports[n].count + Number.EPSILON) * 100) / 100;
    });
    const chartData = {
        labels: propertyTypes.map(n=>n.name),
        datasets: [{
            label: labels[0],
            backgroundColor: colors[0],
            data: walkoutReduced,
        },{
            label: labels[1],
            backgroundColor: colors[1],
            data: walkoutNormal,
        }]
    }
    const options = {
        // responsive: false,
        title: {
          display: true,
          text: title,
          fontSize: 14,
          fontColor: '#191F2D',
        //   fontStyle: 'normal',
        },
        legend: {
          display: true,
          position: 'bottom',
          labels:{boxWidth:20, fontSize:10 } 
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display:false
                }
            }],
            yAxes: [{
                ticks: {
                    min: 0,
                },
                gridLines: {
                    display:false
                }   
            }]
        },
        tooltips: {
            mode : 'label',
        },
    };
    const width = fullWidth?600:380
    const height= fullWidth?300:300
    return(
        <Bar
          width={width}
          height={300}
          data={chartData}
          options={options}
        />
    )

})

export default withStyles(styles)(WalkoutByPropertyChart);