import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Typography } from '@material-ui/core'

const styles = theme => ({
    tableHeaderCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
    },
    tableHeaderRow: {
        height: 30,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
})

function StaffingTable(props) {
    const { classes, staffing, additionalColumns } = props;
    if(!staffing){
        return null;
    }
    return (
        <Table>
            <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                    <TableCell className={classes.tableHeaderCell} align="center">Title</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">Number Present</TableCell>
                    {additionalColumns.map(n=>
                        <TableCell key={n.id} className={classes.tableHeaderCell} align="center">
                            {n.name}
                        </TableCell>
                    )}
                    <TableCell className={classes.tableHeaderCell} align="center">Available to Labor Pool (if needed)</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {staffing.map((staff, i) =>
                    <TableRow key={i}>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{staff.title}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{staff.present}</Typography>
                        </TableCell>
                        {additionalColumns.map(n=>
                            <TableCell key={n.id} className={classes.tableCell} align="center">
                                {staff[n.id]}
                            </TableCell>
                        )}
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{staff.available}</Typography>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    )
} 

export default withStyles(styles)(StaffingTable);