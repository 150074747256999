import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Grid, Typography, Table, TableCell, TableRow, TableBody, Tooltip } from '@material-ui/core'
import { handleAxiosError, getAPIUrl } from '../AppUtils';
import { format, parseISO } from 'date-fns'
import InfoIcon from '@material-ui/icons/Info'
import CreateQuickStatRepPage from './CreateQuickStatRepPage';

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 150,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        padding: 10,
        // '&:last-child': {
        //   paddingRight: 0,
        // },
    },
    tableHeaderRow: {
        height: 35,
    },
    tableCellRow: {
        height: 40,
    },
    tableCell: {
        // width: 50,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 10,
        // '&:last-child': {
        //   paddingRight: 0,
        // },
    },
    tableCellLeft: {
        // width: 50,
        fontSize: 14,
        fontWeight: "bold",
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 10,
    },
    input: {
        // width: 50,
        textAlign: "center",
        paddingTop: 2,
        paddingBottom: 2,
        color: "#555555",
        fontSize: 14,
    },
    formControl: {
        minWidth: 130,
    },
    spacing: {
        height: 25,
    },
    select: {
        color: "#555555",
        fontSize: 14,
    },
    heavyFont: {
        fontSize: 14,
        fontWeight: "bold",
    },
    page: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 300,
    },
    spacing: {
        height: 25,
    },
    title: {
        fontFamily: "'Lato', sans-serif",
        fontSize: 25,
        fontWeight: "bold",
        textDecoration: "underline",
    },
    bold: {
        fontWeight: "bold",
    },
    normal: {
        fontWeight: "normal",
    },
    companyLogo: {
        backgroundColor: theme.palette.background.default,
        maxWidth: "100%",
    },
    gridContainer: {
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        width: "100%"
    },
});

function QuickStatRepDetails(props) {
    const { classes, report, logo, orgSettings } = props;
    const [ updating, setUpdating ] = useState(false);

    if(updating){
        return (
            <Grid container justify='center'>
                <Grid container justify='flex-end' className={classes.gridContainer}>
                    <Button className={classes.button} color="secondary" variant="contained" size="small"
                        onClick={()=>setUpdating(false)} 
                    >
                        Cancel Update
                    </Button>
                </Grid>
                <CreateQuickStatRepPage
                    history={props.history}
                    departmentInfo={{
                        hospitalId: report.hospital_id,
                        departmentId: report.department_id,
                        labels: report.departmentLabels,
                    }}
                    setDepartmentInfo={(e)=>{}}
                    logo={logo}
                    orgSettings={orgSettings}
                    initialDetails = {report}
                />
            </Grid>
        )
    }
    return (
        <Grid container className={classes.gridContainer}>
            <Grid item md={4}>
                <img className={classes.companyLogo}
                    src={logo}
                    alt=''
                />
            </Grid>
            <Grid container item md={4} direction="column" justify='center' alignItems='center'>
                <Typography className={classes.title} variant="h3">Report Details</Typography>
            </Grid>
            <Grid item md={4}>
                <Grid container justify='flex-end'>
                    <Button className={classes.button} color="secondary" variant="contained" size="small"
                        onClick={()=>setUpdating(true)} 
                    >
                        Update Report
                    </Button>
                </Grid>
            </Grid>
            <Grid item sm={12}>
                <div style={{marginTop:30, marginBottom:30}}>
                    {report.departmentLabels.map((n,i)=>
                        <Typography key={i} style={{fontWeight:"bold"}}>{n}</Typography>
                    )}
                </div>
            </Grid>
            <Table>
                <colgroup>
                    <col width="50%"/>
                    <col width="20%"/>
                    <col width="30%"/>
                </colgroup>
                <TableBody>
                    <TableRow className={classes.tableHeaderRow}>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <Typography className={classes.heavyFont}>{"Event: " + report.event_name}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            {"STATUS\n(Red/Green)"}
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            If Red - provide reason/need for escalation
                        </TableCell>
                    </TableRow>
                    {report.field_data.map(n=>
                        <TableRow key={n.field_id} className={classes.tableCellRow}>
                            <TableCell className={classes.tableCellLeft}>
                                {n.field_name}
                            </TableCell>
                            <TableCell className={classes.tableCell} style={{backgroundColor:n.status_color, color:'white'}} align="center">
                                {n.status_name}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                {n.description}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <Grid item className={classes.spacing} xs={12}></Grid>
            <Grid container item xs={12} justify='flex-start'>
                <Typography className={classes.bold} variant="h6">
                    Submitted By :&nbsp;
                </Typography>
                <Typography className={classes.normal} variant="h6">
                    {report.user_first_name + ' ' + report.user_last_name}&nbsp; 
                </Typography>
                <Typography className={classes.bold} variant="h6">
                    on&nbsp;
                </Typography>
                <Typography className={classes.normal} variant="h6">
                    {format(parseISO(report.last_modified),'MMM dd, yyyy hh:mm a')}&nbsp;
                </Typography>
            </Grid>
            <Grid container item xs={12} justify='flex-start'>
                <Typography className={classes.bold} variant="h6">
                    For Event: &nbsp;
                </Typography>
                <Typography className={classes.normal} variant="h6">
                    {report.event_name}
                </Typography>
            </Grid>
            <Grid item className={classes.spacing} xs={12}></Grid>
        </Grid>
    );
}

export default withStyles(styles)(QuickStatRepDetails);