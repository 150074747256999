import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Pie } from 'react-chartjs-2';

const styles = theme => ({
  });

const PatientStatusPieChart = React.memo((props)=>{
    const { classes, bedTypes, bedStatuses, bedAvailability, colors } = props
    if(!bedAvailability[1] || !bedAvailability[2]){
        return null
    }
    if(!bedAvailability){
        return null;
    }
    const title="Patient Status"
    const labels=['Adult Male Present','Adult Female Present','Peds Male Present','Peds Female Present',
    'Adult Male Awaiting Dis','Adult Female Awaiting Dis','Peds Male Awaitng Dis','Peds Female Awaiting Dis']
    //[type][status][category] eg [Adult][Occupied][Male]
    //type 1:adult, 2:ped
    //status 1:present 2:discharge
    //category 1:male 2:female
    const data=[bedAvailability[1][1][1],bedAvailability[1][1][2],bedAvailability[2][1][1],bedAvailability[2][1][2],
    bedAvailability[1][2][1],bedAvailability[1][2][2],bedAvailability[2][2][1],bedAvailability[2][2][2]]
    const chartData = {
      labels: labels,
      datasets: [{
        backgroundColor: colors,
        data: data
      }]
    }
    const options = {
        responsive: false,
        plugins: {
          title: {
            display: true,
            text: title,
            size: {font: 14},
            color: '#191F2D',
          //   fontStyle: 'normal',
          },
          legend: {
              display: true,
              position: 'bottom',
              labels:{boxWidth:20, font:{size: 10}} 
          },
        }
        
    };
    return(
        <Pie
            width={380}
            height={380}
            data={chartData}
            options={options}
        />
    )

})

export default withStyles(styles)(PatientStatusPieChart);