import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Link, Typography, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import axios from 'axios';
import { handleAxiosError, getAPIUrl, getSocketUrl } from '../AppUtils';
import io from 'socket.io-client';

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 120,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 30,
    },
    tableRow: {
        height: 40,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    searchGrid: {
        marginBottom: 20,
    },
    totalText: {
        marginLeft: 15,
    },
    pagination:{
        marginTop: 30,
    },
})

function EventTRAINAggregatedTable(props) {
    const { classes, eventId, typeId, history, aggregates, filters, colLabel } = props;
    const source = axios.CancelToken.source();

    const [TRAINType, setTRAINType] = useState("");
    const [TRAINInfo, setTRAINInfo] = useState({aggregates:[],categories:[],data:[]});
    const [extraColumns, setExtraColumns] = useState([]);

    useEffect(()=>{
        getTRAINInfo();
        getTRAINType();
        const socket = io(getSocketUrl());
        socket.on('socket_msg', getTRAINInfo)
        return () => {
            socket.close();
            source.cancel("unmount");
        }
    }, []);

    useEffect(()=>{
        getTRAINInfo();
    }, [aggregates,filters]);

    function getTRAINType() {
        axios.get(getAPIUrl()+`/TRAINType/${typeId}`,{cancelToken: source.token})
        .then(response => {
            if(response.data===null)
                history.push('/badurl');
            else
                setTRAINType(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getTRAINInfo() {
        var url =  eventId?getAPIUrl()+`/event/${eventId}/TRAIN/${typeId}/aggregate/${aggregates}`:
            getAPIUrl()+`/event/TRAIN/${typeId}/aggregate/${aggregates}`
        if(filters){
            url += `/filter/${filters}`
        }
        axios.get(url,{cancelToken: source.token})
        .then(response => {
            if(response.data===null)
                history.push('/badurl');
            else{
                // console.log(response.data)
                setTRAINInfo(response.data.data);
                setExtraColumns(response.data.extraColumns)
            }
        })
        .catch(error=>handleAxiosError(error));
    }
    function getLinkURL(newFilter){
        var filtersString = filters?`${filters}&${newFilter}`:newFilter;
        var url = '/event';
        if(eventId){
            url += `/${eventId}`
        }
        url += `/TRAIN/${typeId}/aggregate/${aggregates}/filter/${filtersString}`
        return url;
    }
    function getLinkOrText(link,label){
        if(filters&&filters.split('&').length===aggregates.split('&').length){
            return label;
        }
        else{
            return (
                <Link component={RouterLink} 
                    to={getLinkURL(link)}
                >
                    {label}
                </Link>
            )
        }
    }
    var TRAINData = TRAINInfo.data.reduce((obj, entry) => {
        obj[[entry.id,entry.category]] = entry.number;
        return obj;
    }, {});
    // console.log(TRAINInfo.aggregates)
    const colors = ["rgba(58, 199, 255, 0.7)","rgba(0, 157, 0, 0.7)","rgba(253, 216, 53, 0.7)","rgba(245, 124, 0, 0.7)","rgba(224, 14, 60, 0.7)"]
    return (
        <div>
            <Typography style={{fontSize:20,fontWeight:"bold"}} display="inline">DETAILS: </Typography>
            <Typography style={{fontSize:20}} display="inline"> TRIAGE RESOURCE ALLOCATION FOR INPATIENTS ({TRAINType.toUpperCase()}) </Typography>
            <div style={{height:20}}/>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        {extraColumns.map(n=>
                            <TableCell key={n.id} className={classes.tableHeaderCell} align="center">
                                {n.name}
                            </TableCell>
                        )}
                        <TableCell className={classes.tableHeaderCell} align="center">
                            {colLabel}
                        </TableCell>
                        {TRAINInfo.categories.map(category=>
                            <TableCell className={classes.tableHeaderCell} 
                                align="center" 
                                key={category.id}
                            >
                                {category.name}
                            </TableCell>
                        )}
                        {TRAINInfo.specializedNeeds?
                            <TableCell className={classes.tableHeaderCell} 
                                align="center" 
                            >
                                Specialized Needs
                            </TableCell>:undefined
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {TRAINInfo.aggregates.map(n=>
                    <TableRow className={classes.tableRow} key={n.id}>
                        {extraColumns.map(col=>
                            <TableCell key={col.id} className={classes.tableCell} align="center">
                                <Typography>{n[col.id]}</Typography>
                            </TableCell>
                        )}
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>
                                {getLinkOrText(n.id,n.name)}
                            </Typography>
                        </TableCell>
                        {TRAINInfo.categories.map((category,i)=>
                            <TableCell key={category.id} className={classes.tableCell} align="center"
                                style={{backgroundColor: colors[i]}}
                            >
                                <Typography>
                                    {getLinkOrText(n.id,TRAINData[[n.id,category.id]])}
                                </Typography>
                            </TableCell>
                        )}
                        {TRAINInfo.specializedNeeds?
                            <TableCell className={classes.tableCell} align="center">
                                <Typography>
                                    {getLinkOrText(n.id,n.specializedNeeds)}
                                </Typography>
                            </TableCell>:undefined
                        }
                    </TableRow>)}
                </TableBody>
            </Table>
        </div>
    )
} 

export default withStyles(styles)(EventTRAINAggregatedTable);