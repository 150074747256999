import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Grid, Typography, Table, TableCell, TableRow, TableBody, Tooltip } from '@material-ui/core'
import StrikeInput from './StrikeInput';
import { handleAxiosError, getAPIUrl } from '../AppUtils';
import { format, parseISO } from 'date-fns'
import InfoIcon from '@material-ui/icons/Info'
import CreateStrikeReportPage from './CreateStrikeReportPage';

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 150,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 35,
    },
    tableCellRow: {
        height: 40,
    },
    tableCell: {
        // width: 50,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    input: {
        // width: 50,
        textAlign: "center",
        paddingTop: 2,
        paddingBottom: 2,
        color: "#555555",
        fontSize: 14,
    },
    page: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 300,
    },
    spacing: {
        height: 25,
    },
    title: {
        fontFamily: "'Lato', sans-serif",
        fontSize: 25,
        fontWeight: "bold",
        textDecoration: "underline",
    },
    bold: {
        fontWeight: "bold",
    },
    normal: {
        fontWeight: "normal",
    },
    companyLogo: {
        backgroundColor: theme.palette.background.default,
        maxWidth: "100%",
    },
    gridContainer: {
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        width: "100%"
    },
});

function StrikeReportDetails(props) {
    const { classes, report, logo, orgSettings } = props;
    const [ updating, setUpdating ] = useState(false);

    if(updating){
        return (
            <Grid container justify='center'>
                <Grid container justify='flex-end' className={classes.gridContainer}>
                    <Button className={classes.button} color="secondary" variant="contained" size="small"
                        onClick={()=>setUpdating(false)} 
                    >
                        Cancel Update
                    </Button>
                </Grid>
                <CreateStrikeReportPage
                    history={props.history}
                    departmentInfo={{
                        hospitalId: report.hospital_id,
                        departmentId: report.department_id,
                        labels: report.departmentLabels,
                    }}
                    setDepartmentInfo={(e)=>{}}
                    logo={logo}
                    orgSettings={orgSettings}
                    initialDetails = {report}
                />
            </Grid>
        )
    }
    return (
        <Grid container className={classes.gridContainer}>
            <Grid item md={4}>
                <img className={classes.companyLogo}
                    src={logo}
                    alt=''
                />
            </Grid>
            <Grid container item md={4} direction="column" justify='center' alignItems='center'>
                <Typography className={classes.title} variant="h3">Report Details</Typography>
            </Grid>
            <Grid item md={4}>
                <Grid container justify='flex-end'>
                    <Button className={classes.button} color="secondary" variant="contained" size="small"
                        onClick={()=>setUpdating(true)} 
                    >
                        Update Report
                    </Button>
                </Grid>
            </Grid>
            <Grid item sm={12}>
                <div style={{marginTop:30, marginBottom:30}}>
                    {report.departmentLabels.map((n,i)=>
                        <Typography key={i} style={{fontWeight:"bold"}}>{n}</Typography>
                    )}
                </div>
            </Grid>
            <Table>
                <colgroup>
                    <col width="33%"/>
                    <col width="33%"/>
                    <col width="33%"/>
                </colgroup>
                <TableBody>
                    <TableRow className={classes.tableHeaderRow}>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            Strike Event
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            Union
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            Shift
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableCellRow}>
                        <TableCell className={classes.tableCell} align="center">
                            {report.strike_name}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            {report.union_name}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            {report.shift_name}
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableHeaderRow}>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            Normal Schedule Staffing
                            <Tooltip title="# of staff scheduled for normal operations">
                                <InfoIcon color="disabled" style={{marginLeft:10}}/>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            Reduced Schedule Staffing
                            <Tooltip title="# of staff scheduled to work during the strike">
                                <InfoIcon color="disabled" style={{marginLeft:10}}/>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            Safe Minimum Staffing
                            <Tooltip title="# of staff needed to maintain regulatory/patient safety levels">
                                <InfoIcon color="disabled" style={{marginLeft:10}}/>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableCellRow}>
                        <TableCell className={classes.tableCell} align="center">
                            {report.normal_staffing}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            {report.reduced_staffing}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            {report.safe_staffing}
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableHeaderRow}>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            Actual Staff Who Showed 
                            <Tooltip title="# of staff that showed up day of strike">
                                <InfoIcon color="disabled" style={{marginLeft:10}}/>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            Replacement Staff Used
                            <Tooltip title="# of replacement staff used">
                                <InfoIcon color="disabled" style={{marginLeft:10}}/>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            Requested Replacement Staff, did not receive any
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableCellRow}>
                        <TableCell className={classes.tableCell} align="center">
                            {report.actual_staffing}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            {report.used_replacement_staffing}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <input type="checkbox" checked={report.request_replacement_failed} readOnly/>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Grid item className={classes.spacing} xs={12}></Grid>
            <Grid item xs={12}>
                <Grid container direction="row" alignItems="center">
                    <Typography variant="h5">Strike Plan for Your Unit</Typography>
                </Grid>
                <Typography>{report.strike_plan}</Typography>
            </Grid>
            <Grid item className={classes.spacing} xs={12}></Grid>
            <Grid container item xs={12} justify='flex-start'>
                <Typography className={classes.bold} variant="h6">
                    Submitted By :&nbsp;
                </Typography>
                <Typography className={classes.normal} variant="h6">
                    {report.user_first_name + ' ' + report.user_last_name}&nbsp; 
                </Typography>
                <Typography className={classes.bold} variant="h6">
                    on&nbsp;
                </Typography>
                <Typography className={classes.normal} variant="h6">
                    {format(parseISO(report.last_modified),'MMM dd, yyyy hh:mm a')}&nbsp;
                </Typography>
            </Grid>
            <Grid container item xs={12} justify='flex-start'>
                <Typography className={classes.bold} variant="h6">
                    For Event: &nbsp;
                </Typography>
                <Typography className={classes.normal} variant="h6">
                    {report.strike_name}
                </Typography>
            </Grid>
            <Grid item className={classes.spacing} xs={12}></Grid>
        </Grid>
    );
}

export default withStyles(styles)(StrikeReportDetails);