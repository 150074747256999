import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Link, Typography, IconButton,
        Tooltip, TableSortLabel, Grid, InputAdornment } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormInput } from './CustomHooks'
import CustomizedTextField from './CustomizedTextField';
import Pagination from 'react-bootstrap/Pagination'
import './custom.scss';
import { format, parseISO } from 'date-fns'
import SearchIcon from '@material-ui/icons/Search';
import {SortableLabel, stableSort, getSorting } from './TableUtils'
import { connect } from 'react-redux';

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 100,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 30,
    },
    tableRow: {
        height: 40,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    searchGrid: {
        marginBottom: 20,
    },
    totalText: {
        marginLeft: 15,
    },
    pagination:{
        marginTop: 30,
    },
    iconButton: {
        padding: 5,
    }
})

function ReportsTable(props) {
    const { classes, reports, adminView, handleDelete, downloadReportPDF, departmentAlias, identifierColumns } = props
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const searchText = useFormInput("");
    const rowsPerPage = 10;
    React.useEffect(()=>{
        setPage(0);
    },[searchText.value]);
    const handleRequestSort = property => event => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    function filterFunc(input){
        if(searchText.value===""){
            return true;
        }
        const searchTerms = searchText.value.split(/[\s,]+/);
        const notContained = searchTerms.some(searchTerm=>{
            let containsTerm = false;
            Object.values(input).forEach(field=>{
                if(field.toString().toLowerCase().includes(searchTerm.toLowerCase())){
                    containsTerm = true;
                }
            })
            return !containsTerm
        })
        return !notContained;
    }
    function handlePageChange(pageNum){
        setPage(pageNum);
    }
    const filteredReports = reports?reports.filter(n=>filterFunc(n)):[]
    const numPages = Math.ceil(filteredReports.length/rowsPerPage);
    function handlePageIncrease(){
        if(page<numPages-1){
            setPage(page+1);
        }
    }
    function handlePageDecrease(){
        if(page>0){
            setPage(page-1);
        }
    }
    return (
        <div>
            <Grid className={classes.searchGrid} container alignItems='center'>
                <CustomizedTextField
                    variant="outlined"
                    endAdornment={<InputAdornment><SearchIcon/></InputAdornment>}
                    {...searchText}
                />
                <Typography className={classes.totalText} variant="h5">
                    Total : {filteredReports.length}
                </Typography>
            </Grid>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="id"}
                                handleSort={handleRequestSort("id")}
                                label="Report ID"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="event"}
                                handleSort={handleRequestSort("event")}
                                label="Event"
                            />
                        </TableCell>
                        {identifierColumns.map(n=>
                            <TableCell key={n.id} className={classes.tableHeaderCell} align="center">
                                <SortableLabel
                                    order={order}
                                    active={orderBy===n.id+"_name"}
                                    handleSort={handleRequestSort(n.id+"_name")}
                                    label={n.name}
                                />
                            </TableCell>
                        )}
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="department"}
                                handleSort={handleRequestSort("department")}
                                label={departmentAlias}
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">View Report</TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">Download</TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="last_modified"}
                                handleSort={handleRequestSort("last_modified")}
                                label="Report Date/Time"
                            />
                        </TableCell>
                        {adminView?
                            <TableCell className={classes.tableHeaderCell} align="center">
                                Delete
                            </TableCell>:undefined
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stableSort(filteredReports, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(report=>
                    <TableRow className={classes.tableRow} key={report.id}>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{report.id}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{report.event}</Typography>
                        </TableCell>
                        {identifierColumns.map(n=>
                            <TableCell key={n.id} className={classes.tableCell} align="center">
                                <Typography>
                                    {report[n.id+"_name"]}
                                </Typography>
                            </TableCell>
                        )}
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{report.department_name}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>
                                <Link component={RouterLink} to={`/reportDetails/${report.id}`}>Report</Link>
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <IconButton className={classes.iconButton}
                                onClick={()=>downloadReportPDF(report.id)}
                            >
                                <FontAwesomeIcon size="xs" icon={["fa","file-download"]}/>
                            </IconButton>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{format(parseISO(report.last_modified),'MMM dd, yyyy hh:mm a')}</Typography>
                            {/* <Typography>{report.date}</Typography> */}
                        </TableCell>
                        {adminView?
                            <TableCell className={classes.tableCell} align="center">
                                <IconButton className={classes.iconButton} 
                                    onClick={()=>handleDelete(report.id)}
                                >
                                    <FontAwesomeIcon size="xs" icon={["fa","trash-alt"]}/>
                                </IconButton>
                            </TableCell>:undefined
                        }
                    </TableRow>)}
                </TableBody>
            </Table>
            <div>
                
            <Pagination className={classes.pagination}>
                <Pagination.First onClick={()=>handlePageChange(0)}/>
                <Pagination.Prev onClick={handlePageDecrease} disabled={page===0}/>
                {page-10>0?<Pagination.Ellipsis/>:undefined}
                {Array(numPages).fill().map((_, i) => {
                    if(i>page-10 && i<page+10){
                        return(
                            <Pagination.Item key={i} 
                                active={page===i} 
                                onClick={()=>handlePageChange(i)}
                            >
                                {i+1}
                            </Pagination.Item>
                        );
                    }
                })}
                {page+10<numPages-1?<Pagination.Ellipsis/>:undefined}
                <Pagination.Next onClick={handlePageIncrease} disabled={page>=numPages-1}/>
                <Pagination.Last onClick={()=>handlePageChange(numPages-1)}/>
            </Pagination>
            </div>
        </div>
    )
} 

const mapStateToProps = (state) => {
    const { departmentAlias, identifierColumns } = state
    return { departmentAlias, identifierColumns }
};

export default withStyles(styles)(connect(mapStateToProps)(ReportsTable));