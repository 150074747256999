import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, AppBar, Toolbar } from '@material-ui/core'
import { loginAuth } from './AppUtils';
import ihsLogo from './images/ihs.png'
import BottomBar from './BottomBar';

const styles = theme => ({
    page: {
        minHeight: '100vh',
    },
    logo: {
        paddingLeft: 24,
        paddingTop: 20,
        maxWidth: 442,
        height: "auto",
    },
    grow: {
        flexGrow: 1,
    },
    text: {
        fontSize: 26,
        fontWeight: 500,
    },
    link: {
        fontSize: 26,
        fontWeight: 500,
        '&:hover': {
            cursor: "pointer",
        },
    },
});

function LoggedOutPage(props) {
    const { classes } = props;
    return (
        <Grid className={classes.page}container direction="column" justify='space-between'>
            <AppBar position="static">
                <Toolbar>
                    <img className={classes.logo}
                        src={ihsLogo}
                        alt=''
                    />
                    <div className={classes.grow}/>
                    <Grid className={classes.buttonGroup} container alignItems='center' justify='flex-end'>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Grid item container direction='column' justify="center" alignItems='center'>
                <Typography className={classes.text} variant="h4">You have successfully logged out.</Typography>
                <br/>
                <div>
                    <Typography className={classes.text} display="inline" variant="h4">
                        Click&nbsp; 
                    </Typography>
                    <Typography className={classes.link} display="inline" color="secondary" onClick={loginAuth} variant="h4">here</Typography>
                    <Typography className={classes.text} display="inline" variant="h4">
                        &nbsp;to login again.
                    </Typography>
                </div>
            </Grid>
            <BottomBar/>
        </Grid>
    );
}

export default withStyles(styles)(LoggedOutPage);