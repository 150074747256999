import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, ListItem, List, ListItemText, Paper, Typography } from '@material-ui/core'
import { Document, Page } from 'react-pdf';
import HelpPDF from './pdfs/e_StatRep.pdf';
import MosesEinsteinWakefieldPDF from './pdfs/STATREP- Moses Einstein Wakefield.pdf';
import NewRochellePDF from './pdfs/STATREP - New Rochelle.pdf';
import MtVernonPDF from './pdfs/STATREP - Mt Vernon.pdf';
import { useFormInput } from './CustomHooks';

const styles = theme => ({
    page: {
        minHeight: 750,
        paddingTop: 10,
        paddingBottom: 10,
    },
    buttons:{
        paddingTop: 20,
    },
    listItem:{
        height: 40,
    },
    selectedItem:{
        fontWeight: "bold",
    },
    list: {
        width: 260,
        // backgroundColor: theme.palette.background.paper,
        // borderStyle: "solid",
        // borderWidth: 1,
        // borderColor: theme.palette.secondary.main,
        // borderRadius: 5,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

function HelpPage(props) {
    const { classes } = props;
    const [numPages,setNumPages] = useState(0);
    const [pageNumber,setPageNumber] = useState(1);
    const [activeDocument, setActiveDocument] = useState(HelpPDF);
    const document = useFormInput(HelpPDF);
    function onDocumentLoadSuccess({ numPages }){
        setNumPages(numPages);
    }
    //mount
    useEffect(() => {
        if(props.adminView){
            props.setAdminView(false);
        }
    }, []);
    return (
        <Grid className={classes.page} container justify='center'>
            <Grid className={classes.buttons} item xs={3} container direction = 'column' justify='flex-start' alignItems='center'>
                <List component="nav" className={classes.list}>
                    <ListItem button className={classes.listItem} 
                        selected={activeDocument===HelpPDF}
                        onClick={()=>setActiveDocument(HelpPDF)}
                        classes={{selected:classes.selectedItem}}
                    >
                        Quick Reference
                    </ListItem>
                    <ListItem>
                        Status Report for Printing
                    </ListItem>
                    <List component="div" disablePadding className={classes.nested}>
                        <ListItem button className={classes.listItem} 
                            selected={activeDocument===MtVernonPDF}
                            onClick={()=>setActiveDocument(MtVernonPDF)}
                            classes={{selected:classes.selectedItem}}
                        >
                            Mt Vernon
                        </ListItem>
                        <ListItem button className={classes.listItem} 
                            selected={activeDocument===NewRochellePDF}
                            onClick={()=>setActiveDocument(NewRochellePDF)}
                            classes={{selected:classes.selectedItem}}
                        >
                            New Rochelle
                        </ListItem>
                        <ListItem button className={classes.listItem} 
                            selected={activeDocument===MosesEinsteinWakefieldPDF}
                            onClick={()=>setActiveDocument(MosesEinsteinWakefieldPDF)}
                            classes={{selected:classes.selectedItem}}
                        >
                            Moses Einstein Wakefield
                        </ListItem>
                    </List>
                </List>
            </Grid>
            <Grid item xs={8}>
                <Paper>
                    <Document
                        file={activeDocument}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber}
                            height={1050} 
                        />
                    </Document>
                </Paper>
            </Grid>
            <Grid item xs={1}/>
        </Grid>
    );
}

export default withStyles(styles)(HelpPage);