import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios';
import { handleAxiosError, getAPIUrl, getSocketUrl } from '../AppUtils';
import { Typography, Grid, FormControl, Select, Button, CircularProgress } from '@material-ui/core';
import StrikeDashboardTable from './StrikeDashboardTable';
import StrikeCharts from './StrikeCharts';
import io from 'socket.io-client';
import { connect } from 'react-redux';
import ConfirmCloseStrikeEventDialog from './ConfirmCloseStrikeEventDialog';
import ChangeStrikeEventButton from './ChangeStrikeEventButton';

const styles = theme => ({
    page: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 300,
    },
    formControl: {
        width: 320,
    },
    select: {
        borderRadius: 5,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#e2e2e2",
        backgroundColor: "#FFF",
        color: "#555555",
        fontSize: 14,
    },
    titleBoldText:{
        fontSize: 18,
        fontWeight: "bold",
    },
    titleText:{
        fontSize: 18,
    },
    statusBox:{
        marginRight: 10,
    },
    closeEventButton: {
        color: theme.palette.text.primary,
    },
});

function StrikeDashboardPage(props) {
    const { classes, match, departmentAlias, departments, filters } = props;
    const source = axios.CancelToken.source();
    const [filterChoice, setFilterChoice] = useState({});
    const [strikeReports, setStrikeReports] = useState([]);
    const [closeEventDialogOpen, setCloseEventDialogOpen] = useState(false);
    const [event, setEvent] = useState({});
    const [openStrikeEvents, setOpenStrikeEvents] = useState([]);
    const [loadingEvent, setLoadingEvent] = useState(true);

    useEffect(() => {
        getStrikeEventDetails();
        const socket = io(getSocketUrl());
        socket.on('update_strike', getStrikeEventDetails)
        return () => {
            socket.close();
            source.cancel("unmount");
        }
    }, []);
    useEffect(()=>{
        getStrikeEventDetails();
    }, [match.params.id])
    function getOpenStrikeEvents(){
        axios.get(getAPIUrl()+'/openStrikeEvents',{cancelToken: source.token})
        .then(response => {
            setOpenStrikeEvents(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getStrikeEventDetails(){
        getOpenStrikeEvents();
        const url = match.params.id?
            getAPIUrl()+`/strikeEventDetails/${match.params.id}`
            :getAPIUrl()+'/strikeEventDetails'
        axios.get(url,{cancelToken: source.token})
        .then(response => {
            setStrikeReports(response.data.reports);
            setEvent(response.data.event);
            setLoadingEvent(false);
        })
        .catch(error=>{
            handleAxiosError(error);
            setLoadingEvent(false);
        });
    }
    function handleFilterChange(index, key, e){
        let newFilterChoice = {
            ...filterChoice,
            [key]: e.target.value
        }
        for(let i = index+1; i < filters.length; i++){
            newFilterChoice[filters[i].info.key] = "";
        }
        if(index < filters.length){
            newFilterChoice["department"] = "";
        }
        setFilterChoice(newFilterChoice);
    }
    const aggregatesUsed = []
    const filtersUsed = []
    var filteredFilters = filters.slice(0);
    var filteredDepartments = departments.slice(0);
    for(let i = 0; i < filters.length; i++){
        let key = filters[i].info.key;
        let val = filterChoice[key]?parseInt(filterChoice[key]):undefined
        let possibleOptions = filteredDepartments.reduce((obj,n)=>{
            obj[n[key]] = true;
            return obj
        },{});
        filteredFilters[i] = Object.assign({},filters[i])
        filteredFilters[i].options = filteredFilters[i].options.filter(n=>possibleOptions[n.id])
        if(val){
            if(filteredFilters[i].options.some(n=>n.id===val)){
                aggregatesUsed.push(key);
                filtersUsed.push(val);
                filteredDepartments = filteredDepartments.filter(n=>n[key]===val)
            }
            else{
                // console.log(filters[i].options)
                // filterChoice[key] = "";
            }
        }
    }
    const departmentChoices = filteredDepartments.slice();
    if(filterChoice["department"]){
        aggregatesUsed.push("department");
        filtersUsed.push(filterChoice["department"]);
        filteredDepartments = filteredDepartments.filter(n=>n["id"]==filterChoice["department"])
    }
    let depIds = filteredDepartments.map(m=>m.id);
    const filteredReports = strikeReports.filter(n=>depIds.includes(n.department_id));
    if(loadingEvent){
        return(
            <div className={classes.page}>
                <Grid container justify='center' alignItems='center'>
                    <CircularProgress/>
                </Grid>
            </div>
        )
    }
    if(!event){
        return(
            <div className={classes.page}>
                <Grid container justify='center' alignItems='center'>
                    <Typography className={classes.text} variant="h4">
                        Event Not Found
                    </Typography>
                </Grid>
            </div>
        )
    }
    return (
        <div className={classes.page}>
            <ConfirmCloseStrikeEventDialog
                open={closeEventDialogOpen}
                handleClose={()=>setCloseEventDialogOpen(false)}
                eventId={event.id}
                refreshEvent={getStrikeEventDetails}
            />
            <Grid style={{marginBottom: 20}} container direction="row">
                <Grid item xs={6} container direction="row" alignItems="center">
                    <Typography className={classes.titleBoldText} display="inline">Strike Event:&nbsp;</Typography>
                    {event.status?<ChangeStrikeEventButton style={{marginLeft:10}} openEvents={openStrikeEvents} history={props.history}/>:
                    <Typography className={classes.titleText} display="inline">{event.name}</Typography>}
                </Grid>
                <Grid item container direction="row" xs={6} justify="flex-end" alignItems="center">
                    <div className={classes.statusBox}>
                        <Typography className={classes.titleBoldText} display="inline">Status:&nbsp;</Typography>
                        <Typography className={classes.titleText} display="inline">{event.status?"Open":"Closed"} </Typography>
                    </div>
                    {event.status?
                        <Button className={classes.closeEventButton} 
                            variant="contained" color="secondary" size="small"
                            onClick={()=>setCloseEventDialogOpen(true)} 
                        >
                            Close Event
                        </Button>:undefined
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} container>
                <Grid>
                    {filteredFilters.map((n,i)=>
                        <FormControl key={n.info.key} className={classes.formControl}>
                            <Typography>{n.info.name}</Typography>
                            <Select
                                className={classes.select}
                                disableUnderline 
                                native
                                onChange={e=>handleFilterChange(i,n.info.key,e)}
                                value={filterChoice[n.info.key]}
                                classes={{select:classes.selectSelect}}
                            >
                                <option className={classes.option} value="">
                                    &nbsp;&nbsp;&nbsp;&nbsp;All
                                </option>
                                {n.options.map(filter =>
                                    <option key={filter.id} className={classes.option} value={filter.id}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;{filter.name}
                                    </option>)
                                }
                            </Select>
                        </FormControl>
                    )}
                    <FormControl className={classes.formControl}>
                        <Typography>{departmentAlias}</Typography>
                        <Select
                            className={classes.select}
                            disableUnderline 
                            native
                            onChange={e=>handleFilterChange(filteredFilters.length,"department",e)}
                            value={filterChoice["department"]}
                            classes={{select:classes.selectSelect}}
                        >
                            <option className={classes.option} value="">
                                &nbsp;&nbsp;&nbsp;&nbsp;All
                            </option>
                            {departmentChoices.map(filter =>
                                <option key={filter.id} className={classes.option} value={filter.id}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;{filter.name}
                                </option>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <div style={{height: 50}}/>
            <StrikeDashboardTable
                strikeReports={filteredReports}
            />
            <div style={{height: 50}}/>
            <StrikeCharts
                eventName={event.name}
                reports={strikeReports}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    const { departmentAlias, departments, filters } = state
    return { departmentAlias, departments, filters }
};

export default withStyles(styles)(connect(mapStateToProps)(StrikeDashboardPage));