import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Link, Typography, IconButton,
        Tooltip, TableSortLabel, Grid, CircularProgress } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from 'react-bootstrap/Pagination'
import './custom.scss';
import { stableSort, getSorting, SortableLabel } from './TableUtils'

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 120,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 6,
        '&:last-child':{
            paddingRight: 6
        }
    },
    tableHeaderRow: {
        height: 30,
    },
    tableRow: {
        height: 40,
        // '&:hover': {
        //     cursor: "pointer",
        // },
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: 10,
    },
})

const StandardTable = React.memo((props)=>{
    const { classes, data, columns, order, orderBy, handleRequestSort } = props
    if(!data){
        return <div/>
    }
    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        {columns.map(col=>
                            <TableCell key={col.id} className={classes.tableHeaderCell} align="center">
                                <SortableLabel
                                    order={order}
                                    active={orderBy===col.id}
                                    handleSort={handleRequestSort(col.id)}
                                    label={col.name}
                                />
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stableSort(data, getSorting(order, orderBy))
                    .map((row,i)=>
                    <TableRow className={classes.tableRow} key={i}>
                        {columns.map(col=>
                            <TableCell key={col.id} className={classes.tableCell} align="center">
                                <Typography>
                                    {row[col.id]}
                                </Typography>
                            </TableCell>
                        )}
                    </TableRow>)}
                </TableBody>
            </Table>
        </div>
    )
}) 

export default withStyles(styles)(StandardTable);