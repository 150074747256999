import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Link, Typography, IconButton,
        Tooltip, TableSortLabel, Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from 'react-bootstrap/Pagination'
import '../custom.scss';
import { format, parseISO } from 'date-fns'
import { stableSort, getSorting, SortableLabel } from '../TableUtils'
import EditIcon from '@material-ui/icons/Edit';
import EditQuickStatRepEventDialog from './EditQuickStatRepEventDialog';
import { Link as RouterLink } from 'react-router-dom'

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 120,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 30,
    },
    tableRow: {
        height: 40,
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    clickableTableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
        '&:hover': {
            cursor: "pointer",
        },
    },
    searchGrid: {
        marginBottom: 20,
    },
    totalText: {
        marginLeft: 15,
    },
    pagination:{
        marginTop: 30,
    }
})

function QuickStatRepEventsTable(props) {
    const { classes, events, refreshEvents } = props
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('id');
    const [page, setPage] = useState(0);
    const [editEvent, setEditEvent] = useState(undefined);
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    const rowsPerPage = 10;
    const handleRequestSort = property => event => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    function handlePageChange(pageNum){
        setPage(pageNum);
    }
    const filteredEvents = events?events:[]
    const numPages = Math.ceil(filteredEvents.length/rowsPerPage);
    function handlePageIncrease(){
        if(page<numPages-1){
            setPage(page+1);
        }
    }
    function handlePageDecrease(){
        if(page>0){
            setPage(page-1);
        }
    }
    function handleEditDialogClose(){
        setEditDialogOpen(false);
        setEditEvent(undefined);
    }
    return (
        <div>
            <EditQuickStatRepEventDialog
                open={editDialogOpen}
                event={editEvent}
                handleClose={handleEditDialogClose}
                refreshEvents={refreshEvents}
            />
            <Typography variant="h5" style={{marginBottom: 20}}>Quick StatRep Events</Typography>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="id"}
                                handleSort={handleRequestSort("id")}
                                label="Event ID"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="name"}
                                handleSort={handleRequestSort("name")}
                                label="Name"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="status"}
                                handleSort={handleRequestSort("status")}
                                label="Status"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            Edit Event
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stableSort(filteredEvents, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(event=>
                    <TableRow className={classes.tableRow} key={event.id}>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>
                                <Link component={RouterLink} 
                                    to={`/strikeDashboard/${event.id}`}
                                >
                                    {event.id}
                                </Link>
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>
                                <Link component={RouterLink} 
                                    to={`/strikeDashboard/${event.id}`}
                                >
                                    {event.name}
                                </Link>
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{event.status?'Open':'Closed'}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <IconButton
                                fontSize="15"
                                onClick={()=>{
                                    setEditEvent(event);
                                    setEditDialogOpen(true);
                                }}
                            >
                                <EditIcon/>
                            </IconButton>
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
            <div>
            <Pagination className={classes.pagination}>
                <Pagination.First onClick={()=>handlePageChange(0)}/>
                <Pagination.Prev onClick={handlePageDecrease} disabled={page===0}/>
                {page-10>0?<Pagination.Ellipsis/>:undefined}
                {Array(numPages).fill().map((_, i) => {
                    if(i>page-10 && i<page+10){
                        return(
                            <Pagination.Item key={i} 
                                active={page===i} 
                                onClick={()=>handlePageChange(i)}
                            >
                                {i+1}
                            </Pagination.Item>
                        );
                    }
                })}
                {page+10<numPages-1?<Pagination.Ellipsis/>:undefined}
                <Pagination.Next onClick={handlePageIncrease} disabled={page>=numPages-1}/>
                <Pagination.Last onClick={()=>handlePageChange(numPages-1)}/>
            </Pagination>
            </div>
        </div>
    )
} 

export default withStyles(styles)(QuickStatRepEventsTable);