import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import ReportsTable from './ReportsTable';
import { handleAxiosError, getAPIUrl } from './AppUtils';

const styles = theme => ({
    page: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 300,
    }
});

function ReportsPage(props) {
    const { classes, adminView, user } = props;
    const source = axios.CancelToken.source();
    const [reports, setReports] = useState(undefined);

    //mount, unmount
    useEffect(() => {
        getReports();
        return () => {source.cancel("unmount");}
    }, []);
    function handleDelete(id){
        setReports(reports.filter(n=>n.id!==id))
        axios.delete(getAPIUrl()+`/report/${id}`,{cancelToken: source.token})
        .then(response => {
        })
        .catch(error=>handleAxiosError(error));
    }
    function downloadReportPDF(id){
        axios.get(getAPIUrl()+`/reportPDF/${id}`,{
                cancelToken: source.token,
                responseType: 'blob',
                headers: {
                  'Accept': 'application/pdf'
                }
            })
        .then(response => {
            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'unknown.pdf';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename=(.+)/);
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }
            var blob = new Blob([response.data]);
            if (navigator.appVersion.toString().indexOf('.NET') > 0)
                window.navigator.msSaveBlob(blob, fileName);
            else
            {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            }
        })
        .catch(error=>handleAxiosError(error));
    }
    function getReports(){
        if(adminView){
            axios.get(getAPIUrl()+'/reports',{cancelToken: source.token})
            .then(response => {
                setReports(response.data);
            })
            .catch(error=>handleAxiosError(error));
        }
        else{
            axios.get(getAPIUrl()+'/userReports',{cancelToken: source.token})
            .then(response => {
                setReports(response.data);
            })
            .catch(error=>handleAxiosError(error));
        }
    }
    return (
        <div className={classes.page}>
            <ReportsTable
                reports={reports}
                adminView={adminView}
                handleDelete={handleDelete}
                downloadReportPDF={downloadReportPDF}
            />
        </div>
    );
}

export default withStyles(styles)(ReportsPage);