import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogContent, DialogActions, Typography, DialogTitle, RadioGroup, Radio,
    FormControl, FormLabel, FormControlLabel, Checkbox, Grid, CircularProgress, MenuItem, MenuList, Select } from '@material-ui/core'
import axios from 'axios';
import { handleAxiosError, getAPIUrl } from '../AppUtils';
import { useFormInput, useCheckbox } from '../CustomHooks';
import CustomizedTextField from '../CustomizedTextField';
import { Link } from 'react-router-dom'

const styles = theme => ({
    button: {
        color: theme.palette.text.primary,
    },
    select: {
        borderRadius: 5,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#e2e2e2",
        backgroundColor: "#FFF",
        // color: "#555555",
        fontSize: 18,
    },
    formControl: {
        width: 249,
    },
    selectSelect: {
        paddingLeft: 8,
    },
})

function ChangeEventButton(props){
    const { classes, openEvents } = props;
    const source = axios.CancelToken.source();
    const [open, setOpen] = useState(false);
    const eventName = useFormInput("");

    function handleClose(){
        setOpen(false);
    }
    function createEvent(){
        // setCreating(true);
    }
    function handleChange(event){
        // setCreating(false);
        props.history.push(`/event/${event.target.value}`);
    }

    return (
        <div style={props.style}>
            <FormControl className={classes.formControl}>
                <Select
                    className={classes.select}
                    disableUnderline 
                    native
                    classes={{select:classes.selectSelect}}
                    onChange={handleChange}
                >
                    {openEvents.map(event=>
                    <option key={event.id} value={event.id}>
                        {event.name}
                    </option>)}
                </Select>
            </FormControl>
            {/* <Button className={classes.button} variant="contained" color="secondary" size="small" onClick={()=>setOpen(true)}>Change</Button>
            <Dialog 
                open={open}
                onClose={handleClose}
                onExited={handleExited}
            >
                <DialogTitle>Open Events</DialogTitle>
                <DialogContent>
                    <Grid container direction="column" alignItems="center">
                        <MenuList>
                            {openEvents.map(event=><MenuItem key={event.id} component={Link} to={`/event/${event.id}`} onClick={handleClose}>
                                {event.name}
                            </MenuItem>)}
                        </MenuList>
                    </Grid>
                </DialogContent>
            </Dialog> */}
        </div>
    )
}

export default withStyles(styles)(ChangeEventButton);